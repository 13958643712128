import React from 'react';
import { Column } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import FormDetails from './FormDetails';

import './style.less';

export default function ReviewStepColumns() {
  const { formData } = useWizard();

  return (
    <>
      <Column>
        <FormDetails
          form={formData.details}
          formData={formData.builder.schema || formData.content.json}
        />
      </Column>
      <Column></Column>
    </>
  );
}
