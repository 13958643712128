import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import LanguageIcon from '@components/LanguageIcon';
import { createLanguageFilters } from '@utils/supportedLanguages';

const createColumns = (t, handleUseSharedPathway) => [
  {
    className: 'language-column',
    title: t('cards:ProcedurePathwayList.columns.language.title'),
    dataIndex: 'metadata',
    key: 'language',
    render: ({ language }) => (language ? <LanguageIcon language={language} /> : null),
    filters: createLanguageFilters(t),
    onFilter: (value, record) => {
      return record.metadata.language === value;
    },
  },
  {
    title: t('pathways:SharedPathways.columns.pathwayName'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
    width: 400,
  },
  {
    title: t('pathways:SharedPathways.columns.createdBy'),
    dataIndex: 'parentName',
    key: 'parentName',
    width: 250,
  },
  {
    title: t('pathways:SharedPathways.columns.lastUpdated'),
    dataIndex: 'creation',
    key: 'creation',
    sorter: (a, b) => a.creation.localeCompare(b.creation),
    render: creation => moment(creation).format('LLL'),
  },
  {
    title: t('pathways:SharedPathways.columns.snapshotName'),
    // dataIndex: 'snapshotName',
    key: 'snapshotName',
    render: sharedPathway => {
      return sharedPathway.snapshotName;
    },
  },
  {
    title: t('pathways:SharedPathways.columns.action'),
    dataIndex: 'id',
    key: 'id',
    render: id => (
      <Button
        type="primary"
        onClick={e => {
          e.stopPropagation();
          handleUseSharedPathway(id);
        }}
      >
        {t('pathways:SharedPathways.use')}
      </Button>
    ),
    width: 200,
  },
];

export default createColumns;
