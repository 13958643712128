import {
  GDPR_DELETE_REQUEST,
  GDPR_DOWNLOAD_REQUEST,
  HOSPITAL_ADMIN,
  HOSPITAL_USER,
  SUPER_ADMIN,
  SUPPORT_ADMIN,
  SYSTEM_ADMIN,
  APP_USER,
} from '@constants';

export const LOAD_SUPPORT_REQUESTS = 'LOAD_SUPPORT_REQUESTS';
export const LOAD_SUPPORT_REQUESTS_SUCCESS = 'LOAD_SUPPORT_REQUESTS_SUCCESS';
export const LOAD_SUPPORT_REQUESTS_FAILED = 'LOAD_SUPPORT_REQUESTS_FAILED';

export const UPDATE_SUPPORT_REQUEST_ASSIGNEE = 'UPDATE_SUPPORT_REQUEST_ASSIGNEE';
export const UPDATE_SUPPORT_REQUEST_ASSIGNEE_SUCCESS = 'UPDATE_SUPPORT_REQUEST_ASSIGNEE_SUCCESS';
export const UPDATE_SUPPORT_REQUEST_ASSIGNEE_FAILED = 'UPDATE_SUPPORT_REQUEST_ASSIGNEE_FAILED';

export type RequestStatus = 'created' | 'processing' | 'completed';
export type RequestType = typeof GDPR_DELETE_REQUEST | typeof GDPR_DOWNLOAD_REQUEST;
export type RequestAssignee = {
  readonly type: 'hospital' | 'super-admin' | 'support-admin';
  readonly id?: string;
};

export type UserType =
  | typeof HOSPITAL_ADMIN
  | typeof HOSPITAL_USER
  | typeof SUPER_ADMIN
  | typeof SUPPORT_ADMIN
  | typeof SYSTEM_ADMIN
  | typeof APP_USER;

export type IRawRequest = {
  readonly uuid: string;
  readonly created: string;
  readonly status: RequestStatus;
  readonly assignee: RequestAssignee;
  readonly type: RequestType;
  readonly title: string;
  readonly description: string;
  readonly history?: any[];
  readonly userType: UserType;
  readonly organisationId?: string; // only optional as some old requests don't have it;
};

export type IRawUserSupportRequests = {
  app_user: string;
  created: string;
  json: {
    requests: IRawRequest[];
  };
  status: string;
  url: string;
  uuid: string;
  version: number;
};

export type IRequest = {
  readonly userId?: string; // corresponds to pip app user app_user_id
  readonly userUuid: string; // corresponds to pip app user uuid
  readonly uuid: string;
  readonly created: string;
  readonly status: RequestStatus;
  assignee: {
    type: string;
    id?: string;
  };
  readonly type: RequestType;
  readonly title: string;
  readonly description: string;
  readonly history?: any[];
  readonly userType: UserType;
  readonly organisationId?: string;
};
