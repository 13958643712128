import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload, message, Icon, Button } from 'antd';
import { getDefaultRegistry } from 'react-jsonschema-form/lib/utils';
import validateFormData from 'react-jsonschema-form/lib/validate';
import PropTypes from 'prop-types';
import { Card } from '@cards/Card';

import './style.less';

const { Dragger } = Upload;

const FormUploadCard = ({ fileList, onSubmit, error, onNextStep, isEdit }) => {
  const { t } = useTranslation();

  const draggerProps = useMemo(() => {
    return {
      fileList,
      name: 'file',
      multiple: false,
      accept: '.json',
      onRemove: () => {
        onSubmit([], undefined);
      },
      beforeUpload: file => false,
      onChange: ({ fileList }) => {
        const file = fileList.length > 0 && fileList[fileList.length - 1].originFileObj;

        if (file) {
          try {
            const reader = new FileReader();

            reader.onload = e => {
              try {
                const content = e.target.result;
                const json = JSON.parse(content);
                const { fields, widgets } = getDefaultRegistry();
                const validationResult = validateFormData({}, json.schema, null, fields, widgets);
                const filteredErrors = validationResult.errors.filter(
                  error => error.name !== 'required',
                );
                if (filteredErrors.length > 0) throw new Error(t('cards:FormUpload.jsonError'));

                onSubmit([file], json);
              } catch (err) {
                message.error(t('cards:FormUpload.jsonError'));
              }
            };

            reader.readAsText(file);
          } catch (err) {
            message.error(t('cards:FormUpload.fileError'));
          }
        }
      },
    };
  }, [fileList, onSubmit, t]);

  return (
    <Card.Half className="form-upload-card" title={t('cards:FormUpload.title')}>
      <div className="description">{t('cards:FormUpload.description1')}</div>
      <Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <Icon type="cloud-upload" />
        </p>
        <p className="ant-upload-text">{t('cards:FormUpload.text')}</p>
        <p className="ant-upload-hint">{t('cards:FormUpload.hint')}</p>
      </Dragger>
      {error ? <p className="form-upload-error">{error}</p> : null}
      {!isEdit && (
        <div className="proceed-to-builder">
          <div className="description">{t('cards:FormUpload.description2')}</div>
          <div className="button-container">
            <Button type="primary" onClick={onNextStep} disabled={fileList.length > 0}>
              {t('cards:FormUpload.useBuilder')}
            </Button>
          </div>
        </div>
      )}
    </Card.Half>
  );
};

FormUploadCard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fileList: PropTypes.array.isRequired,
  error: PropTypes.string,
};

FormUploadCard.defaultProps = {
  error: '',
};

export default FormUploadCard;
