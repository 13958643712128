import { Organisation } from '@liquid-state/directory-client';

export type IFetchDirectoryOrganisation = ReturnType<typeof fetchDirectoryOrganisation>;
export const fetchDirectoryOrganisation = (slug: string) => ({
  type: 'directory/fetch-organisation' as const,
  payload: {
    slug,
  },
});

export type IFetchDirectoryOrganisationSuccess = ReturnType<
  typeof fetchDirectoryOrganisationSuccess
>;
export const fetchDirectoryOrganisationSuccess = (slug: string, organisation: Organisation) => ({
  type: 'directory/fetch-organisation-success' as const,
  payload: {
    slug,
    organisation,
  },
});

export type IFetchDirectoryOrganisationFailed = ReturnType<typeof fetchDirectoryOrganisationFailed>;
export const fetchDirectoryOrganisationFailed = (slug: string) => ({
  type: 'directory/fetch-organisation-failed' as const,
  payload: {
    slug,
  },
});
