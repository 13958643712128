import { combineReducers } from 'redux';
import { GDPR_DELETE_REQUEST, GDPR_DOWNLOAD_REQUEST } from '@constants';
import {
  IRequest,
  LOAD_SUPPORT_REQUESTS,
  LOAD_SUPPORT_REQUESTS_SUCCESS,
  LOAD_SUPPORT_REQUESTS_FAILED,
  UPDATE_SUPPORT_REQUEST_ASSIGNEE,
  UPDATE_SUPPORT_REQUEST_ASSIGNEE_SUCCESS,
  UPDATE_SUPPORT_REQUEST_ASSIGNEE_FAILED,
} from './types';
import {
  ILoadSupportRequests,
  ILoadSupportRequestsSuccess,
  ILoadSupportRequestsFailed,
  SubmitGDPRRequestForCurrentUser,
  SubmitGDPRRequestFailed,
  SubmitGDPRRequestSuccess,
  SubmitGDPRRequestForAppUser,
  SubmitGDPRRequestForDashboardUser,
  SaveGDPRContact,
  SaveGDPRContactFailed,
  SaveGDPRContactSuccess,
  GDPRContactsLoaded,
  LoadGDPRContacts,
  LoadGDPRContactsFailed,
  ActionSupportRequest,
  ActionSupportRequestSuccess,
  ActionSupportRequestFailed,
} from './actions';
import { IState } from '../reducer';

function contacts(
  state: string[] = [],
  action: SaveGDPRContactSuccess | GDPRContactsLoaded,
): string[] {
  switch (action.type) {
    case 'requests/save-GDPR-contact-success':
      return [action.payload.email];
    case 'requests/gdpr-contacts-loaded':
      return action.payload.contacts;
    default:
      return state;
  }
}

function contactsLanguage(
  state: string = 'en',
  action: SaveGDPRContactSuccess | GDPRContactsLoaded,
): string {
  switch (action.type) {
    case 'requests/save-GDPR-contact-success':
      return action.payload.language;
    case 'requests/gdpr-contacts-loaded':
      return action.payload.language;
    default:
      return state;
  }
}

function contactsLoading(
  state = false,
  action: LoadGDPRContacts | GDPRContactsLoaded | LoadGDPRContactsFailed,
): boolean {
  switch (action.type) {
    case 'requests/load-gdpr-contacts':
      return true;
    case 'requests/gdpr-contacts-loaded':
    case 'requests/load-gdpr-contacts-failed':
      return false;
    default:
      return state;
  }
}

function contactsSubmitting(
  state = false,
  action: SaveGDPRContact | SaveGDPRContactFailed | SaveGDPRContactSuccess,
): boolean {
  switch (action.type) {
    case 'requests/save-GDPR-contact':
      return true;
    case 'requests/save-GDPR-contact-failed':
    case 'requests/save-GDPR-contact-success':
      return false;
    default:
      return state;
  }
}

const list = (
  state: IRequest[] = [],
  action: ILoadSupportRequestsSuccess | SubmitGDPRRequestSuccess | ActionSupportRequestSuccess,
): IRequest[] => {
  switch (action.type) {
    case LOAD_SUPPORT_REQUESTS_SUCCESS:
      return action.payload.requests;
    case 'requests/GDPR-success':
      return [action.payload.request, ...state];
    case UPDATE_SUPPORT_REQUEST_ASSIGNEE_SUCCESS:
      return state.map(req => {
        if (req.uuid === action.payload.request.uuid) {
          return action.payload.request;
        }
        return req;
      });
    default:
      return state;
  }
};

const loading = (
  state: boolean = false,
  action: ILoadSupportRequests | ILoadSupportRequestsSuccess | ILoadSupportRequestsFailed,
): boolean => {
  switch (action.type) {
    case LOAD_SUPPORT_REQUESTS:
      return true;
    case LOAD_SUPPORT_REQUESTS_SUCCESS:
    case LOAD_SUPPORT_REQUESTS_FAILED:
      return false;
    default:
      return state;
  }
};

function submitting(
  state = false,
  action:
    | SubmitGDPRRequestForCurrentUser
    | SubmitGDPRRequestForAppUser
    | SubmitGDPRRequestForDashboardUser
    | SubmitGDPRRequestFailed
    | SubmitGDPRRequestSuccess,
) {
  switch (action.type) {
    case 'requests/GDPR-for-current-user':
    case 'requests/GDPR-for-app-user':
    case 'requests/GDPR-for-dashboard-user':
      return true;
    case 'requests/GDPR-failed':
    case 'requests/GDPR-success':
      return false;
    default:
      return state;
  }
}

const updating = (
  state: boolean = false,
  action: ActionSupportRequest | ActionSupportRequestSuccess | ActionSupportRequestFailed,
): boolean => {
  switch (action.type) {
    case UPDATE_SUPPORT_REQUEST_ASSIGNEE:
      return true;
    case UPDATE_SUPPORT_REQUEST_ASSIGNEE_SUCCESS:
    case UPDATE_SUPPORT_REQUEST_ASSIGNEE_FAILED:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  contacts,
  contactsLanguage,
  contactsLoading,
  contactsSubmitting,
  list,
  loading,
  updating,
  submitting,
});

export const isLoading = (state: IState): boolean => state.requests.loading;
export const isRequestSubmitting = (state: IState): boolean => state.requests.submitting;
export const isRequestUpdating = (state: IState): boolean => state.requests.updating;
export const isRequestsContactsSubmitting = (state: IState): boolean =>
  state.requests.contactsSubmitting;

export const selectContacts = (state: IState) => [
  state.requests.contactsLoading,
  state.requests.contacts,
];

export const selectContactsLanguage = (state: IState) => [
  state.requests.contactsLoading,
  state.requests.contactsLanguage,
];

export const selectDownloadRequestsForHospital = (hospitalId: string) => (
  state: IState,
): [boolean, IRequest[]] => {
  const currentOrgId = state.organisation.appDetails.organisationSlug;
  console.log('currentOrgId: ', currentOrgId);
  console.log('state.requests: ', state.requests);
  return [
    state.requests.loading,
    state.requests.list.filter(
      item =>
        (item.organisationId === currentOrgId ||
          item.assignee.id === currentOrgId ||
          item.history?.find(h => h.data.previousAssignee?.id === currentOrgId)) &&
        item.type === GDPR_DOWNLOAD_REQUEST,
    ),
  ];
};

export const selectDeleteRequestsForHospital = (hospitalId: string) => (
  state: IState,
): [boolean, IRequest[]] => {
  return [
    state.requests.loading,
    state.requests.list.filter(
      item =>
        (item.organisationId === hospitalId ||
          item.assignee.id === hospitalId ||
          item.history?.find(h => h.data.previousAssignee?.id === hospitalId)) &&
        item.type === GDPR_DELETE_REQUEST,
    ),
  ];
};

export const selectDownloadRequestsForSuperAdmin = (hospitalId: string) => (
  state: IState,
): [boolean, IRequest[]] => {
  const currentOrgId = state.organisation.appDetails.organisationSlug;
  console.log('currentOrgId: ', currentOrgId);
  console.log('state.requests: ', state.requests);
  return [
    state.requests.loading,
    state.requests.list.filter(
      item =>
        (item.organisationId === currentOrgId || item.assignee.type === 'super-admin') &&
        item.type === GDPR_DOWNLOAD_REQUEST,
    ),
  ];
};

export const selectDeleteRequestsForSuperAdmin = (hospitalId: string) => (
  state: IState,
): [boolean, IRequest[]] => {
  const currentOrgId = state.organisation.appDetails.organisationSlug;
  return [
    state.requests.loading,
    state.requests.list.filter(
      item =>
        (item.organisationId === currentOrgId || item.assignee.type === 'super-admin') &&
        item.type === GDPR_DELETE_REQUEST,
    ),
  ];
};
