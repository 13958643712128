import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import moment from 'moment';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { selectAppUser } from '@redux/appUsers/reducers';
import IconButton from '@components/IconButton';
import './styles.less';

const createColumns = t => [
  {
    title: t('cards:PatientInvitationCodes.columns.code'),
    key: 'code',
    render: data => (
      <>
        {data.code}
        <IconButton
          icon={faCopy}
          size="small"
          tooltip={t('common:buttons.copyToClipboard')}
          onClick={() => navigator.clipboard.writeText(data.code)}
        />
      </>
    ),
  },
  {
    title: t('cards:PatientInvitationCodes.columns.sentDate'),
    key: 'sentDate',
    render: data =>
      moment(data.expires_at)
        .subtract(7, 'days')
        .format('lll'),
    ellipsis: true,
  },
  {
    title: t('cards:PatientInvitationCodes.columns.expirationDate'),
    key: 'expirationDate',
    render: data => moment(data.expires_at).format('lll'),
    ellipsis: true,
  },
  {
    title: t('cards:PatientInvitationCodes.columns.consumed'),
    key: 'consumed',
    render: data => t(`common:${data.consumed ? 'yes' : 'no'}`),
  },
];

const InvitationCodes = ({ appUserId, ...props }) => {
  const { t } = useTranslation();
  const columns = useMemo(() => createColumns(t), [t]);
  const [loading, appUser] = useSelector(selectAppUser(appUserId));

  if (!appUser || !appUser.invitationCodes.length) {
    return null;
  }

  return (
    <Table
      columns={columns}
      dataSource={appUser.invitationCodes}
      loading={loading}
      pagination={false}
      rowKey={record => record.code}
    />
  );
};

InvitationCodes.propTypes = {
  appUserId: string.isRequired,
};

InvitationCodes.defaultProps = {};

export default InvitationCodes;
