import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { Icon, Menu, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faMobile,
  faFilesMedical,
  faRss,
  faHeart,
  faClock,
} from '@fortawesome/pro-regular-svg-icons';
import { pathway } from '@assets/icons';
import EditButton from '@components/EditButton';
import SubNavMenuContainer from '@components/SubNavMenuContainer';
import SubNavMenu from '@components/SubNavMenu';
import { useFeature, usePageActions } from '@hooks';
import { fetchAppUsers } from '@redux/appUsers/actions';
import {
  fetchAppUsersPathways,
  fetchAppUserJourneys,
} from '@pathways/redux/appUserPathways/actions';
import { selectAppUser } from '@redux/appUsers/reducers';
import { selectPathwaysForAppUserWithOriginals } from '@pathways/redux/appUserPathways/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectPathways } from '@pathways/redux/pathways/reducers';
import AppUserPathways from '@pathways/pages/AppUsers';
import AppUserTimeline from '@pathways/pages/AppUsers/JourneyDetails';
import { contentTypes, FORM, QUESTIONNAIRE, TASK } from '@utils/contentTypes';
import AppUserEngagements from './AppUserEngagements';
import AppUserHealthData from './AppUserHealthData';
import AppUserOverviewTab from './AppUserOverviewTab';
import AppUserFormListTab from './AppUserFormListTab';
import AppUserEdit from './AppUserEdit';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Evereg from './Evereg';
import AppUserAppAccessTab from './AppUserAppAccessTab';
import { getMatchTypeWithParams } from '../../propTypes';
import { trackAndPushHistory } from '../../analytics';
import AppUserFilesTab from './AppUserFilesTab';
import AppUserFile from './AppUserFilesTab/AppUserFile';
import { fetchOrganisation } from '@redux/organisations/actions';
import { selectOrganisation } from '@redux/organisations/reducers';
import { selectOrganisationSlug } from '../../organisation/redux/selectors';
import { organisationSlug as primaryOrgSlug, showAppUserHealthDataScreen } from 'settings';

const routes = {
  edit: 'edit',
  overview: 'overview',
  files: 'files',
  forms: 'forms',
  questionnaires: 'questionnaires',
  tasks: 'tasks',
  timeline: 'timeline',
  journey: 'journey',
  engagements: 'engagements',
  healthData: 'health-data',
  access: 'access',
  termsAndConditions: 'terms-and-conditions',
  privacyPolicy: 'privacy-policy',
  evereg: 'evereg',
};

function AppUserDetails({ history, match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    params: { id },
  } = match;

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchAppUsersPathways());
    dispatch(fetchPathways());
    dispatch(fetchAppUserJourneys(id));
    dispatch(fetchOrganisation());
  }, [dispatch, id]);

  const [, appUser] = useSelector(selectAppUser(id));
  const [appUserPathwaysLoading, pathways] = useSelector(
    selectPathwaysForAppUserWithOriginals(appUser),
  );
  const [pathwaysLoading] = useSelector(selectPathways);
  const [, organisation] = useSelector(selectOrganisation());
  const orgSlug = useSelector(selectOrganisationSlug());
  const { appUsersControlledDocsForAllOrgs } = useFeature('appUsersControlledDocsForAllOrgs');
  const [healthDataCollected, setHealthDataCollected] = useState([]);
  const { setTitle } = usePageActions({
    actions: [
      <EditButton
        key="edit-btn"
        onClick={() => trackAndPushHistory(history, `${match.url}/${routes.edit}`, 'Edit')}
      />,
    ],
    showBack: true,
  });

  useEffect(() => {
    setTitle(appUser && `${appUser.displayName}`);
  }, [appUser, setTitle]);

  useEffect(() => {
    if (!organisation) return;
    setHealthDataCollected(organisation.health_data_collected || []);
  }, [organisation]);

  const { evereg: everegFeature } = useFeature('evereg');
  const showEvereg = appUser?.language === 'es' && everegFeature;

  return (
    <Switch>
      <Route path={`${match.path}/${routes.edit}`} component={AppUserEdit} />
      <SubNavMenuContainer
        menu={
          <SubNavMenu>
            <Menu.Item key={routes.overview}>
              <FontAwesomeIcon icon={faUser} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.overview')}
            </Menu.Item>
            <Menu.Item key={routes.timeline}>
              <FontAwesomeIcon icon={faClock} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.timeline')}
            </Menu.Item>
            {/* <Menu.SubMenu
              key={routes.journey}
              title={
                <>
                  <Icon component={pathway} className="tab-item-icon" />
                  {t('patients:IndividualDetail.tabs.journeys')}
                </>
              }
              onTitleClick={() =>
                history.location.pathname === `${match.url}/${routes.journey}`
                  ? null
                  : history.push(`${match.url}/${routes.journey}`)
              }
            > */}
            <Menu.ItemGroup
              key="app-details-group"
              title={
                <span>
                  <Icon component={pathway} className="tab-item-icon" />
                  <span>{t('patients:IndividualDetail.tabs.pathways')}</span>
                </span>
              }
              onTitleClick={() =>
                history.location.pathname === `${match.url}/${routes.journey}`
                  ? null
                  : history.push(`${match.url}/${routes.journey}`)
              }
            >
              {appUserPathwaysLoading || pathwaysLoading ? (
                <Spin style={{ width: '100%' }} />
              ) : (
                pathways?.map(pathway => (
                  <Menu.Item key={`${routes.journey}/pathway/${pathway.id}`}>
                    {pathway.originalPathway.name}
                  </Menu.Item>
                ))
              )}
            </Menu.ItemGroup>
            {/* </Menu.SubMenu> */}
            <Menu.Item key={routes.forms}>
              <FontAwesomeIcon icon={contentTypes.FORM.icon} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.forms')}
            </Menu.Item>
            <Menu.Item key={routes.questionnaires}>
              <FontAwesomeIcon icon={contentTypes.QUESTIONNAIRE.icon} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.questionnaires')}
            </Menu.Item>
            <Menu.Item key={routes.tasks}>
              <FontAwesomeIcon icon={contentTypes.TASK.icon} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.tasks')}
            </Menu.Item>
            <Menu.Item key={routes.files}>
              <FontAwesomeIcon icon={faFilesMedical} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.files')}
            </Menu.Item>
            <Menu.Item key={routes.engagements}>
              <FontAwesomeIcon icon={faRss} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.engagements')}
            </Menu.Item>
            {showAppUserHealthDataScreen &&
            (healthDataCollected.length || orgSlug === primaryOrgSlug) ? (
              <Menu.Item key={routes.healthData}>
                <FontAwesomeIcon icon={faHeart} className="tab-item-icon" />
                {t('patients:IndividualDetail.tabs.healthData')}
              </Menu.Item>
            ) : null}
            <Menu.ItemGroup
              key="app-details-group"
              title={
                <span>
                  <FontAwesomeIcon icon={faMobile} className="tab-item-icon" />
                  <span>{t('patients:IndividualDetail.tabs.appDetails')}</span>
                </span>
              }
            >
              <Menu.Item key={routes.access}>
                {t('patients:IndividualDetail.tabs.appAccess')}
              </Menu.Item>
              {appUsersControlledDocsForAllOrgs || appUser?.hospitalId === primaryOrgSlug ? (
                <Menu.Item key={routes.termsAndConditions}>
                  {t('patients:IndividualDetail.tabs.terms')}
                </Menu.Item>
              ) : null}
              {appUsersControlledDocsForAllOrgs || appUser?.hospitalId === primaryOrgSlug ? (
                <Menu.Item key={routes.privacyPolicy}>
                  {t('patients:IndividualDetail.tabs.privacyPolicy')}
                </Menu.Item>
              ) : null}
            </Menu.ItemGroup>
            {showEvereg ? (
              <Menu.Item key={routes.evereg}>
                <>
                  <span className="app-user-tabs-item-alphabet-icon">E</span>
                  {t('patients:IndividualDetail.tabs.evereg')}
                </>
              </Menu.Item>
            ) : null}
          </SubNavMenu>
        }
      >
        <Switch>
          <Route path={`${match.path}/${routes.overview}`} component={AppUserOverviewTab} />
          <Route path={`${match.path}/${routes.files}/:uuid`} component={AppUserFile} />
          <Route path={`${match.path}/${routes.files}`} component={AppUserFilesTab} />
          <Route
            path={`${match.path}/${routes.forms}`}
            render={props => (
              <AppUserFormListTab formTranslationKey="form" type={FORM} {...props} />
            )}
          />
          <Route
            path={`${match.path}/${routes.questionnaires}`}
            render={props => (
              <AppUserFormListTab
                formTranslationKey="questionnaire"
                type={QUESTIONNAIRE}
                {...props}
              />
            )}
          />
          <Route
            path={`${match.path}/${routes.tasks}`}
            render={props => (
              <AppUserFormListTab formTranslationKey="task" type={TASK} {...props} />
            )}
          />
          <Route path={`${match.path}/${routes.engagements}`} component={AppUserEngagements} />
          <Route path={`${match.path}/${routes.healthData}`} component={AppUserHealthData} />
          <Route path={`${match.path}/${routes.timeline}`} component={AppUserTimeline} />
          <Route path={`${match.path}/${routes.journey}`} component={AppUserPathways} />
          <Route path={`${match.path}/${routes.access}`} component={AppUserAppAccessTab} />
          {/* {appUser?.hospitalId === 'liquid-state' ? ( */}
          <>
            <Route
              path={`${match.path}/${routes.termsAndConditions}`}
              component={TermsAndConditions}
            />
            <Route path={`${match.path}/${routes.privacyPolicy}`} component={PrivacyPolicy} />
          </>
          {/* ) : null} */}
          {appUser?.language === 'es' ? (
            <Route path={`${match.path}/${routes.evereg}`} component={Evereg} />
          ) : null}
          <Route path={match.path} render={() => <Redirect to={`${match.url}/overview`} />} />
        </Switch>
      </SubNavMenuContainer>
    </Switch>
  );
}

AppUserDetails.propTypes = {
  match: getMatchTypeWithParams({
    id: string.isRequired,
    activeTab: string,
    detailId: string,
  }).isRequired,
};

AppUserDetails.defaultProps = {
  activeTab: null,
  detailId: null,
};

export default AppUserDetails;
