import { Organisation } from '@liquid-state/directory-client';
import { fetchDirectoryOrganisation } from '@redux/directoryOrganisations/actions';
import { selectDirectoryOrganisationWithLoading } from '@redux/directoryOrganisations/reducers';
import { useDispatch, useStore } from 'react-redux';

const useDirectoryOrganisations = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const fetch = (slug: string | null): [boolean, Organisation?] => {
    if (!slug) {
      return [false, undefined];
    }
    let [loading, data] = selectDirectoryOrganisationWithLoading(slug)(store.getState());
    if (loading === undefined) {
      dispatch(fetchDirectoryOrganisation(slug));
      loading = true;
    }
    return [loading, data];
  };

  return fetch;
};

export default useDirectoryOrganisations;
