/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useMemo, useRef } from 'react';
import uuid from 'uuid';
import { useTranslation } from 'react-i18next';
import {
  Menu,
  Divider,
  Dropdown,
  Input,
  Form,
  InputNumber,
  Radio,
  Button,
  Popover,
  Switch,
  Checkbox,
  Select,
  List,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
  faPlusCircle,
  faTrash,
  faInfoCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import FormPreviewModal from '@components/FormPreviewModal';
import ReorderButton from '@components/ReorderButton';
import EditComputationsModal from '@pages/Forms/FormWizard/EditComputationsModal';
import locales from '../../../locales';
import { validateFormSchema } from './schemaFormBuilderCompatibilityValidator';
import { toRJSF, fromRJSF } from './formSchemaConverter';
import './styles.less';

const DEFAULT_TEXT_INPUT_MAX_LENGTH = 100000;
const DEFAULT_NUMBER_INPUT_MAX = 1000000000000000000;

const elementTypeToTitleMap = t => ({
  text: t('forms:FormWizard.steps.builder.text'),
  number: t('forms:FormWizard.steps.builder.number'),
  multipleChoice: t('forms:FormWizard.steps.builder.multipleChoice'),
  date: t('forms:FormWizard.steps.builder.date'),
  slider: t('forms:FormWizard.steps.builder.slider'),
  section: t('forms:FormWizard.steps.builder.section'),
  nullElement: t('forms:FormWizard.steps.builder.nullElement'),
});

function getNextKeyWithPrefix(schemaObj, prefix) {
  const numbers = Object.keys(schemaObj)
    .map(k => {
      const match = new RegExp(`^${prefix}(\\d+)$`).exec(k);
      return match ? parseInt(match[1], 10) : null;
    })
    .filter(n => n !== null);
  const maxNum = numbers.length > 0 ? Math.max(...numbers) : 0;
  return `${prefix}${maxNum + 1}`;
}

function getNextQKey(schemaObj) {
  return getNextKeyWithPrefix(schemaObj, 'q');
}

function TextQuestion({ form, elementKey, localElementSchema, setLocalElementSchema }) {
  const { t } = useTranslation();

  const maxMustBeGreaterThanMin = (rule, value, callback) => {
    if (localElementSchema.maxLength <= localElementSchema.minLength) {
      callback(t('forms:FormWizard.steps.builder.maxLengthMustBeGreaterThanMinLength'));
    } else {
      callback();
    }
  };

  return (
    <div className="text-question">
      <Input
        placeholder={t('forms:FormWizard.steps.builder.placeholder')}
        value={localElementSchema.placeholder}
        onChange={e => {
          const value = e.target.value;
          setLocalElementSchema(prevSchema => ({
            ...prevSchema,
            placeholder: value,
          }));
        }}
        maxLength={DEFAULT_TEXT_INPUT_MAX_LENGTH}
      />
      <div className="min-max-container">
        <Form.Item>
          <InputNumber
            placeholder={t('forms:FormWizard.steps.builder.minLength')}
            value={localElementSchema.minLength}
            onChange={value => {
              setLocalElementSchema(prevSchema => ({
                ...prevSchema,
                minLength: value,
              }));
              form.setFieldsValue({
                [`${elementKey}-minMax`]: value,
              });
            }}
            min={0}
            max={DEFAULT_NUMBER_INPUT_MAX}
          />
          <InputNumber
            placeholder={t('forms:FormWizard.steps.builder.maxLength')}
            value={localElementSchema.maxLength}
            onChange={value => {
              setLocalElementSchema(prevSchema => ({
                ...prevSchema,
                maxLength: value,
              }));
              form.setFieldsValue({
                [`${elementKey}-minMax`]: value,
              });
            }}
            min={0}
            max={DEFAULT_TEXT_INPUT_MAX_LENGTH}
          />
          {form.getFieldDecorator(`${elementKey}-minMax`, {
            rules: [{ validator: maxMustBeGreaterThanMin }],
          })(<span />)}
        </Form.Item>
      </div>
      <div className="radio-group-container">
        <p>{t('forms:FormWizard.steps.builder.inputType')}:</p>
        <Radio.Group
          value={localElementSchema['ui:widget']}
          onChange={e =>
            setLocalElementSchema(prevSchema => ({
              ...prevSchema,
              'ui:widget': e.target.value,
            }))
          }
        >
          <Radio value="input">{t('forms:FormWizard.steps.builder.shortAnswer')}</Radio>
          <Radio value="textarea">{t('forms:FormWizard.steps.builder.longAnswer')}</Radio>
        </Radio.Group>
      </div>
    </div>
  );
}

function NumberQuestion({ form, elementKey, localElementSchema, setLocalElementSchema }) {
  const { t } = useTranslation();

  const maxMustBeGreaterThanMin = (rule, value, callback) => {
    if (localElementSchema.maximum <= localElementSchema.minimum) {
      callback(t('forms:FormWizard.steps.builder.maxLengthMustBeGreaterThanMinLength'));
    } else {
      callback();
    }
  };

  return (
    <div className="number-question">
      <div className="min-max-container">
        <Form.Item>
          <InputNumber
            placeholder={t('forms:FormWizard.steps.builder.minimum')}
            value={localElementSchema.minimum}
            onChange={value => {
              setLocalElementSchema(prevSchema => ({
                ...prevSchema,
                minimum: value,
              }));
              form.setFieldsValue({
                [`${elementKey}-minMax`]: value,
              });
            }}
            min={-DEFAULT_NUMBER_INPUT_MAX}
            max={DEFAULT_NUMBER_INPUT_MAX}
          />
          <InputNumber
            placeholder={t('forms:FormWizard.steps.builder.maximum')}
            value={localElementSchema.maximum}
            onChange={value => {
              setLocalElementSchema(prevSchema => ({
                ...prevSchema,
                maximum: value,
              }));
              form.setFieldsValue({
                [`${elementKey}-minMax`]: value,
              });
            }}
            min={-DEFAULT_NUMBER_INPUT_MAX}
            max={DEFAULT_TEXT_INPUT_MAX_LENGTH}
          />
          {form.getFieldDecorator(`${elementKey}-minMax`, {
            rules: [{ validator: maxMustBeGreaterThanMin }],
          })(<span />)}
        </Form.Item>
      </div>
    </div>
  );
}

function MultipleChoice({ form, elementKey, localElementSchema, setLocalElementSchema, language }) {
  const { t } = useTranslation();
  const [options, setOptions] = useState({});
  const [optionLabels, setOptionLabels] = useState({});
  const [defaults, setDefaults] = useState([]);
  const [utiliseCustomValues, setUtiliseCustomValues] = useState(false);
  const prevTypeRef = useRef(localElementSchema.type);

  const localisedYes = locales[language]?.forms?.FormWizard?.steps?.builder?.yes;
  const localisedNo = locales[language]?.forms?.FormWizard?.steps?.builder?.no;

  useEffect(() => {
    if (localElementSchema.type === 'boolean' && localElementSchema['ui:widget'] === 'checkboxes') {
      setLocalElementSchema(prevSchema => {
        const elementSchema = { ...prevSchema };
        delete elementSchema['ui:widget'];
        return elementSchema;
      });
      form.setFieldsValue({
        [`${elementKey}-widget`]: undefined,
      });
    }
  }, [
    localElementSchema.type,
    localElementSchema['ui:widget'],
    elementKey,
    setLocalElementSchema,
    form,
  ]);

  useEffect(() => {
    if (localElementSchema.type === 'boolean') {
      if (typeof localElementSchema.default === 'boolean') {
        setDefaults([localElementSchema.default]);
      } else {
        setDefaults([]);
      }
      return;
    }

    const hasCustomValues = !!localElementSchema.enumNames;
    setUtiliseCustomValues(hasCustomValues);

    if (localElementSchema.enum) {
      const initialOptions = {};
      const initialLabels = {};

      if (hasCustomValues) {
        localElementSchema.enum.forEach((value, index) => {
          const id = uuid();
          initialOptions[id] = value;
          initialLabels[id] = localElementSchema.enumNames[index] || '';
        });
      } else {
        localElementSchema.enum.forEach(value => {
          const id = uuid();
          initialOptions[id] = value;
        });
      }

      setOptions(initialOptions);
      if (hasCustomValues) {
        setOptionLabels(initialLabels);
      }

      if (localElementSchema.default != null) {
        const defaultValue = localElementSchema.default;
        if (Array.isArray(defaultValue)) {
          const defaultKeys = defaultValue
            .map(value => Object.entries(initialOptions).find(([_, val]) => val === value)?.[0])
            .filter(Boolean);
          setDefaults(defaultKeys);
        } else {
          const defaultKey = Object.entries(initialOptions).find(
            ([_, val]) => val === defaultValue,
          )?.[0];
          setDefaults(defaultKey ? [defaultKey] : []);
        }
      }
    }
  }, []);

  useEffect(() => {
    const currentType = localElementSchema.type;
    if (currentType !== prevTypeRef.current) {
      setOptions({});
      setOptionLabels({});
      setDefaults([]);
      setUtiliseCustomValues(false);
      setLocalElementSchema(prevSchema => {
        const elementSchema = { ...prevSchema };
        delete elementSchema.enum;
        delete elementSchema.enumNames;
        delete elementSchema.default;
        return elementSchema;
      });
      prevTypeRef.current = currentType;
    }
  }, [localElementSchema.type, elementKey, setLocalElementSchema]);

  useEffect(() => {
    if (localElementSchema.type !== 'boolean') {
      if (Object.keys(options).length > 0) {
        const optionValues = Object.values(options);
        const filteredValues =
          localElementSchema.type === 'number'
            ? optionValues.filter(option => typeof option === 'number')
            : optionValues;

        const currentEnum = localElementSchema.enum || [];
        const sameEnum =
          currentEnum.length === filteredValues.length &&
          currentEnum.every((val, idx) => val === filteredValues[idx]);

        if (!sameEnum) {
          if (utiliseCustomValues) {
            const labelValues = Object.entries(options).map(([key]) => optionLabels[key] || '');

            setLocalElementSchema(prevSchema => ({
              ...prevSchema,
              enum: filteredValues,
              enumNames: labelValues,
            }));
          } else {
            setLocalElementSchema(prevSchema => {
              const newSchema = { ...prevSchema, enum: filteredValues };
              if (newSchema.enumNames) {
                delete newSchema.enumNames;
              }
              return newSchema;
            });
          }
        }
      } else {
        if (localElementSchema.enum) {
          setLocalElementSchema(prevSchema => {
            const elementSchema = { ...prevSchema };
            delete elementSchema.enum;
            delete elementSchema.enumNames;
            delete elementSchema.default;
            return elementSchema;
          });
        }
      }
    }
  }, [
    options,
    optionLabels,
    utiliseCustomValues,
    elementKey,
    localElementSchema,
    setLocalElementSchema,
  ]);

  useEffect(() => {
    const currentDefault = localElementSchema.default;

    if (localElementSchema.type === 'boolean') {
      if (defaults.length === 0) {
        if (typeof currentDefault !== 'undefined') {
          setLocalElementSchema(prevSchema => {
            const elementSchema = { ...prevSchema };
            delete elementSchema.default;
            return elementSchema;
          });
        }
        return;
      }

      const newBooleanDefault = defaults[0];
      if (newBooleanDefault !== currentDefault) {
        setLocalElementSchema(prevSchema => ({
          ...prevSchema,
          default: newBooleanDefault,
        }));
      }
      return;
    }

    if (defaults.length > 0) {
      const newDefault =
        defaults.length === 1 ? options[defaults[0]] : defaults.map(key => options[key]);

      if (Array.isArray(newDefault) && Array.isArray(currentDefault)) {
        if (
          newDefault.length === currentDefault.length &&
          newDefault.every((val, i) => val === currentDefault[i])
        ) {
          return;
        }
      } else if (newDefault === currentDefault) {
        return;
      }

      setLocalElementSchema(prevSchema => ({
        ...prevSchema,
        default: newDefault,
      }));
    } else {
      if (typeof currentDefault !== 'undefined') {
        setLocalElementSchema(prevSchema => {
          const elementSchema = { ...prevSchema };
          delete elementSchema.default;
          return elementSchema;
        });
      }
    }
  }, [defaults, options, elementKey, localElementSchema, setLocalElementSchema]);

  useEffect(() => {
    if (localElementSchema.type && !form.getFieldValue(`${elementKey}-dataType`)) {
      form.setFieldsValue({
        [`${elementKey}-dataType`]: localElementSchema.type,
      });
    }
    if (localElementSchema['ui:widget'] && !form.getFieldValue(`${elementKey}-widget`)) {
      form.setFieldsValue({
        [`${elementKey}-widget`]: localElementSchema['ui:widget'],
      });
    }
    if (options && !form.getFieldValue(`${elementKey}-options`)) {
      form.setFieldsValue({
        [`${elementKey}-options`]: 'value',
      });
    }
    if (localElementSchema.enumNames && !form.getFieldValue(`${elementKey}-useCustomValues`)) {
      form.setFieldsValue({
        [`${elementKey}-useCustomValues`]: true,
      });
    }
  }, []);

  const handleDefaultsChange = (key, checked) => {
    if (localElementSchema['ui:widget'] === 'checkboxes') {
      if (checked) {
        setDefaults([...defaults, key]);
      } else {
        setDefaults(defaults.filter(d => d !== key));
      }
    } else {
      if (checked) {
        setDefaults([key]);
      } else {
        setDefaults([]);
      }
    }
  };

  const toggleCustomValues = checked => {
    setUtiliseCustomValues(checked);

    if (checked) {
      const initialLabels = {};
      Object.entries(options).forEach(([key, value]) => {
        initialLabels[key] = String(value);
      });
      setOptionLabels(initialLabels);

      form.setFieldsValue({
        [`${elementKey}-useCustomValues`]: true,
      });
    } else {
      setLocalElementSchema(prevSchema => {
        const elementSchema = { ...prevSchema };
        delete elementSchema.enumNames;
        return elementSchema;
      });

      form.setFieldsValue({
        [`${elementKey}-useCustomValues`]: false,
      });
    }
  };

  const validateCustomValues = () => {
    if (!utiliseCustomValues) return Promise.resolve();
    if (localElementSchema.type !== 'string' && localElementSchema.type !== 'number')
      return Promise.resolve();

    const hasEmptyValue = Object.entries(options).some(([key, value]) => {
      if (localElementSchema.type === 'string') {
        return value === '';
      } else if (localElementSchema.type === 'number') {
        return value === undefined || value === null;
      }
      return false;
    });

    if (hasEmptyValue) {
      return Promise.reject(new Error(t('forms:FormWizard.steps.builder.allCustomValuesRequired')));
    }

    return Promise.resolve();
  };

  const renderBooleanOptions = () => (
    <div className="boolean-names-container">
      <div className="boolean-names-container-inner">
        <h4>{t('forms:FormWizard.steps.builder.default')}</h4>
        <h4>{t('forms:FormWizard.steps.builder.label')}</h4>
      </div>
      <div className="boolean-names-container-inner">
        <Checkbox
          checked={defaults[0] === true}
          onChange={e => handleDefaultsChange(true, e.target.checked)}
        />
        <Input
          value={localElementSchema.enumNames?.[0] || localisedYes}
          placeholder={t('forms:FormWizard.steps.builder.yes')}
          onChange={e => {
            const value = e.target.value;
            setLocalElementSchema(prevSchema => ({
              ...prevSchema,
              enumNames: [value, localElementSchema.enumNames?.[1] || localisedNo],
            }));
          }}
        />
      </div>
      <div className="boolean-names-container-inner">
        <Checkbox
          checked={defaults[0] === false}
          onChange={e => handleDefaultsChange(false, e.target.checked)}
        />
        <Input
          value={localElementSchema.enumNames?.[1] || localisedNo}
          placeholder={t('forms:FormWizard.steps.builder.no')}
          onChange={e => {
            const value = e.target.value;
            setLocalElementSchema(prevSchema => ({
              ...prevSchema,
              enumNames: [localElementSchema.enumNames?.[0] || localisedYes, value],
            }));
          }}
        />
      </div>
    </div>
  );

  const renderOptions = () => {
    if (!localElementSchema.type || !options) return null;

    return (
      <Form.Item>
        {Object.entries(options).length > 0 && (
          <>
            <div className="custom-values-toggle">
              <Checkbox
                checked={utiliseCustomValues}
                onChange={e => toggleCustomValues(e.target.checked)}
              >
                {t('forms:FormWizard.steps.builder.useCustomValues')}
              </Checkbox>
            </div>
            <div className="option-header">
              <h4>{t('forms:FormWizard.steps.builder.default')}</h4>
              {utiliseCustomValues ? (
                <div className="custom-values-header">
                  <h4>{t('forms:FormWizard.steps.builder.label')}</h4>
                  <h4>{t('forms:FormWizard.steps.builder.value')}</h4>
                </div>
              ) : (
                <h4>{t('forms:FormWizard.steps.builder.option')}</h4>
              )}
            </div>
          </>
        )}

        {Object.entries(options).map(([key, value]) => (
          <div key={key} className="option-container">
            {!utiliseCustomValues ? (
              <div className="option-container-inner">
                <Checkbox
                  value={key}
                  checked={defaults.includes(key)}
                  onChange={e => handleDefaultsChange(key, e.target.checked)}
                />
                {localElementSchema.type === 'string' ? (
                  <Input
                    placeholder={t('forms:FormWizard.steps.builder.option')}
                    value={value}
                    onChange={e => {
                      const newValue = e.target.value;
                      setOptions(prevOptions => ({
                        ...prevOptions,
                        [key]: newValue,
                      }));
                    }}
                  />
                ) : (
                  <InputNumber
                    placeholder={t('forms:FormWizard.steps.builder.option')}
                    value={value}
                    onChange={val => {
                      if (typeof val === 'number') {
                        setOptions(prevOptions => ({
                          ...prevOptions,
                          [key]: val,
                        }));
                      }
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="option-container-inner custom-values">
                <Checkbox
                  value={key}
                  checked={defaults.includes(key)}
                  onChange={e => handleDefaultsChange(key, e.target.checked)}
                />
                <Input
                  placeholder={t('forms:FormWizard.steps.builder.label')}
                  value={optionLabels[key] || ''}
                  onChange={e => {
                    const newLabel = e.target.value;
                    setOptionLabels(prevLabels => ({
                      ...prevLabels,
                      [key]: newLabel,
                    }));
                  }}
                />
                {localElementSchema.type === 'string' ? (
                  <Input
                    placeholder={t('forms:FormWizard.steps.builder.value')}
                    value={value}
                    onChange={e => {
                      const newValue = e.target.value;
                      setOptions(prevOptions => ({
                        ...prevOptions,
                        [key]: newValue,
                      }));
                    }}
                  />
                ) : (
                  <InputNumber
                    placeholder={t('forms:FormWizard.steps.builder.value')}
                    value={value}
                    onChange={val => {
                      if (typeof val === 'number') {
                        setOptions(prevOptions => ({
                          ...prevOptions,
                          [key]: val,
                        }));
                      }
                    }}
                  />
                )}
              </div>
            )}
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => {
                const newOptions = { ...options };
                delete newOptions[key];
                setOptions(newOptions);

                if (utiliseCustomValues) {
                  const newLabels = { ...optionLabels };
                  delete newLabels[key];
                  setOptionLabels(newLabels);
                }

                setDefaults(prevDefaults => prevDefaults.filter(d => d !== key));

                if (Object.keys(newOptions).length === 0) {
                  form.setFieldsValue({
                    [`${elementKey}-options`]: undefined,
                  });
                }
              }}
            />
          </div>
        ))}

        {utiliseCustomValues && (
          <Form.Item
            validateStatus={form.getFieldError(`${elementKey}-useCustomValues`) ? 'error' : ''}
            help={form.getFieldError(`${elementKey}-useCustomValues`)?.[0] || ''}
          >
            {form.getFieldDecorator(`${elementKey}-useCustomValues`, {
              rules: [
                {
                  validator: validateCustomValues,
                },
              ],
            })(<span />)}
          </Form.Item>
        )}

        <Button
          className="add-option-button"
          onClick={() => {
            const newUuid = uuid();
            setOptions(prevOptions => ({
              ...prevOptions,
              [newUuid]: localElementSchema.type === 'number' ? 0 : '',
            }));

            if (utiliseCustomValues) {
              setOptionLabels(prevLabels => ({
                ...prevLabels,
                [newUuid]: '',
              }));
            }

            form.setFieldsValue({
              [`${elementKey}-options`]: newUuid,
            });
          }}
          type="primary"
        >
          {t('forms:FormWizard.steps.builder.addOption')}
        </Button>

        {form.getFieldDecorator(`${elementKey}-options`, {
          rules: [
            {
              required: true,
              message: t('forms:FormWizard.steps.builder.optionsRequired'),
            },
          ],
        })(<span />)}
      </Form.Item>
    );
  };

  return (
    <div className="multiple-choice">
      <Form>
        <div className="data-type-and-widget-container">
          <Form.Item>
            <Select
              placeholder={t('forms:FormWizard.steps.builder.dataType')}
              value={localElementSchema.type}
              onChange={value => {
                setLocalElementSchema(prevSchema => ({
                  ...prevSchema,
                  type: value,
                  isCollapsed: false,
                }));
                form.setFieldsValue({
                  [`${elementKey}-dataType`]: value,
                });
              }}
            >
              <Select.Option value="boolean">
                {t('forms:FormWizard.steps.builder.yesOrNo')}
              </Select.Option>
              <Select.Option value="string">
                {t('forms:FormWizard.steps.builder.text')}
              </Select.Option>
              <Select.Option value="number">
                {t('forms:FormWizard.steps.builder.number')}
              </Select.Option>
            </Select>
            {!form.getFieldError(`${elementKey}-dataType`) &&
              form.getFieldError(`${elementKey}-widget`) && <p>-</p>}
            {form.getFieldDecorator(`${elementKey}-dataType`, {
              rules: [
                { required: true, message: t('forms:FormWizard.steps.builder.dataTypeRequired') },
              ],
            })(<span />)}
          </Form.Item>
          <Form.Item>
            <Select
              placeholder={t('forms:FormWizard.steps.builder.widget')}
              value={localElementSchema['ui:widget']}
              onChange={value => {
                setLocalElementSchema(prevSchema => ({
                  ...prevSchema,
                  'ui:widget': value,
                  isCollapsed: false,
                }));
                form.setFieldsValue({
                  [`${elementKey}-widget`]: value,
                });
              }}
            >
              <Select.Option value="radio">
                {t('forms:FormWizard.steps.builder.radio')}
              </Select.Option>
              <Select.Option value="select">
                {t('forms:FormWizard.steps.builder.dropdown')}
              </Select.Option>
              <Select.Option value="checkboxes" disabled={localElementSchema.type === 'boolean'}>
                {t('forms:FormWizard.steps.builder.checkbox')}
              </Select.Option>
            </Select>
            {!form.getFieldError(`${elementKey}-widget`) &&
              form.getFieldError(`${elementKey}-dataType`) && <p>-</p>}
            {form.getFieldDecorator(`${elementKey}-widget`, {
              rules: [
                { required: true, message: t('forms:FormWizard.steps.builder.widgetRequired') },
              ],
            })(<span />)}
          </Form.Item>
        </div>

        <div>
          {localElementSchema.type === 'boolean' ? renderBooleanOptions() : renderOptions()}
        </div>
      </Form>
    </div>
  );
}

function SliderQuestion({ form, elementKey, localElementSchema, setLocalElementSchema }) {
  const { t } = useTranslation();

  const maxMustBeGreaterThanMin = (rule, value, callback) => {
    if (localElementSchema.maximum <= localElementSchema.minimum) {
      callback(t('forms:FormWizard.steps.builder.maxMustBeGreaterThanMin'));
    } else {
      callback();
    }
  };

  return (
    <div className="slider-question">
      <div className="min-max-container">
        <Form.Item>
          <InputNumber
            value={localElementSchema.minimum}
            onChange={value => {
              setLocalElementSchema(prevSchema => ({
                ...prevSchema,
                minimum: value,
              }));
              form.setFieldsValue({
                [`${elementKey}-minMax`]: value,
              });
            }}
            min={-DEFAULT_NUMBER_INPUT_MAX}
            max={DEFAULT_NUMBER_INPUT_MAX}
          />
          <p>{t('forms:FormWizard.steps.builder.to')}</p>
          <InputNumber
            value={localElementSchema.maximum}
            onChange={value => {
              setLocalElementSchema(prevSchema => ({
                ...prevSchema,
                maximum: value,
              }));
              form.setFieldsValue({
                [`${elementKey}-minMax`]: value,
              });
            }}
            min={-DEFAULT_NUMBER_INPUT_MAX}
            max={DEFAULT_NUMBER_INPUT_MAX}
          />
          {form.getFieldDecorator(`${elementKey}-minMax`, {
            rules: [{ validator: maxMustBeGreaterThanMin }],
          })(<span />)}
        </Form.Item>
      </div>
    </div>
  );
}

function ElementCardTitle({ elementKey, localElementSchema, setLocalElementSchema, form }) {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState(false);
  const [hasTitleInputBeenFocusedBefore, setHasTitleInputBeenFocusedBefore] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (localElementSchema.isNewElement) {
      setIsEditing(true);
      setHasTitleInputBeenFocusedBefore(true);
      setLocalElementSchema(prevSchema => ({
        ...prevSchema,
        isNewElement: false,
      }));
    }
  }, []);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [hasTitleInputBeenFocusedBefore, isEditing]);

  useEffect(() => {
    if (!isEditing && !hasTitleInputBeenFocusedBefore && inputRef.current) {
      setIsEditing(true);
      inputRef.current.focus();
      setHasTitleInputBeenFocusedBefore(true);
    }
  }, [elementKey]);

  useEffect(() => {
    if (localElementSchema.title && form.getFieldValue(`${elementKey}-title`) === undefined) {
      form.setFieldsValue({
        [`${elementKey}-title`]: localElementSchema.title,
      });
    }
    if (!localElementSchema.title && form.getFieldValue(`${elementKey}-title`) !== undefined) {
      form.setFieldsValue({
        [`${elementKey}-title`]: undefined,
      });
    }
  }, [localElementSchema.title, form, elementKey]);

  return (
    <div className="element-card-title">
      <Form.Item>
        {isEditing ? (
          <Input
            ref={inputRef}
            value={localElementSchema.title}
            onChange={e => {
              const value = e.target.value;
              setLocalElementSchema(prevSchema => ({ ...prevSchema, title: value }));
            }}
            onBlur={() => setIsEditing(false)}
            maxLength={DEFAULT_TEXT_INPUT_MAX_LENGTH}
            placeholder={`${elementTypeToTitleMap(t)[localElementSchema.elementType]} ${t(
              'forms:FormWizard.steps.builder.lowercaseTitle',
            )}`}
          />
        ) : (
          <Button type="dashed" onClick={() => setIsEditing(true)}>
            {localElementSchema.title ? (
              <p className="existing-title">{localElementSchema.title}</p>
            ) : (
              <p className="new-title">{`${
                elementTypeToTitleMap(t)[localElementSchema.elementType]
              } ${t('forms:FormWizard.steps.builder.lowercaseTitle')}`}</p>
            )}
          </Button>
        )}
        {form.getFieldDecorator(`${elementKey}-title`, {
          rules: [{ required: true, message: t('forms:FormWizard.steps.builder.titleRequired') }],
        })(<span />)}
      </Form.Item>
    </div>
  );
}

function ElementCard({
  schema,
  setSchema,
  numberOfElements,
  index,
  elementKey,
  elementSchema,
  saveChangesToElement,
  reorderElement,
  deleteElement,
  saveElementKeyChange,
  toggleHideAllCardsUnderSection,
  sectionsNotFollowedByFields,
  form,
  language,
}) {
  const { t } = useTranslation();

  const [localElementSchema, setLocalElementSchema] = useState(elementSchema);
  const [keyError, setKeyError] = useState(null);
  const [localKey, setLocalKey] = useState(elementKey);
  const [hidAllCardsUnderSection, setHidAllCardsUnderSection] = useState(false);
  const [requiredSwitchClicked, setRequiredSwitchClicked] = useState(false);

  const containerRef = useRef(null);

  const elementMap = {
    text: TextQuestion,
    number: NumberQuestion,
    multipleChoice: MultipleChoice,
    slider: SliderQuestion,
  };

  useEffect(() => {
    if (elementSchema.order !== localElementSchema.order) {
      setLocalElementSchema(prevSchema => ({
        ...prevSchema,
        order: elementSchema.order,
      }));
    }
  }, [elementSchema.order, localElementSchema.order]);

  useEffect(() => {
    if (elementSchema.isCollapsed !== localElementSchema.isCollapsed) {
      setLocalElementSchema(prevSchema => ({
        ...prevSchema,
        isCollapsed: elementSchema.isCollapsed,
      }));
    }
  }, [elementSchema.isCollapsed]);

  const handleBlur = event => {
    if (requiredSwitchClicked) {
      setRequiredSwitchClicked(false);
      return;
    }

    if (
      containerRef.current &&
      !containerRef.current.contains(event.relatedTarget) &&
      localKey === elementKey
    ) {
      saveChangesToElement(localKey, { ...localElementSchema, isCollapsed: true });
    }
  };

  const handleElementKeyBlur = () => {
    const keyRegex = /^[A-Za-z0-9-]+$/;
    if (localKey === elementKey) {
      if (keyError) {
        setKeyError(null);
      }
      return;
    }
    if (!localKey.trim()) {
      setLocalKey(elementKey);
      if (keyError) {
        setKeyError(null);
      }
      return;
    }
    if (!keyRegex.test(localKey)) {
      setKeyError(
        t('forms:FormWizard.steps.builder.keyInvalid', {
          localKey,
        }),
      );
      setLocalKey(elementKey);
      return;
    }
    if (schema[localKey] !== undefined) {
      setKeyError(t('forms:FormWizard.steps.builder.keyAlreadyExists', { localKey }));
      setLocalKey(elementKey);
      return;
    }
    setKeyError(null);
    saveElementKeyChange(elementKey, localKey, localElementSchema);
  };

  const formKeyElement = (
    <div className="form-key-element">
      <p>{t('forms:FormWizard.steps.builder.fieldId')}:</p>
      <Form.Item validateStatus={keyError ? 'error' : undefined} help={keyError || undefined}>
        <Input
          value={localKey}
          onChange={e => setLocalKey(e.target.value)}
          onBlur={handleElementKeyBlur}
        />
      </Form.Item>
      <Popover
        content={<p>{t('forms:FormWizard.steps.builder.fieldIdInfo')}</p>}
        title={t('forms:FormWizard.steps.builder.fieldIdInfoTitle')}
        placement="top"
        trigger="click"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </Popover>
    </div>
  );

  const actions =
    localElementSchema.elementType === 'section'
      ? [
          <>
            {hidAllCardsUnderSection ? (
              <Button
                type="link"
                onClick={() => {
                  toggleHideAllCardsUnderSection(elementKey, true);
                  setHidAllCardsUnderSection(false);
                }}
              >
                <FontAwesomeIcon icon={faEye} />
                {t('forms:FormWizard.steps.builder.expandSection')}
              </Button>
            ) : (
              <Button
                type="link"
                onClick={() => {
                  toggleHideAllCardsUnderSection(elementKey, false);
                  setHidAllCardsUnderSection(true);
                }}
              >
                <FontAwesomeIcon icon={faEyeSlash} />
                {t('forms:FormWizard.steps.builder.collapseSection')}
              </Button>
            )}
          </>,
          <FontAwesomeIcon
            icon={faTrash}
            onClick={e => {
              e.stopPropagation();
              deleteElement(elementKey);
            }}
          />,
        ]
      : localElementSchema.elementType === 'nullElement'
      ? [
          formKeyElement,
          <FontAwesomeIcon
            icon={faTrash}
            onClick={e => {
              e.stopPropagation();
              deleteElement(elementKey);
            }}
          />,
        ]
      : [
          <div className="required-switch">
            <p>{t('forms:FormWizard.steps.builder.required')}</p>
            <Switch
              checked={localElementSchema.required}
              onMouseDown={() => setRequiredSwitchClicked(true)}
              onChange={checked => {
                setLocalElementSchema(prevSchema => {
                  const newSchema = { ...prevSchema, required: checked, isCollapsed: false };
                  saveChangesToElement(localKey, newSchema);
                  return newSchema;
                });
              }}
            />
          </div>,
          formKeyElement,
          <FontAwesomeIcon
            icon={faTrash}
            onClick={e => {
              e.stopPropagation();
              deleteElement(elementKey);
            }}
          />,
        ];

  const validateSectionNotFollowedByFields = (rule, value, callback) => {
    if (
      localElementSchema.elementType === 'section' &&
      sectionsNotFollowedByFields.includes(elementKey)
    ) {
      callback(t('forms:FormWizard.steps.builder.sectionNotFollowedByFields'));
    } else {
      callback();
    }
  };

  useEffect(() => {
    if (
      sectionsNotFollowedByFields.includes(elementKey) &&
      form.getFieldValue(`${elementKey}-sectionNotFollowedByFields`) === undefined
    ) {
      form.setFieldsValue({
        [`${elementKey}-sectionNotFollowedByFields`]: true,
      });
    }
    if (
      !sectionsNotFollowedByFields.includes(elementKey) &&
      form.getFieldValue(`${elementKey}-sectionNotFollowedByFields`) === true
    ) {
      form.setFieldsValue({
        [`${elementKey}-sectionNotFollowedByFields`]: undefined,
      });
    }
  }, [sectionsNotFollowedByFields, elementKey, form]);

  return (
    <div
      ref={containerRef}
      onBlur={handleBlur}
      tabIndex="0"
      className={`element-card-wrapper ${localElementSchema.isUnderSection ? 'under-section' : ''}`}
    >
      <Card
        className={`element-card ${keyError ? 'element-card-error' : ''} ${
          elementSchema.elementType === 'section' ? 'section-card' : ''
        } ${elementSchema.isCollapsed ? 'collapsed' : ''} ${
          elementSchema.elementType === 'nullElement' ? 'null-element' : ''
        }`}
        title={
          <ElementCardTitle
            schema={schema}
            elementKey={elementKey}
            localElementSchema={localElementSchema}
            setLocalElementSchema={setLocalElementSchema}
            form={form}
          />
        }
        actions={actions}
        extra={
          <div className="element-card-actions">
            <ReorderButton
              upDisabled={index === 0}
              downDisabled={index >= numberOfElements - 1}
              onClickUp={() => reorderElement(elementKey, 'up')}
              onClickDown={() => reorderElement(elementKey, 'down')}
            />
          </div>
        }
        onClick={() => {
          if (elementSchema.isCollapsed === true) {
            setSchema(prevSchema => {
              const newSchema = { ...prevSchema };
              if (newSchema[elementKey]) {
                newSchema[elementKey].isCollapsed = false;
              }
              return newSchema;
            });
          }
        }}
      >
        <div
          className="element-card-content"
          style={{ display: elementSchema.isCollapsed ? 'none' : 'block' }}
        >
          {localElementSchema.elementType === 'section' && (
            <p className="section-description">
              {t('forms:FormWizard.steps.builder.sectionDescription')}
            </p>
          )}
          {localElementSchema.elementType === 'nullElement' && (
            <p className="null-element-description">
              {t('forms:FormWizard.steps.builder.nullElementDescription')}
            </p>
          )}
          <Form.Item>
            {form.getFieldDecorator(`${elementKey}-sectionNotFollowedByFields`, {
              rules: [{ validator: validateSectionNotFollowedByFields }],
            })(<span />)}
          </Form.Item>
          <Form.Item>
            <Input.TextArea
              placeholder={t('forms:FormWizard.steps.builder.description')}
              value={localElementSchema.description}
              onChange={e => {
                const value = e.target.value;
                setLocalElementSchema(prevSchema => ({
                  ...prevSchema,
                  description: value,
                }));
              }}
              maxLength={DEFAULT_TEXT_INPUT_MAX_LENGTH}
            />
          </Form.Item>
          {elementMap[localElementSchema.elementType]
            ? elementMap[localElementSchema.elementType]({
                elementKey,
                form,
                localElementSchema,
                setLocalElementSchema,
                language,
              })
            : null}
        </div>
      </Card>
    </div>
  );
}

function AddElementDividerButton({ t, handleAddElement, elementInsertionIndex }) {
  const getAddElementMenu = insertionIndex => (
    <Menu>
      <Menu.Item key="section" onClick={() => handleAddElement('section', insertionIndex)}>
        {t('forms:FormWizard.steps.builder.section')}
      </Menu.Item>
      <Menu.Item key="nullElement" onClick={() => handleAddElement('nullElement', insertionIndex)}>
        {t('forms:FormWizard.steps.builder.nullElement')}
      </Menu.Item>
      <Menu.Item key="text" onClick={() => handleAddElement('text', insertionIndex)}>
        {t('forms:FormWizard.steps.builder.text')}
      </Menu.Item>
      <Menu.Item key="number" onClick={() => handleAddElement('number', insertionIndex)}>
        {t('forms:FormWizard.steps.builder.number')}
      </Menu.Item>
      <Menu.Item
        key="multipleChoice"
        onClick={() => handleAddElement('multipleChoice', insertionIndex)}
      >
        {t('forms:FormWizard.steps.builder.multipleChoice')}
      </Menu.Item>
      <Menu.Item key="date" onClick={() => handleAddElement('date', insertionIndex)}>
        {t('forms:FormWizard.steps.builder.date')}
      </Menu.Item>
      <Menu.Item key="slider" onClick={() => handleAddElement('slider', insertionIndex)}>
        {t('forms:FormWizard.steps.builder.slider')}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="divider-container">
      <Divider>
        <Dropdown overlay={getAddElementMenu(elementInsertionIndex)} placement="bottomCenter">
          <FontAwesomeIcon icon={faPlusCircle} />
        </Dropdown>
      </Divider>
    </div>
  );
}

function FormBuilder({
  isFormPreviewModalVisible,
  setIsFormPreviewModalVisible,
  isEditComputationsModalVisible,
  setIsEditComputationsModalVisible,
  setHasComputations,
  formType,
  setFormType,
}) {
  const { t } = useTranslation();
  const {
    formData,
    currentStepData,
    setCurrentStepData,
    setFormData,
    onNextStep,
    form,
  } = useWizard();

  const language = formData?.details.language;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [schema, setSchema] = useState({});
  const [rjsfSchema, setRjsfSchema] = useState({});
  const [errors, setErrors] = useState([]);
  const [sectionsNotFollowedByFields, setSectionsNotFollowedByFields] = useState([]);

  useEffect(() => {
    if (currentStepData?.schema && Object.keys(currentStepData.schema).length) {
      const { internalSchema, title: rjsfTitle } = fromRJSF(currentStepData.schema);
      setSchema(internalSchema || {});
      setTitle(rjsfTitle || '');
      setDescription(currentStepData.description || '');
      setErrors([]);
      return;
    }
    if (formData?.content?.json && !formData?.builder?.startedFromScratch) {
      const uploadedSchema = formData.content.json;
      const validateErrors = validateFormSchema(uploadedSchema);
      if (validateErrors.length > 0) {
        setErrors(validateErrors);
        setSchema({});
        setTitle('');
        setDescription('');
      } else {
        const {
          internalSchema,
          title: rjsfTitle,
          description: rjsfDescription,
          formType: rjsfFormType,
        } = fromRJSF(uploadedSchema);
        setSchema(internalSchema || {});
        setTitle(rjsfTitle || '');
        setDescription(rjsfDescription || '');
        setErrors([]);
        setFormType(rjsfFormType || 'standard');
      }
    }
  }, []);

  useEffect(() => {
    if (errors.length > 0) return;

    if (!Object.keys(schema).length) {
      if (Object.keys(rjsfSchema).length) {
        setRjsfSchema({});
      }

      return;
    }

    const updatedRjsf = {
      ...toRJSF(schema, undefined, description, undefined, undefined, title, formType),
      computations: currentStepData?.schema?.computations || formData?.content?.json?.computations,
    };
    const currentWizardSchema = currentStepData?.schema || {};

    if (JSON.stringify(updatedRjsf) !== JSON.stringify(currentWizardSchema)) {
      setCurrentStepData({ schema: updatedRjsf });
    }

    if (JSON.stringify(updatedRjsf) !== JSON.stringify(rjsfSchema)) {
      setRjsfSchema(updatedRjsf);
    }
  }, [
    schema,
    title,
    description,
    errors,
    currentStepData,
    setCurrentStepData,
    rjsfSchema,
    formType,
  ]);

  useEffect(() => {
    if (
      errors.length === 0 &&
      Object.entries(formData.content.json?.computations || {}).length > 0
    ) {
      setHasComputations(true);
    } else {
      setHasComputations(false);
    }
  }, [errors, formData.content.json?.computations, setHasComputations]);

  useEffect(() => {
    if (title && !form.getFieldValue('title')) {
      form.setFieldsValue({
        title: title,
      });
    }
    if (!title && form.getFieldValue('title') !== undefined) {
      form.setFieldsValue({
        title: undefined,
      });
    }
  }, [title, form]);

  const elementSchemaMap = {
    nullElement: {
      elementType: 'nullElement',
      title: '',
      description: '',
      type: 'null',
    },
    text: {
      elementType: 'text',
      title: '',
      description: '',
      type: 'string',
      'ui:widget': 'input',
      required: false,
      placeholder: '',
    },
    number: {
      elementType: 'number',
      title: '',
      description: '',
      type: 'number',
      'ui:widget': 'input',
      required: false,
      placeholder: '',
    },
    multipleChoice: {
      elementType: 'multipleChoice',
      title: '',
      description: '',
      type: undefined,
      'ui:widget': undefined,
      required: false,
    },
    date: {
      elementType: 'date',
      title: '',
      description: '',
      type: 'string',
      format: 'date',
      required: false,
    },
    slider: {
      elementType: 'slider',
      title: '',
      description: '',
      type: 'number',
      'ui:widget': 'range',
      minimum: 0,
      maximum: 100,
      required: false,
    },
    section: {
      elementType: 'section',
      title: '',
      description: '',
    },
  };

  const handleAddElement = (elementType, insertionIndex = null) => {
    setSchema(prevSchema => {
      const sortedSchemaAsEntries = Object.entries(prevSchema)
        .sort((a, b) => a[1].order - b[1].order)
        .map(([key, value]) => [
          key,
          { ...value, isCollapsed: elementKeysOfElementsWithErrors.includes(key) ? false : true },
        ]);
      if (insertionIndex === null) {
        insertionIndex = sortedSchemaAsEntries.length;
      }
      for (let i = insertionIndex; i < sortedSchemaAsEntries.length; i++) {
        sortedSchemaAsEntries[i][1].order += 1;
      }
      let elementId = '';
      if (elementType === 'section') {
        elementId = getNextKeyWithPrefix(prevSchema, 's');
      } else if (elementType === 'nullElement') {
        elementId = getNextKeyWithPrefix(prevSchema, 'n');
      } else {
        elementId = getNextQKey(prevSchema);
      }
      const newElement = {
        ...elementSchemaMap[elementType],
        order: insertionIndex,
        isCollapsed: false,
        isNewElement: true,
      };
      sortedSchemaAsEntries.splice(insertionIndex, 0, [elementId, newElement]);
      return Object.fromEntries(sortedSchemaAsEntries);
    });
  };

  const sortedSchemaAsEntries = useMemo(() => {
    const sorted = Object.entries(schema).sort((a, b) => a[1].order - b[1].order);

    const firstSectionOrder = sorted.find(([, value]) => value.elementType === 'section')?.[1]
      ?.order;

    if (firstSectionOrder === undefined) {
      return sorted.map(([key, val]) => [key, { ...val, isUnderSection: false }]);
    }

    return sorted.map(([key, val]) => {
      const isUnderSection = val.elementType !== 'section' && val.order >= firstSectionOrder;
      return [
        key,
        {
          ...val,
          isUnderSection,
        },
      ];
    });
  }, [schema]);

  const numberOfElements = useMemo(() => sortedSchemaAsEntries.length, [sortedSchemaAsEntries]);

  useEffect(() => {
    const sectionWithoutFollowingFields = [];
    for (let i = 0; i < sortedSchemaAsEntries.length; i++) {
      const [key, value] = sortedSchemaAsEntries[i];
      if (value.elementType === 'section') {
        if (
          i === sortedSchemaAsEntries.length - 1 ||
          sortedSchemaAsEntries[i + 1][1].elementType === 'section'
        ) {
          sectionWithoutFollowingFields.push(key);
        }
      }
    }
    setSectionsNotFollowedByFields(sectionWithoutFollowingFields);
  }, [schema]);

  const elementKeysOfElementsWithErrors = useMemo(() => {
    const fieldsWithErrorsKeys = Object.entries(form.getFieldsError())
      .filter(field => field[1] !== undefined)
      .map(field => field[0]);
    return fieldsWithErrorsKeys.map(key => {
      const splitKey = key.split('-');
      splitKey.pop();
      return splitKey.join('-');
    });
  }, [form]);

  useEffect(() => {
    setSchema(prevSchema => {
      const newSchema = { ...prevSchema };
      Object.keys(newSchema).forEach(key => {
        if (elementKeysOfElementsWithErrors.includes(key) || newSchema[key].title === '') {
          newSchema[key].isCollapsed = false;
        }
      });
      return newSchema;
    });
  }, [elementKeysOfElementsWithErrors]);

  const saveChangesToElement = (elementKey, elementSchema) => {
    let key = elementKey;
    if (
      elementSchema.title.length > 0 &&
      !schema[elementKey].title &&
      /^q(\d+)$/.exec(elementKey)
    ) {
      let keySlug = elementSchema.title
        .toLowerCase()
        .trim()
        .slice(0, 50)
        .split(' ')
        .filter(word => /^[a-zA-Z0-9]+$/.test(word))
        .join('-');

      if (schema[keySlug]) {
        keySlug = `${getNextQKey(schema)}-${keySlug}`;
      }

      if (keySlug.length > 0) {
        setSchema(prevSchema => {
          const newSchema = { ...prevSchema };
          newSchema[keySlug] = elementSchema;
          delete newSchema[elementKey];
          return newSchema;
        });

        key = keySlug;
      }
    }

    setSchema(prevSchema => ({
      ...prevSchema,
      [key]: elementSchema,
    }));
  };

  const saveElementKeyChange = (currentElementKey, newElementKey, localElementSchema) => {
    setSchema(prevSchema => {
      const newSchema = { ...prevSchema };
      newSchema[newElementKey] = localElementSchema;
      delete newSchema[currentElementKey];
      return newSchema;
    });
  };

  const deleteElement = elementKey => {
    setSchema(prevSchema => {
      const newSchema = { ...prevSchema };
      delete newSchema[elementKey];
      return newSchema;
    });
  };

  const reorderElement = (elementKey, direction) => {
    setSchema(prevSchema => {
      const entries = Object.entries(prevSchema).sort((a, b) => a[1].order - b[1].order);
      const idx = entries.findIndex(([k]) => k === elementKey);
      if (idx < 0) return prevSchema;
      let newIdx = idx;
      if (direction === 'up' && idx > 0) {
        newIdx = idx - 1;
      } else if (direction === 'down' && idx < entries.length - 1) {
        newIdx = idx + 1;
      }
      if (newIdx === idx) {
        return prevSchema;
      }
      const tempOrder = entries[idx][1].order;
      entries[idx][1].order = entries[newIdx][1].order;
      entries[newIdx][1].order = tempOrder;
      return Object.fromEntries(entries);
    });
  };

  const toggleHideAllCardsUnderSection = (elementKey, isReveal) => {
    const sortedElements = Object.entries(schema).sort((a, b) => a[1].order - b[1].order);
    const sectionIndex = sortedElements.findIndex(([key]) => key === elementKey);
    let nextSectionIndex = sectionIndex;
    for (let i = sectionIndex + 1; i < sortedElements.length; i++) {
      if (sortedElements[i][1].elementType === 'section') {
        nextSectionIndex = i;
        break;
      }
    }
    if (nextSectionIndex === sectionIndex) {
      nextSectionIndex = sortedElements.length;
    }
    for (let i = sectionIndex + 1; i < nextSectionIndex; i++) {
      sortedElements[i][1].isHidden = isReveal ? false : true;
    }
    setSchema(Object.fromEntries(sortedElements));
  };

  const handleSaveComputations = computations => {
    setIsEditComputationsModalVisible(false);
    if (computations?.length === 0) {
      setCurrentStepData({
        ...currentStepData,
        schema: { ...currentStepData.schema, computations: {} },
      });
      return;
    }

    if (typeof computations === 'string' && computations.length > 0) {
      const parsedComputations = JSON.parse(computations);
      setCurrentStepData({
        ...currentStepData,
        schema: {
          ...currentStepData.schema,
          computations: parsedComputations,
        },
      });
    }
  };

  const handleStartFromScratch = () => {
    setFormData({
      ...formData,
      builder: {
        schema: undefined,
        startedFromScratch: true,
      },
    });
    setTitle('');
    setDescription('');
    setSchema({});
    setRjsfSchema({});
    setErrors([]);
  };

  return (
    <div className="form-builder">
      {errors.length > 0 ? (
        <div className="element-card-wrapper">
          <Card
            className="schema-errors"
            title={t('forms:FormWizard.steps.builder.incompatibleFormConfig')}
          >
            <h4>{t('forms:FormWizard.steps.builder.incompatibleFormConfig')}</h4>
            <div className="button-container">
              <Button type="primary" onClick={onNextStep}>
                {t('forms:FormWizard.steps.builder.useFormConfigFile')}
              </Button>
              <Button type="primary" onClick={handleStartFromScratch}>
                {t('forms:FormWizard.steps.builder.discardFormConfigFile')}
              </Button>
            </div>
            <p>{t('forms:FormWizard.steps.builder.fixErrors')}</p>
            <List
              dataSource={errors}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={`${t('forms:FormWizard.steps.builder.field')}: ${item.field}`}
                    description={`${t('forms:FormWizard.steps.builder.error')}: ${t(item.message)}`}
                  />
                </List.Item>
              )}
            />
          </Card>
        </div>
      ) : (
        <>
          <div className="element-card-wrapper">
            <Card
              className="element-card form-title-card"
              title={t('forms:FormWizard.steps.builder.formTitle')}
            >
              <Form.Item>
                <Input
                  value={title}
                  onChange={e => {
                    setTitle(e.target.value);
                  }}
                  placeholder={t('forms:FormWizard.steps.builder.formTitle')}
                />
                {form.getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: t('forms:FormWizard.steps.builder.formTitleRequired'),
                    },
                  ],
                })(<span />)}
              </Form.Item>
              <Input.TextArea
                placeholder={t('forms:FormWizard.steps.builder.description')}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Card>
          </div>

          <AddElementDividerButton
            t={t}
            handleAddElement={handleAddElement}
            elementInsertionIndex={0}
          />

          {sortedSchemaAsEntries
            .filter(([key, value]) => !value.isHidden)
            .map(([key, element], index) => (
              <Fragment key={key}>
                <ElementCard
                  schema={schema}
                  setSchema={setSchema}
                  numberOfElements={numberOfElements}
                  index={index}
                  elementKey={key}
                  elementSchema={element}
                  saveChangesToElement={saveChangesToElement}
                  reorderElement={reorderElement}
                  deleteElement={deleteElement}
                  saveElementKeyChange={saveElementKeyChange}
                  toggleHideAllCardsUnderSection={toggleHideAllCardsUnderSection}
                  sectionsNotFollowedByFields={sectionsNotFollowedByFields}
                  form={form}
                  language={language}
                />
                <AddElementDividerButton
                  t={t}
                  handleAddElement={handleAddElement}
                  elementInsertionIndex={index + 1}
                />
              </Fragment>
            ))}
        </>
      )}

      <FormPreviewModal
        readOnly
        name={title}
        visible={isFormPreviewModalVisible}
        handleCloseModal={() => setIsFormPreviewModalVisible(false)}
        loading={false}
        schema={rjsfSchema.schema || {}}
        uiSchema={rjsfSchema.uiSchema || {}}
      />

      <EditComputationsModal
        isEditComputationsModalVisible={isEditComputationsModalVisible}
        setIsEditComputationsModalVisible={setIsEditComputationsModalVisible}
        initialComputations={currentStepData.schema?.computations}
        handleSaveComputations={handleSaveComputations}
        elementKeys={Object.keys(schema)}
      />
    </div>
  );
}

export default FormBuilder;
