import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import './style.less';

function EditComputationsModal({
  isEditComputationsModalVisible,
  setIsEditComputationsModalVisible,
  initialComputations,
  handleSaveComputations,
  elementKeys,
}) {
  const { t } = useTranslation();
  const [computations, setComputations] = useState();
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (typeof initialComputations === 'string' && initialComputations.length > 0) {
      const parsedComputations = JSON.parse(initialComputations);
      setComputations(JSON.stringify(parsedComputations, null, 4));
      return;
    }
    if (typeof initialComputations === 'object' && Object.keys(initialComputations).length > 0) {
      setComputations(JSON.stringify(initialComputations, null, 4));
      return;
    }
  }, [initialComputations]);

  const handleSave = () => {
    if (computations.length > 0) {
      try {
        const parsedComputations = JSON.parse(computations);
        handleSaveComputations(parsedComputations);
        setIsError(false);
      } catch (error) {
        setIsError(true);
      }
    } else {
      handleSaveComputations();
    }
  };

  const copyElementKey = async key => {
    await navigator.clipboard.writeText(key);
  };

  return (
    <Modal
      className="edit-computations-modal"
      title={t('forms:FormWizard.steps.builder.editComputations')}
      visible={isEditComputationsModalVisible}
      onCancel={() => setIsEditComputationsModalVisible(false)}
      centered
      footer={
        <div className="edit-computations-modal-footer">
          <Button onClick={() => setIsEditComputationsModalVisible(false)}>
            {t('forms:FormWizard.steps.builder.cancel')}
          </Button>
          <Button type="primary" onClick={handleSave}>
            {t('forms:FormWizard.steps.builder.save')}
          </Button>
        </div>
      }
    >
      <div className="edit-computations-modal-content">
        <div className="element-keys-container">
          <ul className="element-keys-list">
            {elementKeys
              .filter(key => !/^s\d+$/.test(key))
              .map(key => {
                return (
                  <li key={key}>
                    <Button type="link" onClick={() => copyElementKey(key)}>
                      {key}
                      <FontAwesomeIcon icon={faCopy} />
                    </Button>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="edit-computations-container">
          <Form.Item
            validateStatus={isError ? 'error' : ''}
            help={isError ? t('forms:FormWizard.steps.builder.invalidJson') : ''}
          >
            <Input.TextArea
              rows={elementKeys.length > 0 ? elementKeys.length * 1.5 : 10}
              value={computations}
              onChange={e => {
                setComputations(e.target.value);
                setIsError(false);
              }}
            />
          </Form.Item>
        </div>
      </div>
    </Modal>
  );
}

EditComputationsModal.propTypes = {
  isEditComputationsModalVisible: PropTypes.bool.isRequired,
  setIsEditComputationsModalVisible: PropTypes.func.isRequired,
  handleSaveComputations: PropTypes.func.isRequired,
};

export default EditComputationsModal;
