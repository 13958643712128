import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import { usePageActions } from '@hooks/usePageActions';
import { useTitleUpdate } from '@hooks';
import { Column, ColumnGroup } from '@cards/Card';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { fetchIndexEvents } from '@pathways/redux/indexEvents/actions';
import BasicDetailsApp from './BasicDetailsApp';
import BasicDetailsAdmin from './BasicDetailsAdmin';
import Journeys from '@pathways/pages/AppUsers/New/Journeys';
import ReviewAppUserDetailsCard from './ReviewAppUserDetailsCard';
import ReviewJourneys from '@pathways/pages/AppUsers/New/ReviewJourneys';
import { UCIDValidationErrorModal, useUCIDValidation } from '@components/UCIDInput';
import { screens, setCurrentScreen, track } from '../../../analytics';

import './style.less';
import { inviteAppUser } from '@redux/appUsers/actions';
import { selectAppUsersCreating, selectAppUsersLoading } from '@redux/appUsers/reducers';
import { selectAppUsersPathwaysCreating } from '@pathways/redux/appUserPathways/reducers';

export default function NewAppUser() {
  const { t } = useTranslation();
  const { setTitle } = usePageActions({
    title: t('patients:NewPatient.title'),
    showClose: true,
  });

  const dispatch = useDispatch();
  const creating = useSelector(selectAppUsersCreating);
  const loading = useSelector(selectAppUsersLoading);
  const creatingAppUserPathways = useSelector(selectAppUsersPathwaysCreating);
  const [
    shouldDisplayUCIDValidationErrorModal,
    setShouldDisplayUCIDValidationErrorModal,
  ] = useState(false);
  const validateUCIDs = useUCIDValidation();

  useEffect(() => {
    dispatch(fetchPathways());
    dispatch(fetchIndexEvents());
  });

  useEffect(() => {
    setCurrentScreen(screens.APP_USERS_CREATION);
    track('View step details');
  }, []);

  const onComplete = formData => {
    const pathways = formData.journeys?.journeys[0]?.procedures || [];
    const externalIds = pathways.reduce((acc, p) => {
      return [...acc, p.externalId];
    }, []);
    const ucidValidationResult = validateUCIDs(externalIds);
    if (!ucidValidationResult) {
      setShouldDisplayUCIDValidationErrorModal(true);
      return;
    }

    dispatch(inviteAppUser({ ...formData.details }, formData.journeys?.journeys || []));
  };

  useTitleUpdate(setTitle, 'patients:NewPatient.title');

  document.title = t('patients:NewPatient.title');

  return (
    <Wizard
      onCompleted={onComplete}
      loading={loading}
      submitting={creating || creatingAppUserPathways}
    >
      <WizardItem stepKey="details" title={t('patients:NewPatient.wizard.steps.basicDetails')}>
        <ColumnGroup>
          <Column>
            <BasicDetailsApp />
          </Column>
          <Column>
            <BasicDetailsAdmin />
          </Column>
        </ColumnGroup>
      </WizardItem>
      <WizardItem stepKey="journeys" title={t('patients:NewPatient.wizard.steps.journeys')}>
        <Column>
          <Journeys />
        </Column>
      </WizardItem>
      <WizardItem stepKey="review" title={t('patients:NewPatient.wizard.steps.review')}>
        <ColumnGroup>
          <Column>
            <ReviewAppUserDetailsCard />
          </Column>
        </ColumnGroup>
        <ColumnGroup>
          <Column>
            <ReviewJourneys />
            <UCIDValidationErrorModal
              visible={shouldDisplayUCIDValidationErrorModal}
              onClose={() => setShouldDisplayUCIDValidationErrorModal(false)}
            />
          </Column>
        </ColumnGroup>
      </WizardItem>
    </Wizard>
  );
}
