import React from 'react';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
// import SourceIcon from '@components/SourceIcon';
import LanguageIcon from '@components/LanguageIcon';
import IconButton from '@components/IconButton';
import { createLanguageFilters } from '@utils/supportedLanguages';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
// import { extractSourceFromOwnerId } from '@utils';
import './style.less';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

const allOrgsData = {};
const getOrgFilters = () => {
  return Object.entries(allOrgsData).map(([value, text]) => ({ text, value }));
};

export default (t, getOrgDetails, currentOrgData) => [
  {
    className: 'language-column',
    title: t('cards:ProcedurePathwayList.columns.language.title'),
    dataIndex: 'metadata',
    key: 'language',
    render: ({ language }) => (language ? <LanguageIcon language={language} /> : null),
    filters: createLanguageFilters(t),
    onFilter: (value, record) => {
      return record.metadata.language === value;
    },
  },
  // {
  //   className: 'source-column',
  //   title: t('cards:ProcedurePathwayList.columns.source.title'),
  //   dataIndex: 'ownerId',
  //   key: 'source',
  //   render: ownerId => <SourceIcon source={extractSourceFromOwnerId(ownerId)} />,
  //   // width: 130,
  //   sorter: (a, b) => {
  //     if (a.source > b.source) return -1;
  //     if (a.source < b.source) return 1;
  //     return 0;
  //   },
  //   filters: [
  //     {
  //       text: t('common:source.global'),
  //       value: 'global',
  //     },
  //     // {
  //     //   text: t('common:source.country'),
  //     //   value: 'country',
  //     // },
  //     {
  //       text: t('common:source.hospital'),
  //       value: 'hospital',
  //     },
  //   ],
  //   onFilter: (value, record) => (record.ownerId || '').includes(value),
  // },
  {
    title: t('cards:ProcedurePathwayList.columns.title'),
    // dataIndex: 'name',
    key: 'title',
    width: '25%',
    ellipsis: true,
    sorter: (a, b) => {
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
      return 0;
    },
    render: pathway => {
      console.log(pathway);
      if (!pathway.createdFromPathwayId) {
        return pathway.name;
      }
      return (
        <div className="pathway-name-container">
          <p>{pathway.name}</p>
          <IconButton
            icon={faInfoCircle}
            tooltip={
              pathway.createdFromSnapshotName ? (
                <>
                  {t('cards:ProcedurePathwayList.columns.fromSharedTooltip')}:<br />
                  {pathway.createdFromPathwayName} / {pathway.createdFromSnapshotName}
                </>
              ) : (
                <>
                  {t('cards:ProcedurePathwayList.columns.fromSharedNoSnapshotTooltip')}:<br />
                  {pathway.createdFromPathwayName}
                </>
              )
            }
          />
        </div>
      );
    },
  },
  {
    title: t('cards:ProcedurePathwayList.columns.description'),
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    width: '30%',
    sorter: (a, b) => {
      if (a.description > b.description) return -1;
      if (a.description < b.description) return 1;
      return 0;
    },
  },
  {
    title: t('cards:ProcedurePathwayList.columns.createdBy.title'),
    dataIndex: 'parentOrganisationSlug',
    key: 'parentOrganisationSlug',
    width: '10%',
    render: slug => {
      if (!slug) {
        allOrgsData['current'] = currentOrgData?.organisation_name;
        return currentOrgData?.organisation_name;
      }
      const [, data] = getOrgDetails(slug);
      allOrgsData[slug] = data?.organisation_name;
      return data?.organisation_name;
    },
    filters: getOrgFilters(),
    onFilter: (value, record) => {
      if (value === 'current' && record.parentOrganisationSlug === null) {
        return true;
      }
      return record.parentOrganisationSlug === value;
    },
  },
  {
    title: (
      <span>
        {t('cards:ProcedurePathwayList.columns.isManagedPathway.title')}{' '}
        {
          <IconButton
            icon={faInfoCircle}
            tooltip={t('cards:ProcedurePathwayList.columns.isManagedPathway.description')}
          />
        }
      </span>
    ),
    align: 'center',
    dataIndex: 'isManagedPathway',
    key: 'isManagedPathway',
    render: isManagedPathway => isManagedPathway && <FontAwesomeIcon icon={faCheck} />,
    width: '150px',
    filters: [
      {
        text: t('common:yes'),
        value: true,
      },
      {
        text: t('common:no'),
        value: false,
      },
    ],
    onFilter: (value, record) => record.isManagedPathway === value,
  },
  {
    className: 'published-column',
    title: t('cards:ProcedurePathwayList.columns.published.title'),
    dataIndex: 'isActive',
    fixed: 'right',
    key: 'isActive',
    render: isActive => <PublishedStatusIcon published={isActive} />,
    filters: [
      {
        text: t('cards:ProcedurePathwayList.columns.published.published'),
        value: true,
      },
      {
        text: t('cards:ProcedurePathwayList.columns.published.unpublished'),
        value: false,
      },
    ],
    onFilter: (value, record) => record.isActive === value,
  },
];
