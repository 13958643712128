import React, { useState } from 'react';
import { Modal, Radio, Table } from 'antd';
import { useTranslation } from 'react-i18next';

export default ({ visible, onCancel, onOk, sharedPathway, existingPathways }) => {
  const [showTable, setShowTable] = useState(false);
  const [selectedForUpdate, setSelectedForUpdate] = useState(null);

  const { t } = useTranslation();

  const onRadioChanged = e => {
    e.stopPropagation();
    e.preventDefault();
    setShowTable(e.target.value === 'update');
    setSelectedForUpdate(null);
  };

  const upToDate = existingPathways.every(
    pathway => pathway.snapshotNumber === sharedPathway.snapshotNumber,
  );

  const pathwaysWhichCanBeUpdated = existingPathways.filter(
    pathway => pathway.snapshotNumber !== sharedPathway.snapshotNumber,
  );

  const nextIsEnabled = !showTable || (showTable && selectedForUpdate);

  const _onOk = () => {
    const mode = showTable ? 'update' : 'create';
    onOk({ mode, selectedPathway: selectedForUpdate });
  };

  if (upToDate) {
    return (
      <OnlyUpToDatePathways
        visible={visible}
        onCancel={onCancel}
        onOk={_onOk}
        existingPathways={existingPathways}
      />
    );
  }

  return (
    <Modal
      className="shared-pathways-use-pathway-modal"
      title={t('pathways:SharedPathways.modals.confirmUpdate.title')}
      visible={visible}
      onOk={_onOk}
      onCancel={onCancel}
      okText={t('pathways:SharedPathways.modals.confirmUpdate.okButton')}
      okButtonProps={{ disabled: !nextIsEnabled }}
    >
      <p>{t('pathways:SharedPathways.modals.confirmUpdate.body')}</p>
      <p>
        <strong>{t('pathways:SharedPathways.modals.confirmUpdate.existing')}</strong>
      </p>
      <ul>
        {existingPathways.map(childPathway => (
          <li>
            <a href={`/procedure/pathways/${childPathway.pathwayId}`}>{childPathway.pathwayName}</a>
          </li>
        ))}
      </ul>
      <p>{t('pathways:SharedPathways.modals.confirmUpdate.howToContinue')}</p>
      <Radio.Group name="action" defaultValue="create" onChange={onRadioChanged}>
        <Radio value="create">
          {t('pathways:SharedPathways.modals.confirmUpdate.createOption')}
        </Radio>
        <br />
        <Radio value="update" disabled={upToDate}>
          {t('pathways:SharedPathways.modals.confirmUpdate.updateOption', {
            shapshotNumber: sharedPathway.snapshotNumber,
          })}
        </Radio>
      </Radio.Group>
      {showTable && (
        <Table
          rowKey="pathwayId"
          dataSource={pathwaysWhichCanBeUpdated}
          pagination={false}
          rowSelection={{ type: 'radio', onSelect: record => setSelectedForUpdate(record) }}
        >
          <Table.Column
            title={t('pathways:SharedPathways.modals.confirmUpdate.pathwayName')}
            dataIndex="pathwayName"
            key="pathwayName"
            width="70%"
          />
          <Table.Column
            title={t('pathways:SharedPathways.modals.confirmUpdate.currentSnapshot')}
            dataIndex="snapshotNumber"
            key="snapshotNumber"
          />
        </Table>
      )}
    </Modal>
  );
};

const OnlyUpToDatePathways = ({ visible, onOk, onCancel, existingPathways }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('pathways:SharedPathways.modals.confirmCreate.title')}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={t('pathways:SharedPathways.modals.confirmCreate.okButton')}
    >
      <p>{t('pathways:SharedPathways.modals.confirmCreate.body')}</p>
      <p>
        <strong>{t('pathways:SharedPathways.modals.confirmCreate.existing')}</strong>
      </p>
      <ul>
        {existingPathways.map(childPathway => (
          <li>
            <a href={`/procedure/pathways/${childPathway.pathwayId}`}>{childPathway.pathwayName}</a>
          </li>
        ))}
      </ul>
      <p>{t('pathways:SharedPathways.modals.confirmCreate.question')}</p>
    </Modal>
  );
};
