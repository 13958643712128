export const mapSnakeCaseAppUserPathwayToCamelCase = ({
  id,
  url,
  original_pathway,
  current_stage_slug,
  disabled_rule_ids,
  journey_id,
  is_active,
  external_id,
}) => ({
  id,
  url,
  originalPathway:
    typeof original_pathway === 'number'
      ? original_pathway
      : extractAppUserPathwayIdFromUrl(original_pathway),
  currentStageSlug: current_stage_slug,
  disabledRuleIds: disabled_rule_ids,
  journeyId: journey_id,
  isActive: is_active === undefined ? true : is_active,
  externalId: external_id && external_id !== 'undefined' ? external_id : '',
});

export function extractAppUserPathwayIdFromUrl(url) {
  const matchResults = /\/pathways\/(\d+)/.exec(url);

  if (matchResults[1]) return parseInt(matchResults[1], 10);

  throw new Error('Invalid AppUserPathway original pathway url format');
}
