import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, oneOf, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Icon, Input, Select } from 'antd';
import { selectHospitalsInAlphabeticalOrder } from '@redux/hospitals/reducers';
import { fetchHospital, fetchHospitals } from '@redux/hospitals/actions';
import DetailForm from '@components/DetailForm';
import { Card } from '@cards/Card';
import { selectDashboardUserIcon } from '@assets/icons';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import { selectDashboardUserHospital } from '@redux/login/reducer';
import {
  DASHBOARD_USER_TYPES,
  SUPER_ADMIN,
  SUPPORT_ADMIN,
  SYSTEM_ADMIN,
  HOSPITAL_ADMIN,
  HOSPITAL_USER,
  HOSPITAL_USER_TYPES,
} from '@constants';
import { antFormType } from '../../../propTypes';
import LanguageFormItem from '@components/LanguageFormItem';

function DashboardUserWizard({
  dashboardUser,
  form,
  isEdit,
  loading,
  submitting,
  submitButtonText,
  onSubmit,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, hospitals] = useSelector(selectHospitalsInAlphabeticalOrder);
  const hospital = useSelector(selectDashboardUserHospital);

  const {
    [Permissions.AdministerHospital]: canAdministerHospital,
    [Permissions.AdministerAllHospitals]: canAdministerAllHospitals,
  } = useHasPermissions(Permissions.AdministerHospital, Permissions.AdministerAllHospitals);

  useEffect(() => {
    if (canAdministerAllHospitals) dispatch(fetchHospitals());
    if (canAdministerHospital) dispatch(fetchHospital());
  }, [canAdministerAllHospitals, canAdministerHospital, dispatch]);

  const handleSubmitClick = useCallback(
    event => {
      event.preventDefault();
      form.validateFields((err, values) => {
        if (err) return;
        if (!values.hospitalSlug) {
          values.hospitalSlug = hospital.hospitalSlug;
        }

        onSubmit(values);
      });
    },
    [form, hospital, onSubmit],
  );

  const permissions = useHasPermissions();
  const inviteTypes = {
    [SUPER_ADMIN]: Permissions.InviteSuperAdmin,
    [SUPPORT_ADMIN]: Permissions.InviteSupportAdmin,
    [SYSTEM_ADMIN]: Permissions.InviteSystemAdmin,
    [HOSPITAL_ADMIN]: Permissions.InviteHospitalAdmin,
    [HOSPITAL_USER]: Permissions.InviteHospitalUser,
  };
  const availableInvites = Object.entries(inviteTypes).reduce(
    (invites, [key, perm]) => (permissions[perm] ? [...invites, key] : invites),
    [],
  );

  return (
    <Card className="edit-dashboard-user-card" loading={loading}>
      <DetailForm formProps={{ hideRequiredMark: true, onSubmit: handleSubmitClick }}>
        <h3>{t('dashboardUsers:Wizard.userType')}</h3>
        <Form.Item className="full-width-form-item">
          {form.getFieldDecorator('userType', {
            initialValue: dashboardUser.userType,
            rules: [
              {
                required: true,
                message: t('dashboardUsers:Wizard.errors.userType'),
              },
            ],
          })(
            <Select
              disabled={submitting || isEdit}
              dropdownClassName="select-dashboard-user-type"
              placeholder={t('dashboardUsers:Wizard.placeholders.userType')}
            >
              {availableInvites.map(userType => {
                const camelCaseUserType = userType.replace(/([-][a-z])/g, group =>
                  group.toUpperCase().replace('-', ''),
                );
                return (
                  <Select.Option key={userType} value={userType}>
                    <Icon component={selectDashboardUserIcon(userType)} />
                    {t(`common:userTypes.${camelCaseUserType}`)}
                  </Select.Option>
                );
              })}
            </Select>,
          )}
        </Form.Item>
        {HOSPITAL_USER_TYPES.includes(form.getFieldValue('userType')) &&
        permissions[Permissions.AdministerAllHospitals] ? (
          <Form.Item label={t('common:source.hospital')}>
            {form.getFieldDecorator('hospitalSlug', {
              initialValue: dashboardUser.hospitalId,
              rules: [
                {
                  required: true,
                  message: t('dashboardUsers:Wizard.errors.hospital'),
                },
              ],
            })(
              <Select disabled={submitting || isEdit}>
                {hospitals.map(hospital => (
                  <Select.Option key={hospital.slug} value={hospital.slug}>
                    {hospital.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        ) : null}
        <p>
          {form.getFieldValue('userType')
            ? t(`dashboardUsers:Wizard.explanation.${form.getFieldValue('userType')}`)
            : ''}
        </p>

        <h3>{t('dashboardUsers:Wizard.userDetails')}</h3>
        <Form.Item label={t('common:firstName')}>
          {form.getFieldDecorator('firstName', {
            initialValue: dashboardUser.firstName || '',
            rules: [
              {
                required: true,
                message: t('dashboardUsers:Wizard.errors.firstName'),
              },
            ],
          })(
            <Input
              disabled={submitting}
              placeholder={t('dashboardUsers:Wizard.placeholders.firstName')}
            />,
          )}
        </Form.Item>
        <Form.Item label={t('common:lastName')}>
          {form.getFieldDecorator('lastName', {
            initialValue: dashboardUser.lastName || '',
            rules: [
              {
                required: true,
                message: t('dashboardUsers:Wizard.errors.lastName'),
              },
            ],
          })(
            <Input
              disabled={submitting}
              placeholder={t('dashboardUsers:Wizard.placeholders.lastName')}
            />,
          )}
        </Form.Item>
        <Form.Item label={t('common:email')}>
          {form.getFieldDecorator('email', {
            initialValue: dashboardUser.email || '',
            rules: [
              {
                type: 'email',
                required: true,
                message: t('dashboardUsers:Wizard.errors.email'),
              },
            ],
          })(
            <Input
              disabled={isEdit || submitting}
              placeholder={t('dashboardUsers:Wizard.placeholders.email')}
            />,
          )}
        </Form.Item>
        {isEdit && (
          <Form.Item label={t('common:phone')}>
            {form.getFieldDecorator('phoneNumber', {
              initialValue: dashboardUser.phoneNumber || '',
              rules: [
                {
                  pattern: /\+[\d\s]+/,
                  message: t('common:Wizard.details.phoneNumber.invalid'),
                },
              ],
            })(
              <Input
                disabled={submitting}
                placeholder={t('dashboardUsers:Wizard.placeholders.phoneNumber')}
              />,
            )}
          </Form.Item>
        )}
        <LanguageFormItem currentLanguage={dashboardUser.language} form={form} />
        {isEdit ? null : (
          <p className="email-explainer-text">{t('dashboardUsers:Wizard.explanation.email')}</p>
        )}
        <Button loading={submitting} htmlType="submit" type="primary">
          {submitButtonText}
        </Button>
      </DetailForm>
    </Card>
  );
}

DashboardUserWizard.propTypes = {
  dashboardUser: shape({
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    userType: oneOf(DASHBOARD_USER_TYPES),
  }),
  form: antFormType.isRequired,
  isEdit: bool,
  loading: bool,
  submitting: bool.isRequired,
  submitButtonText: string.isRequired,
  onSubmit: func.isRequired,
};

DashboardUserWizard.defaultProps = {
  dashboardUser: {},
  isEdit: false,
  loading: false,
};

export default Form.create()(DashboardUserWizard);
