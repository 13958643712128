import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquare, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import LanguageIcon from '@components/LanguageIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import IconButton from '@components/IconButton';
import SelectPathwayModal from '@components/SelectPathwayModal';
import UCIDInput from '@components/UCIDInput';
import { languageFromMetadata } from '@utils/contentMetadata';
import './style.less';

function Pathways({ pathways, pathwayTemplates, addPathways, removePathway, updatePathway }) {
  const history = useHistory();
  const [isModalVisible, setModalVisibility] = useState(false);
  const findLanguage = useSelector(languageFromMetadata);
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        className: 'language-column no-filters',
        title: t('cards:JourneyDetails.columns.language'),
        dataIndex: 'metadata',
        key: 'language',
        render: metadata => <LanguageIcon language={findLanguage({ metadata })} />,
        onFilter: (value, record) => {
          return (findLanguage(record) || '').toLowerCase() === value;
        },
        fixed: 'left',
      },
      {
        title: t('cards:JourneyDetails.columns.title'),
        dataIndex: 'name',
        key: 'title',
        render: (pathwayName, record) =>
          record.isAppUserPathwayActive || record.isNewlyAdded
            ? pathwayName
            : t('pathways:archived', { pathwayName }),
      },
      {
        className: 'published-column no-filters',
        title: t('cards:JourneyDetails.columns.published'),
        dataIndex: 'isActive',
        key: 'isActive',
        render: isActive => <PublishedStatusIcon published={isActive} />,
      },
      {
        className: 'pathway-details-column',
        title: t('cards:JourneyDetails.columns.details'),
        render: record => (
          <IconButton
            icon={faExternalLinkSquare}
            tooltip={t('common:buttons.open')}
            onClick={() => history.push(`/procedure/pathways/${record.id}`)}
          />
        ),
      },
      {
        className: 'pathway-ucid-column',
        title: 'UCID',
        key: 'ucid',
        render: record => (
          <UCIDInput
            record={record}
            callback={externalId => {
              updatePathway(record.appUserPathwayId, { externalId });
            }}
          />
        ),
      },
      {
        title: t('cards:JourneyDetails.columns.action'),
        render: record => {
          if (record.isNewlyAdded) {
            return (
              <Button
                type="danger"
                onClick={() =>
                  Modal.confirm({
                    title: t('cards:JourneyDetails.edit.confirm.delete'),
                    onOk: () => removePathway(record.appUserPathwayId),
                  })
                }
              >
                <FontAwesomeIcon icon={faTrashAlt} /> {t('common:buttons.delete')}
              </Button>
            );
          }

          if (record.isAppUserPathwayActive) {
            return (
              <Button
                ghost
                type="danger"
                onClick={() =>
                  Modal.confirm({
                    title: t('cards:JourneyDetails.edit.confirm.archive'),
                    onOk: () => updatePathway(record.appUserPathwayId, { isActive: false }),
                  })
                }
              >
                {t('common:buttons.archive')}
              </Button>
            );
          }
          return (
            <Button
              onClick={() =>
                Modal.confirm({
                  title: t('cards:JourneyDetails.edit.confirm.unarchive'),
                  onOk: () => updatePathway(record.appUserPathwayId, { isActive: true }),
                })
              }
            >
              {t('common:buttons.unarchive')}
            </Button>
          );
        },
      },
    ],
    [history, t, removePathway, updatePathway, findLanguage],
  );

  const handleAddPathways = useCallback(
    selectedIds => {
      addPathways(selectedIds);
      setModalVisibility(false);
    },
    [addPathways],
  );

  const selectedPathwayIds = useMemo(() => pathways.map(({ id }) => id), [pathways]);

  const pathwayModalDatasource = useMemo(() => {
    const selectedPathwayIds = pathways.map(({ id }) => id);
    return pathwayTemplates.filter(path => !selectedPathwayIds.includes(path.id));
  }, [pathwayTemplates, pathways]);

  return (
    <>
      <div className="pathway-table-header">
        <h4>{t('cards:JourneyDetails.pathways')}</h4>
        <Button icon="plus" type="dashed" onClick={() => setModalVisibility(true)}>
          {t('cards:JourneyDetails.edit.add')}
        </Button>
      </div>
      <Table columns={columns} dataSource={pathways} pagination={false} rowKey="appUserPathwayId" />
      <SelectPathwayModal
        pathways={pathwayModalDatasource}
        selectedRowKeys={selectedPathwayIds}
        visible={isModalVisible}
        onSubmit={handleAddPathways}
        onCancel={() => setModalVisibility(false)}
        clearOnClose
      />
    </>
  );
}

export default Pathways;
