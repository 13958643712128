import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Input } from 'antd';
import useWizard from '@components/Wizard/useWizard';
import LanguageFormItem from '@components/LanguageFormItem';
import { Card, ColumnGroup, Column } from '@cards/Card';
import { Permissions } from '@authorisation/constants';
import { useHasPermissions } from '@authorisation/hooks';

function Details({ children }) {
  const { i18n, t } = useTranslation();
  const { currentStepData, form, formData, setFormData } = useWizard();

  const { [Permissions.CreateManagedPathway]: canCreateManagedPathway } = useHasPermissions();

  useEffect(() => {
    if (formData.stagedRules?.stages?.length) {
      const fixedT = i18n.getFixedT(form.getFieldValue('language'));

      setFormData({
        ...formData,
        stagedRules: {
          stages: formData.stagedRules.stages.map(stage =>
            stage.isAdhoc
              ? {
                  ...stage,
                  name: fixedT('pathways:ProcedurePathways.wizard.stagedRules.notAligned'),
                }
              : stage,
          ),
        },
      });
    }
  }, [form.getFieldValue('language'), i18n]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ColumnGroup>
      <Column>
        <Card.Half>
          <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
            <fieldset>
              <legend>{t('common:Wizard.details.header')}</legend>
              <Form.Item label={t('common:Wizard.details.title.label')}>
                {form.getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: t('common:Wizard.details.title.required'),
                    },
                    {
                      max: 60,
                      message: t('common:Wizard.details.title.max60'),
                    },
                    {
                      pattern: /[A-Za-z]+/,
                      message: t('common:Wizard.details.title.pattern'),
                    },
                  ],
                  initialValue: currentStepData.name || '',
                })(<Input placeholder={t('common:Wizard.details.title.placeholder')} />)}
              </Form.Item>
              <Form.Item label={t('common:Wizard.details.description.label')}>
                {form.getFieldDecorator('description', {
                  initialValue: currentStepData.description || '',
                  rules: [
                    {
                      max: 200,
                      message: t('common:Wizard.details.description.max200'),
                    },
                  ],
                })(
                  <Input.TextArea
                    autoSize
                    placeholder={t('common:Wizard.details.description.placeholder')}
                  />,
                )}
              </Form.Item>
              <LanguageFormItem currentLanguage={currentStepData.language} form={form} />
              {canCreateManagedPathway && (
                <Form.Item
                  label={t('pathways:ProcedurePathways.wizard.details.managedPathway.label')}
                >
                  {form.getFieldDecorator('isManagedPathway', {
                    initialValue: currentStepData.isManagedPathway || false,
                    valuePropName: 'checked',
                    rules: [{ required: false }],
                  })(<Checkbox />)}
                </Form.Item>
              )}
              {children}
            </fieldset>
          </Form>
        </Card.Half>
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
}

export default Details;
