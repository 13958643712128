import {
  CREATE_RULE,
  CREATE_RULE_FAILURE,
  DELETE_RULE,
  DELETE_RULES,
  DELETE_RULES_SUCCESS,
  DUPLICATE_RULE,
  DUPLICATE_RULES,
  DUPLICATE_RULES_SUCCESS,
  EDIT_RULE,
  EDIT_RULE_SUCCESS,
  FETCH_RULES,
  FETCH_RULES_ERROR,
  LOAD_RULES,
  FETCHING_RULES,
  RULE_CREATED,
  IRule,
  FETCH_RULES_WHAT_DETAIL,
  FETCH_RULES_WHAT_DETAIL_SUCCESS,
  FETCH_PATHWAY_RULES_WHAT_DETAIL,
  FETCH_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS,
  FETCH_SHARED_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS,
  IFetchRuleWhatDetailOptions,
} from './types';
import { IReduxAction } from '../../../redux/types';

export interface ICreateRule extends IReduxAction<typeof CREATE_RULE, { rule: IRule }> {}
export const createRule = (rule: IRule) => ({
  type: CREATE_RULE,
  payload: { rule },
});

export interface ICreateRuleFailure extends IReduxAction<typeof CREATE_RULE_FAILURE> {}
export const createRuleFailure = () => ({
  type: CREATE_RULE_FAILURE,
  payload: {},
});

export interface IDuplicateRules
  extends IReduxAction<typeof DUPLICATE_RULES, { rulesToCopy: IRule[] }> {}
export const duplicateRules = (rulesToCopy: IRule[]) => ({
  type: DUPLICATE_RULES,
  payload: { rulesToCopy },
});

export interface IDuplicateRule
  extends IReduxAction<typeof DUPLICATE_RULE, { ruleToCopy: IRule }> {}
export const duplicateRule = (ruleToCopy: IRule) => ({
  type: DUPLICATE_RULE,
  payload: { ruleToCopy },
});

export interface IDuplicateRulesSuccess
  extends IReduxAction<typeof DUPLICATE_RULES_SUCCESS, { rules: IRule[] }> {}
export const duplicateRulesSuccess = (rules: IRule[]) => ({
  type: DUPLICATE_RULES_SUCCESS,
  payload: { rules },
});

export interface IDeleteRules extends IReduxAction<typeof DELETE_RULES, { ruleIds: number[] }> {}
export const deleteRules = (ruleIds: number[]) => ({
  type: DELETE_RULES,
  payload: { ruleIds },
});

export interface IDeleteRule extends IReduxAction<typeof DELETE_RULE, { ruleId: number }> {}
export const deleteRule = (ruleId: number) => ({
  type: DELETE_RULE,
  payload: { ruleId },
});

export interface IDeleteRulesSuccess
  extends IReduxAction<typeof DELETE_RULES_SUCCESS, { ruleIds: number[] }> {}
export const deleteRulesSuccess = (ruleIds: number[]) => ({
  type: DELETE_RULES_SUCCESS,
  payload: { ruleIds },
});

export interface IFetchRules extends IReduxAction<typeof FETCH_RULES> {}
export const fetchRules = () => ({
  type: FETCH_RULES,
  payload: {},
});

export interface IFetchingRules extends IReduxAction<typeof FETCHING_RULES> {}
export const fetchingRules = () => ({
  type: FETCHING_RULES,
  payload: {},
});

export interface IFetchRulesError
  extends IReduxAction<typeof FETCH_RULES_ERROR, { error: string }> {}
export const fetchRulesError = (error: string) => ({
  type: FETCH_RULES_ERROR,
  payload: { error },
});

export interface ILoadRules extends IReduxAction<typeof LOAD_RULES, { rules: IRule[] }> {}
export const loadRules = (rules: IRule[]) => ({
  type: LOAD_RULES,
  payload: { rules },
});

export interface IRuleCreated extends IReduxAction<typeof RULE_CREATED, { rule: IRule }> {}
export const ruleCreated = (rule: IRule) => ({
  type: RULE_CREATED,
  payload: { rule },
});

export interface IEditRule
  extends IReduxAction<typeof EDIT_RULE, { id: number; editedRule: IRule }> {}
export const editRule = (id: number, editedRule: IRule) => ({
  type: EDIT_RULE,
  payload: { id, editedRule },
});

export interface IEditRuleSuccess
  extends IReduxAction<typeof EDIT_RULE_SUCCESS, { editedRule: IRule }> {}
export const editRuleSuccess = (editedRule: IRule) => ({
  type: EDIT_RULE_SUCCESS,
  payload: { editedRule },
});

export type EditRuleFailed = ReturnType<typeof editRuleFailed>;
export const editRuleFailed = () => ({
  type: 'rules/editFailed' as const,
  payload: {},
});

export interface IFetchRulesWhatDetail
  extends IReduxAction<
    typeof FETCH_RULES_WHAT_DETAIL,
    { options: IFetchRuleWhatDetailOptions[] }
  > {}
export const fetchRulesWhatDetail = (options: IFetchRuleWhatDetailOptions[]) => ({
  type: FETCH_RULES_WHAT_DETAIL,
  payload: { options },
});

export interface IFetchRulesWhatDetailSuccess
  extends IReduxAction<
    typeof FETCH_RULES_WHAT_DETAIL_SUCCESS,
    { rules: { [key: number]: { [key: string]: any } } }
  > {}
export const fetchRulesWhatDetailSuccess = (rules: { [key: number]: { [key: string]: any } }) => ({
  type: FETCH_RULES_WHAT_DETAIL_SUCCESS,
  payload: { rules },
});

export interface IFetchPathwayRulesWhatDetail
  extends IReduxAction<
    typeof FETCH_PATHWAY_RULES_WHAT_DETAIL,
    { pathwayId: number; isSharedPathway?: boolean }
  > {}
export const fetchPathwayRulesWhatDetail = (pathwayId: number, isSharedPathway?: boolean) => ({
  type: FETCH_PATHWAY_RULES_WHAT_DETAIL,
  payload: { pathwayId, isSharedPathway },
});

export interface IFetchPathwayEngagementCheckActionsWhatDetailSuccess
  extends IReduxAction<
    typeof FETCH_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS,
    { pathwayId: number; details: { [key: number]: { [key: string]: any } } }
  > {}
export const fetchPathwayEngagementCheckActionsWhatDetailSuccess = (
  pathwayId: number,
  details: { [key: number]: { [key: string]: any } },
) => ({
  type: FETCH_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS,
  payload: { pathwayId, details },
});

export interface IFetchSharedPathwayEngagementCheckActionsWhatDetailSuccess
  extends IReduxAction<
    typeof FETCH_SHARED_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS,
    { sharedPathwayId: number; details: { [key: number]: { [key: string]: any } } }
  > {}
export const fetchSharedPathwayEngagementCheckActionsWhatDetailSuccess = (
  sharedPathwayId: number,
  details: { [key: number]: { [key: string]: any } },
) => ({
  type: FETCH_SHARED_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS,
  payload: { sharedPathwayId, details },
});
