import OrganisationDetailsCache from '@utils/organisationDetailsCache';
import { getContext, put, takeEvery } from 'redux-saga/effects';
import {
  fetchDirectoryOrganisationFailed,
  fetchDirectoryOrganisationSuccess,
  IFetchDirectoryOrganisation,
} from './actions';

export default function*() {
  yield takeEvery('directory/fetch-organisation', doFetchDirectoryOrganisation);
}

function* doFetchDirectoryOrganisation({ payload: { slug } }: IFetchDirectoryOrganisation): any {
  const orgCache: OrganisationDetailsCache = yield getContext('organisationDetailsCache');

  try {
    const orgDetails = yield orgCache.get(slug);
    yield put(fetchDirectoryOrganisationSuccess(slug, orgDetails));
  } catch {
    yield put(fetchDirectoryOrganisationFailed(slug));
  }
}
