import { combineReducers } from 'redux';
import { Organisation } from '@liquid-state/directory-client';
import {
  IFetchDirectoryOrganisation,
  IFetchDirectoryOrganisationFailed,
  IFetchDirectoryOrganisationSuccess,
} from './actions';
import { IState } from '@redux/reducer';

const loading = (
  state: { [slug: string]: boolean } = {},
  action:
    | IFetchDirectoryOrganisation
    | IFetchDirectoryOrganisationSuccess
    | IFetchDirectoryOrganisationFailed,
) => {
  switch (action.type) {
    case 'directory/fetch-organisation':
      const current = state[action.payload.slug];
      if (current === undefined) {
        return {
          ...state,
          [action.payload.slug]: true,
        };
      }
      return state;
    case 'directory/fetch-organisation-success':
    case 'directory/fetch-organisation-failed':
      return {
        ...state,
        [action.payload.slug]: false,
      };
    default:
      return state;
  }
};

const byId = (
  state: { [slug: string]: Organisation } = {},
  action: IFetchDirectoryOrganisationSuccess,
) => {
  switch (action.type) {
    case 'directory/fetch-organisation-success':
      return {
        ...state,
        [action.payload.slug]: action.payload.organisation,
      };
    default:
      return state;
  }
};

export default combineReducers({ loading, byId });

export const selectDirectoryOrganisation = (slug: string) => (state: IState) => {
  return state.directoryOrganisations.byId[slug];
};

export const selectDirectoryOrganisationWithLoading = (slug: string) => (
  state: IState,
): [boolean?, Organisation?] => {
  return [state.directoryOrganisations.loading[slug], state.directoryOrganisations.byId[slug]];
};
