type ValidationError = {
  type: string;
  message: string;
  field?: string;
};

interface SchemaProperty {
  type: string;
  title: string;
  format?: string;
  items?: object;
  minimum?: number;
  maximum?: number;
  minLength?: number;
  maxLength?: number;
  enum?: (string | number)[];
  enumNames?: string[];
  default?: boolean | string | number | (string | number)[];
  uniqueItems?: boolean;
  properties?: { [key: string]: SchemaProperty };
  required?: string[];
}

const SUPPORTED_ELEMENT_TYPES = ['string', 'number', 'boolean', 'array', 'object', 'null'];
const SUPPORTED_UI_WIDGETS = ['input', 'textarea', 'radio', 'checkboxes', 'select', 'range'];
const SUPPORTED_FORMATS = ['date'];

export function validateFormSchema(schema: any): ValidationError[] {
  const errors: ValidationError[] = [];

  if (!schema || typeof schema !== 'object') {
    errors.push({
      type: 'invalid-schema',
      message: 'formWizard:validator.invalidSchema',
    });
    return errors;
  }

  if (!schema.schema || typeof schema.schema !== 'object') {
    errors.push({
      type: 'missing-schema',
      message: 'formWizard:validator.missingSchema',
      field: 'schema',
    });
  } else {
    if (schema.schema.type !== 'object') {
      errors.push({
        type: 'invalid-root-type',
        message: 'formWizard:validator.rootTypeMustBeObject',
        field: 'schema.type',
      });
    }
    if (!schema.schema.properties || typeof schema.schema.properties !== 'object') {
      errors.push({
        type: 'missing-properties',
        message: 'formWizard:validator.missingProperties',
        field: 'schema.properties',
      });
    }

    if (schema.schema.description) {
      errors.push({
        type: 'deprecated-schema-description',
        message: 'formWizard:validator.deprecatedSchemaDescription',
        field: 'schema.description',
      });
    }
  }

  if (!schema.uiSchema || typeof schema.uiSchema !== 'object') {
    errors.push({
      type: 'missing-ui-schema',
      message: 'formWizard:validator.missingUiSchema',
      field: 'uiSchema',
    });
  } else {
    if (!Object.prototype.hasOwnProperty.call(schema.uiSchema, 'ui:order')) {
      errors.push({
        type: 'missing-ui-order',
        message: 'formWizard:validator.missingUiOrder',
        field: 'uiSchema.ui:order',
      });
    } else if (!Array.isArray(schema.uiSchema['ui:order'])) {
      errors.push({
        type: 'invalid-ui-order',
        message: 'formWizard:validator.uiOrderMustBeArray',
        field: 'uiSchema.ui:order',
      });
    } else if (schema.schema && schema.schema.properties) {
      const topLevelProps = Object.keys(schema.schema.properties);
      const missingProps = topLevelProps.filter(
        prop => !schema.uiSchema['ui:order'].includes(prop),
      );

      if (missingProps.length > 0) {
        errors.push({
          type: 'incomplete-ui-order',
          message: 'formWizard:validator.incompleteUiOrder',
          field: 'uiSchema.ui:order',
        });
      }
    }
  }

  if (schema.schema && schema.schema.properties && typeof schema.schema.properties === 'object') {
    validateProperties(schema.schema.properties, schema.uiSchema, errors);
  }

  if (schema.schema && schema.schema.required) {
    if (!Array.isArray(schema.schema.required)) {
      errors.push({
        type: 'invalid-required',
        message: 'formWizard:validator.invalidRequiredArray',
        field: 'schema.required',
      });
    } else if (schema.schema.properties) {
      schema.schema.required.forEach((reqField: string) => {
        if (!schema.schema.properties[reqField]) {
          errors.push({
            type: 'required-field-not-in-properties',
            message: 'formWizard:validator.requiredFieldNotInProperties',
            field: `schema.required.${reqField}`,
          });
        }
      });
    }
  }

  return errors;
}

function validateProperties(
  properties: { [key: string]: any },
  uiSchema: any,
  errors: ValidationError[],
  path: string = 'schema.properties',
) {
  Object.entries(properties).forEach(([propertyKey, propertyValue]) => {
    const currentPath = `${path}.${propertyKey}`;

    if (!propertyValue || typeof propertyValue !== 'object') {
      errors.push({
        type: 'invalid-property',
        message: 'formWizard:validator.invalidProperty',
        field: currentPath,
      });
      return;
    }

    const property = propertyValue as SchemaProperty;

    if (typeof property.title !== 'string') {
      errors.push({
        type: 'missing-property-title',
        message: 'formWizard:validator.missingPropertyTitle',
        field: `${currentPath}.title`,
      });
    }

    if (!SUPPORTED_ELEMENT_TYPES.includes(property.type)) {
      errors.push({
        type: 'unsupported-type',
        message: 'formWizard:validator.unsupportedType',
        field: `${currentPath}.type`,
      });
    }

    if (property.type === 'object') {
      const sectionUiSchema = uiSchema?.[propertyKey];

      if (!sectionUiSchema || typeof sectionUiSchema !== 'object') {
        errors.push({
          type: 'missing-section-ui-schema',
          message: 'formWizard:validator.missingSectionUiSchema',
          field: `uiSchema.${propertyKey}`,
        });
      } else if (property.properties && Object.keys(property.properties).length > 0) {
        if (!sectionUiSchema['ui:order']) {
          errors.push({
            type: 'missing-section-ui-order',
            message: 'formWizard:validator.missingSectionUiOrder',
            field: `uiSchema.${propertyKey}.ui:order`,
          });
        } else if (!Array.isArray(sectionUiSchema['ui:order'])) {
          errors.push({
            type: 'invalid-section-ui-order',
            message: 'formWizard:validator.invalidSectionUiOrder',
            field: `uiSchema.${propertyKey}.ui:order`,
          });
        } else {
          const sectionProps = Object.keys(property.properties);
          const missingProps = sectionProps.filter(
            prop => !sectionUiSchema['ui:order'].includes(prop),
          );

          if (missingProps.length > 0) {
            errors.push({
              type: 'incomplete-section-ui-order',
              message: 'formWizard:validator.incompleteSectionUiOrder',
              field: `uiSchema.${propertyKey}.ui:order`,
            });
          }
        }
      }

      if (property.required) {
        if (!Array.isArray(property.required)) {
          errors.push({
            type: 'invalid-section-required',
            message: 'formWizard:validator.invalidSectionRequiredArray',
            field: `${currentPath}.required`,
          });
        } else if (property.properties) {
          property.required.forEach((reqField: string) => {
            if (!property.properties?.[reqField]) {
              errors.push({
                type: 'section-required-field-not-in-properties',
                message: 'formWizard:validator.sectionRequiredFieldNotInProperties',
                field: `${currentPath}.required.${reqField}`,
              });
            }
          });
        } else {
          errors.push({
            type: 'section-required-without-properties',
            message: 'formWizard:validator.sectionRequiredWithoutProperties',
            field: `${currentPath}.required`,
          });
        }
      }

      if (property.properties && typeof property.properties === 'object') {
        validateProperties(
          property.properties,
          sectionUiSchema,
          errors,
          currentPath + '.properties',
        );
      }
    } else {
      validateFieldProperty(property, uiSchema, propertyKey, currentPath, errors);
    }
  });
}

function validateFieldProperty(
  property: SchemaProperty,
  uiSchema: any,
  propertyKey: string,
  propertyPath: string,
  errors: ValidationError[],
) {
  if (property.format) {
    if (!SUPPORTED_FORMATS.includes(property.format)) {
      errors.push({
        type: 'unsupported-format',
        message: 'formWizard:validator.unsupportedFormat',
        field: `${propertyPath}.format`,
      });
    }
    if (property.format === 'date' && property.type !== 'string') {
      errors.push({
        type: 'date-type-mismatch',
        message: 'formWizard:validator.dateTypeMismatch',
        field: propertyPath,
      });
    }
  }

  if (property.type === 'array') {
    if (!property.items || typeof property.items !== 'object') {
      errors.push({
        type: 'missing-items-definition',
        message: 'formWizard:validator.missingItemsDefinition',
        field: `${propertyPath}.items`,
      });
    }
  }

  if (
    Object.prototype.hasOwnProperty.call(property, 'minimum') ||
    Object.prototype.hasOwnProperty.call(property, 'maximum')
  ) {
    if (typeof property.minimum !== 'number' && typeof property.maximum !== 'number') {
      errors.push({
        type: 'slider-missing-minmax',
        message: 'formWizard:validator.sliderMissingMinMax',
        field: propertyPath,
      });
    }
    if (property.type !== 'number') {
      errors.push({
        type: 'slider-type-mismatch',
        message: 'formWizard:validator.sliderTypeMismatch',
        field: `${propertyPath}.type`,
      });
    }
  }

  if (property.minLength !== undefined && typeof property.minLength !== 'number') {
    errors.push({
      type: 'invalid-min-length',
      message: 'formWizard:validator.invalidMinLength',
      field: `${propertyPath}.minLength`,
    });
  }

  if (property.maxLength !== undefined && typeof property.maxLength !== 'number') {
    errors.push({
      type: 'invalid-max-length',
      message: 'formWizard:validator.invalidMaxLength',
      field: `${propertyPath}.maxLength`,
    });
  }

  const fieldUiSchema = uiSchema?.[propertyKey];

  if (fieldUiSchema) {
    if (typeof fieldUiSchema !== 'object' || Array.isArray(fieldUiSchema)) {
      errors.push({
        type: 'invalid-field-ui-schema',
        message: 'formWizard:validator.invalidFieldUiSchema',
        field: `uiSchema.${propertyKey}`,
      });
    } else {
      if (
        fieldUiSchema['ui:widget'] &&
        !SUPPORTED_UI_WIDGETS.includes(fieldUiSchema['ui:widget'])
      ) {
        errors.push({
          type: 'unsupported-ui-widget',
          message: 'formWizard:validator.unsupportedUiWidget',
          field: `uiSchema.${propertyKey}.ui:widget`,
        });
      }

      if (fieldUiSchema['ui:widget'] === 'range') {
        if (typeof property.minimum !== 'number' || typeof property.maximum !== 'number') {
          errors.push({
            type: 'range-missing-minmax',
            message: 'formWizard:validator.rangeMissingMinMax',
            field: propertyPath,
          });
        }
      }

      if (fieldUiSchema['ui:widget'] === 'checkboxes' && property.type !== 'array') {
        errors.push({
          type: 'checkboxes-type-mismatch',
          message: 'formWizard:validator.checkboxesTypeMismatch',
          field: `${propertyPath}.type`,
        });
      }
    }
  }
}
