import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { faCopy, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '@components/IconButton';
import EditButton from '@components/EditButton';
import { selectRule } from '@pathways/redux/rules/reducers';
import { Column } from '@cards/Card';
import { usePageActions } from '@hooks';
import { waitForStoreUpdate } from '@utils';
import { selectPathway } from '@pathways/redux/pathways/reducers';
import { selectSharedPathway } from '@pathways/redux/sharedPathways/reducers';
import {
  deleteRule,
  duplicateRule,
  fetchRules,
  fetchRulesWhatDetail,
} from '@pathways/redux/rules/actions';
import { fetchSharedPathwaySnapshots } from '@pathways/redux/sharedPathways/actions';
import ProcedureRuleDetails from '@pathways/cards/Pathways/RuleDetails';
import confirmModal from '@pathways/cards/Pathways/PathwayList/confirmModal';
import confirmDuplicateModal from '@pathways/cards/Pathways/RuleList/confirmDuplicateModal';
import { matchType } from '../../../propTypes';

function RuleDetails({ history, match }) {
  const dispatch = useDispatch();
  const store = useStore();
  const { t } = useTranslation();
  const [, stateRule] = useSelector(selectRule(match.params.id));
  const [rule, setRule] = useState(stateRule);

  const [, pathway] = useSelector(selectPathway(match.params.pathwayId));
  const sharedPathway = useSelector(selectSharedPathway(Number(match.params.pathwayId)));

  useEffect(() => {
    const sharedPathwayStagesRules = sharedPathway?.stages
      ? sharedPathway.stages.flatMap(stage => stage.rules)
      : [];
    const sharedPathwayIndexEventsRules = sharedPathway?.indexEvents
      ? sharedPathway.indexEvents.flatMap(ie => ie.rules)
      : [];
    const sharedPathwayRules = [...sharedPathwayStagesRules, ...sharedPathwayIndexEventsRules];
    const matchingSharedPathwayRule = sharedPathwayRules.find(
      rule => rule.id === Number(match.params.id),
    );

    const pathwayStagesRules = pathway?.stages ? pathway.stages.flatMap(stage => stage.rules) : [];
    const pathwayIndexEventsRules = pathway?.indexEvents
      ? pathway.indexEvents.flatMap(ie => ie.rules)
      : [];
    const pathwayRules = [...pathwayStagesRules, ...pathwayIndexEventsRules];
    const matchingPathwayRule = pathwayRules.find(rule => rule.id === Number(match.params.id));

    setRule(stateRule || matchingSharedPathwayRule || matchingPathwayRule);
  }, [stateRule, sharedPathway, pathway, match.params.id]);

  const { setActions, setTitle } = usePageActions({
    actions: [],
    showBack: true,
  });

  const copyRule = useCallback(() => {
    dispatch(duplicateRule(rule));

    return waitForStoreUpdate(store, selectRule(rule.id));
  }, [dispatch, rule, store]);

  const onDeleteRule = useCallback(() => {
    dispatch(deleteRule(rule.id));
    return waitForStoreUpdate(store, selectRule(rule.id));
  }, [dispatch, rule, store]);

  useEffect(() => {
    if (!sharedPathway) {
      dispatch(fetchSharedPathwaySnapshots());
    } else {
      if (!rule) {
        dispatch(fetchRules());
      }
    }
  }, [dispatch, match, sharedPathway, rule]);

  useEffect(() => {
    if (rule) {
      setTitle(rule.name);
      if (stateRule) {
        setActions([
          <IconButton
            icon={faCopy}
            key="duplicate-rule"
            tooltip={t('common:buttons.copy')}
            onClick={() => confirmDuplicateModal(t, copyRule)}
          />,
          <IconButton
            icon={faTrashAlt}
            key="delete-rule"
            tooltip={t('common:buttons.delete')}
            disabled={rule?.isReadOnly}
            onClick={() =>
              confirmModal(t('cards:ProcedureRuleList.modals.confirmDelete'), onDeleteRule)
            }
          />,
          <EditButton
            key="edit-rule"
            disabled={rule?.isReadOnly}
            onClick={() =>
              history.push(`/procedure/rules/${rule.id}/edit/${match.params.pathwayId}`)
            }
          />,
        ]);
      }
    }
  }, [rule, t, copyRule, onDeleteRule, history, setActions, setTitle, match, stateRule]);

  useEffect(() => {
    if (rule?.id) {
      dispatch(fetchRulesWhatDetail([{ rule: rule, sharedPathwayId: sharedPathway?.id }]));
    }
  }, [rule?.id, dispatch, sharedPathway?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Column>
      <ProcedureRuleDetails ruleId={match.params.id} ruleData={rule} />
    </Column>
  );
}

RuleDetails.propTypes = {
  match: matchType.isRequired,
};

export default RuleDetails;
