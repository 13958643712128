import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchableTable from '@components/SearchableTable';
import createColumns from './createColumns';
import UseModal from './UseModal';

import './style.less';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

const SharedPathwaysListCard = ({ sharedPathways, allPathways, loading }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [usedPathways, setUsedPathways] = useState({});
  const [showUseModal, setShowUseModal] = useState(false);
  const [selectedSharedPathway, setSelectedSharedPathway] = useState(null);

  const handleUseSharedPathway = useCallback(
    id => {
      const selected = sharedPathways.find(sp => sp.id === id);
      if (usedPathways[selected.snapshotParent]?.length > 0) {
        setSelectedSharedPathway(selected);
        setShowUseModal(true);
        return;
      }
      history.push(`/procedure/shared-pathways/${id}/duplicate`);
    },
    [history, usedPathways, sharedPathways],
  );

  const onModalOk = ({ mode, selectedPathway }) => {
    let url = `/procedure/shared-pathways/${selectedSharedPathway.id}/duplicate`;
    if (mode === 'update') {
      url = `${url}?updatePathway=${selectedPathway.pathwayId}`;
    }
    history.push(url);
    setShowUseModal(false);
  };

  useEffect(() => {
    if (!allPathways.length) {
      return;
    }
    const computedUsedPathways = allPathways.reduce((acc, pathway, currentIndex) => {
      if (pathway.createdFromPathwayId) {
        const existingPathwaysMade = acc[pathway.createdFromPathwayId]
          ? acc[pathway.createdFromPathwayId]
          : [];
        return {
          ...acc,
          [pathway.createdFromPathwayId]: [
            ...existingPathwaysMade,
            {
              pathwayId: pathway.id,
              pathwayName: pathway.name,
              snapshotNumber: pathway.createdFromSnapshotNumber,
            },
          ],
        };
      }
      return acc;
    }, {});

    setUsedPathways(computedUsedPathways);
  }, [allPathways]);

  const columns = useMemo(() => createColumns(t, handleUseSharedPathway, usedPathways), [
    t,
    handleUseSharedPathway,
    usedPathways,
  ]);

  return (
    <>
      <SearchableTable
        columns={columns}
        dataSource={sharedPathways}
        filterFunc={filterFunc}
        loading={loading}
        onRowClick={row => history.push(`/procedure/shared-pathways/${row.id}`)}
      />
      <UseModal
        visible={showUseModal}
        onOk={onModalOk}
        onCancel={() => setShowUseModal(false)}
        sharedPathway={selectedSharedPathway}
        existingPathways={usedPathways[selectedSharedPathway?.snapshotParent] ?? []}
      />
    </>
  );
};

export default SharedPathwaysListCard;
