import { getContext, call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { PIPUser } from '@liquid-state/pip-client/dist/types';
import { message } from 'antd';
import moment from 'moment';
import createPipClient from '@api/pipClient';
import {
  pipAppUrl,
  appToken as primaryAppToken,
  organisationSlug as primaryOrgSlug,
} from 'settings';
import { GDPR_DOWNLOAD_REQUEST, APP_USER } from '@constants';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { selectAppDetails } from '@organisation/redux/selectors';
import doEnsurePipObjectType from '@redux/doEnsurePipObjectType';
import {
  LOAD_SUPPORT_REQUESTS,
  UPDATE_SUPPORT_REQUEST_ASSIGNEE,
  IRequest,
  IRawUserSupportRequests,
  RequestType,
  IRawRequest,
  UserType,
} from './types';
import {
  loadSupportRequestsSuccess,
  loadSupportRequestsFailed,
  ActionSupportRequest,
  submitGDPRRequestFailed,
  submitGDPRRequestSuccess,
  SubmitGDPRRequestForAppUser,
  SubmitGDPRRequestForCurrentUser,
  SubmitGDPRRequestForDashboardUser,
  actionSupportRequestSuccess,
  actionSupportRequestFailed,
  loadGDPRContacts,
  loadGDPRContactsFailed,
  SaveGDPRContact,
  saveGDPRContactSuccess,
  saveGDPRContactFailed,
  gdprContactsLoaded,
} from './actions';
import { selectUserRole } from '../../authorisation/selectors';
import {
  determineGDPRRequestAssigneeBasedOnUserRole,
  determineGDPRRequestStatusBasedOnUserRole,
  determinePIPKeyForGDPRContacts,
  extractAppUserIdFromUrl,
} from './utils';
import takeFirst from '../takeFirst';
import i18n from '../../i18n';

export default function* root() {
  yield takeLatest(LOAD_SUPPORT_REQUESTS, doLoadRequests);
  yield takeEvery(UPDATE_SUPPORT_REQUEST_ASSIGNEE, doActionSupportRequest);
  yield takeEvery('requests/GDPR-for-current-user', doCreateGDPRRequestForCurrentUser);
  yield takeEvery('requests/GDPR-for-app-user', doCreateGDPRRequestForAppUser);
  yield takeEvery('requests/GDPR-for-dashboard-user', doCreateGDPRRequestForDashboardUser);
  yield takeFirst('requests/fetch-gdpr-contacts', doFetchGDPRContacts);
  yield takeLatest('requests/save-GDPR-contact', doSaveGDPRContact);
}

function* doLoadRequests(): any {
  try {
    // const { appToken } = yield select(selectAppDetails);
    const { organisationSlug } = yield select(selectAppDetails);
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const response = yield call(
      pipClient.getObjectsForType,
      `${primaryAppToken}-support-requests`,
      'latest',
    );

    let filterFunc = (req: IRawRequest) => req.organisationId === organisationSlug;
    if (organisationSlug === primaryOrgSlug) {
      // we are in the primary Org, keep only requests assigned to us to be processed
      filterFunc = (req: IRawRequest) =>
        req.organisationId === primaryOrgSlug || req.assignee.type === 'super-admin';
    }
    const filteredResponse = response
      .reduce((acc: object[], userRequests: IRawUserSupportRequests): any[] => {
        return [
          ...acc,
          {
            ...userRequests,
            json: {
              requests: userRequests.json.requests.filter(filterFunc),
            },
          },
        ];
      }, [])
      .filter((userReqs: any) => userReqs.json.requests.length);

    const requestsWithUser = filteredResponse.reduce(
      (acc: object[], userRequests: IRawUserSupportRequests): any[] => {
        return [
          ...acc,
          ...userRequests.json.requests.map(request => ({
            ...request,
            userUuid: extractAppUserIdFromUrl(userRequests.app_user), // extract uuid from url
          })),
        ];
      },
      [],
    );

    const users = yield call(() =>
      Promise.all(
        requestsWithUser.map(async (req: any) => {
          const appUserResponse: any = await pipClient.getAppUserByUuid(req.userUuid);
          return appUserResponse;
        }),
      ),
    );

    const requests = requestsWithUser.reduce((acc: IRequest[], req: any): IRequest[] => {
      const user = users.find((user: any) => user.uuid === req.userUuid);
      if (user) {
        return [
          ...acc,
          {
            ...req,
            userId: user.app_user_id,
          },
        ];
      }

      return [...acc, req];
    }, []);

    yield put(loadSupportRequestsSuccess(requests));
  } catch (err) {
    console.error(err);
    yield put(loadSupportRequestsFailed());
  }
}

function* doActionSupportRequest({ payload: { request, action } }: ActionSupportRequest): any {
  try {
    const userRole = yield select(selectUserRole);
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const slug = `${primaryAppToken}-support-requests`;
    const existingRequests = yield call(
      pipClient.getObjectsForType,
      slug,
      'latest',
      request.userUuid,
    );

    const updatedValues = {
      assignee: determineGDPRRequestAssigneeBasedOnUserRole(userRole),
      status: determineGDPRRequestStatusBasedOnUserRole(userRole),
      history: [
        ...(request.history || []),
        {
          action,
          data: {
            previousAssignee: request.assignee,
            nextAssignee: determineGDPRRequestAssigneeBasedOnUserRole(userRole),
            timestamp: moment().toISOString(),
          },
        },
      ],
    };

    const updatedRequests: IRawRequest[] = existingRequests[0]?.json.requests.map(
      (req: IRawRequest): IRawRequest => {
        if (req.uuid === request.uuid) {
          return {
            ...req,
            ...updatedValues,
          };
        }

        return req;
      },
    );

    yield call(pipClient.createObject, slug, { requests: updatedRequests }, request.userUuid);

    const updatedRequest = {
      ...request,
      ...updatedValues,
    };

    if (action === 'transition')
      yield call(message.success, i18n.t('dataRequests:List.transitionSuccess'));
    if (action === 'completed')
      yield call(message.success, i18n.t('dataRequests:List.completedSuccess'));

    yield put(actionSupportRequestSuccess(updatedRequest));
  } catch (err) {
    console.error(err);
    yield put(actionSupportRequestFailed());

    if (action === 'transition')
      yield call(message.error, i18n.t('dataRequests:List.transitionFailed'));
    if (action === 'completed')
      yield call(message.error, i18n.t('dataRequests:List.completedFailed'));
  }
}

function* doCreateGDPRRequest(
  pipAppUser: PIPUser,
  type: RequestType,
  userRole: UserType,
  hospitalId?: string,
): any {
  if (!pipAppUser) throw new Error('User does not exist in PIP');
  const tokens = yield getContext('tokens');
  const pipClient = yield call(createPipClient, tokens);
  const slug = `${primaryAppToken}-support-requests`;

  yield call(doEnsurePipObjectType, pipClient, slug, primaryAppToken);

  const existingRequests = yield call(pipClient.getObjectsForType, slug, 'latest', pipAppUser.uuid);

  const updatedRequests: { requests: IRawRequest[] } = {
    requests: [
      ...(existingRequests[0]?.json.requests || []),
      {
        organisationId: hospitalId,
        organisationAppUserId: pipAppUser.app_user_id,
        assignee: determineGDPRRequestAssigneeBasedOnUserRole(userRole, hospitalId),
        created: new Date().toISOString(),
        description: '',
        history: [],
        status: 'created',
        title: type === GDPR_DOWNLOAD_REQUEST ? 'GDPR data download' : 'GDPR data deletion',
        type,
        userType: userRole,
        uuid: uuid(),
      },
    ],
  };

  yield call(pipClient.createObject, slug, updatedRequests, pipAppUser.uuid);

  return updatedRequests;
}

function* doCreateGDPRRequestForCurrentUser({
  payload: { type },
}: SubmitGDPRRequestForCurrentUser) {
  try {
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const pipTokenClaims = JSON.parse(atob(tokens.tokenMap.get('pip').split('.')[1]));
    const pipAppUserResponse = yield call(pipClient.getAppUser, pipTokenClaims.sub);
    const pipAppUser = pipAppUserResponse.results[0];

    const dashboardUser = yield select(selectCurrentDashboardUser);

    const updatedRequests = yield doCreateGDPRRequest(
      pipAppUser,
      type,
      dashboardUser.role,
      dashboardUser.profile.hospitalId,
    );

    const latestRequest = {
      ...updatedRequests.requests[updatedRequests.requests.length - 1],
      appUser: extractAppUserIdFromUrl(
        updatedRequests.requests[updatedRequests.requests.length - 1].appUser,
      ),
    };

    yield put(submitGDPRRequestSuccess(latestRequest));

    yield call(
      message.success,
      i18n.t(
        `myProfile:${type === GDPR_DOWNLOAD_REQUEST ? 'DataDownload' : 'DataDeletion'}.success`,
      ),
    );
  } catch (err) {
    console.error(err);
    yield put(submitGDPRRequestFailed());
    yield call(
      message.error,
      i18n.t(`myProfile:${type === GDPR_DOWNLOAD_REQUEST ? 'DataDownload' : 'DataDeletion'}.fail`),
    );
  }
}

function* doCreateGDPRRequestForAppUser({ payload: { type, user } }: SubmitGDPRRequestForAppUser) {
  try {
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const pipAppUserResponse = yield call(pipClient.getAppUser, user.ids.pip);
    const pipAppUser = pipAppUserResponse.results[0];

    const updatedRequests = yield doCreateGDPRRequest(pipAppUser, type, APP_USER, user.hospitalId);

    const latestRequest = {
      ...updatedRequests.requests[updatedRequests.requests.length - 1],
      appUser: extractAppUserIdFromUrl(
        updatedRequests.requests[updatedRequests.requests.length - 1].appUser,
      ),
    };

    yield put(submitGDPRRequestSuccess(latestRequest));
    const history = yield getContext('history');
    yield call(history.goBack);
    yield call(
      message.success,
      i18n.t(
        `dataRequests:${
          type === GDPR_DOWNLOAD_REQUEST ? 'CreateDataRequest' : 'CreateDeletionRequest'
        }.success`,
        { name: `${user.firstName} ${user.lastName}` },
      ),
    );
  } catch (err) {
    console.error(err);
    yield put(submitGDPRRequestFailed());

    if (err.message === 'User does not exist in PIP') {
      yield call(
        message.error,
        i18n.t(
          `dataRequests:${
            type === GDPR_DOWNLOAD_REQUEST ? 'CreateDataRequest' : 'CreateDeletionRequest'
          }.errors.userDoesntExist`,
        ),
        3,
      );
      return;
    }

    yield call(
      message.error,
      i18n.t(`myProfile:${type === GDPR_DOWNLOAD_REQUEST ? 'DataDownload' : 'DataDeletion'}.fail`),
    );
  }
}

function* doCreateGDPRRequestForDashboardUser({
  payload: { type, user },
}: SubmitGDPRRequestForDashboardUser) {
  try {
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);

    const pipAppUserResponse = yield call(pipClient.getAppUser, user.uuid);
    const pipAppUser = pipAppUserResponse.results[0];

    const updatedRequests = yield doCreateGDPRRequest(
      pipAppUser,
      type,
      user.userType,
      user.hospitalId,
    );

    const latestRequest = {
      ...updatedRequests.requests[updatedRequests.requests.length - 1],
      appUser: extractAppUserIdFromUrl(
        updatedRequests.requests[updatedRequests.requests.length - 1].appUser,
      ),
    };

    yield put(submitGDPRRequestSuccess(latestRequest));
    const history = yield getContext('history');
    yield call(history.goBack);
    yield call(
      message.success,
      i18n.t(
        `dataRequests:${
          type === GDPR_DOWNLOAD_REQUEST ? 'CreateDataRequest' : 'CreateDeletionRequest'
        }.success`,
        { name: user.name },
      ),
    );
  } catch (err) {
    console.error(err);
    yield put(submitGDPRRequestFailed());

    if (err.message === 'User does not exist in PIP') {
      yield call(
        message.error,
        i18n.t(
          `dataRequests:${
            type === GDPR_DOWNLOAD_REQUEST ? 'CreateDataRequest' : 'CreateDeletionRequest'
          }.errors.userDoesntExist`,
        ),
        3,
      );
      return;
    }

    yield call(
      message.error,
      i18n.t(`myProfile:${type === GDPR_DOWNLOAD_REQUEST ? 'DataDownload' : 'DataDeletion'}.fail`),
    );
  }
}

function* doFetchGDPRContacts(): any {
  try {
    yield put(loadGDPRContacts());
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const dashboardUser = yield select(selectCurrentDashboardUser);
    const gdprContactsType = yield call(
      determinePIPKeyForGDPRContacts,
      dashboardUser.profile.hospitalId,
    );

    const [gdprContacts]: { json: { gdpr: string[]; 'gdpr-language': string } }[] = yield call(
      pipClient.getObjectsForType,
      gdprContactsType,
      'latest',
    );

    yield put(
      gdprContactsLoaded(
        gdprContacts?.json.gdpr || [],
        gdprContacts?.json['gdpr-language'] || 'en',
      ),
    );
  } catch (err) {
    console.error(err);
    yield put(loadGDPRContactsFailed());
  }
}

function* doSaveGDPRContact({ payload: { email, language } }: SaveGDPRContact): any {
  try {
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const dashboardUser = yield select(selectCurrentDashboardUser);
    const gdprContactsType = yield call(
      determinePIPKeyForGDPRContacts,
      dashboardUser.profile.hospitalId,
    );

    const [gdprContacts]: { json: { gdpr: string[]; 'gdpr-language': string } }[] = yield call(
      pipClient.getObjectsForType,
      gdprContactsType,
      'latest',
    );

    if (!gdprContacts) {
      const { appToken } = yield select(selectAppDetails);
      const pipUrlWithAppToken = `${pipAppUrl}${appToken}/`;
      yield call(pipClient.createObjectType, gdprContactsType, pipUrlWithAppToken);
    }

    const updatedJson = {
      gdpr: [email],
      'gdpr-language': language,
    };

    yield call(pipClient.createObject, gdprContactsType, updatedJson);

    yield put(saveGDPRContactSuccess(email, language));
    yield call(message.success, i18n.t('dataRequests:GDPRCard.success'));
  } catch (err) {
    console.error(err);
    yield put(saveGDPRContactFailed());
    yield call(message.error, i18n.t('dataRequests:GDPRCard.error'));
  }
}
