import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadContent } from '@redux/core/actions';
import { AnyContentMap, selectContent, selectContentLoading } from '@redux/core/reducers';
import ContentRef from '@utils/contentRef';
import { getBasicContentDetails } from '@utils/contentDetails';

const useContentRefs = (refs?: string[]) => {
  const dispatch = useDispatch();
  const [cachedMap, setCachedMap] = useState<AnyContentMap>({});

  // Extracting useEffect deps as requested by react-hooks/exhaustive-deps
  const refsCount = refs?.length ?? 0;

  const refsAsStrings = refs?.map(ref => (ref.toString() ? ref.toString() : ref)) ?? [];

  useEffect(() => {
    dispatch(loadContent(refsAsStrings));
    // Don't include refs as a dependency to avoid re-running the
    // effect when the array is updated and depend on refsCount instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refsCount, dispatch]);

  const content = useSelector(selectContent(refsAsStrings));
  const contentCount = Object.keys(content).length;

  useEffect(() => {
    setCachedMap(content);
    // Don't include content as a dependency to avoid re-running the
    // effect when the array is updated and depend on contentCount instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentCount, refsCount]);

  return cachedMap;
};

export const useContentRefFromUrl = (urlFragment: string) => {
  const ref = ContentRef.fromUrlFragment(urlFragment);
  const refAsString = ref.toString();
  const { [refAsString]: content } = useContentRefs([refAsString]);
  const { [refAsString]: loading } = useSelector(selectContentLoading([refAsString]));
  return [loading, content, ref];
};

export const useContentRef = (ref: string) => {
  const contentRef = ContentRef.normaliseString(ref);
  const { [contentRef]: content } = useContentRefs([contentRef]);
  const { [contentRef]: loading } = useSelector(selectContentLoading([contentRef]));
  const contentDetails = content && getBasicContentDetails(content);
  return {
    loading,
    content,
    isDeleted: !loading && !content,
    url: contentDetails?.content_ref?.toDashboardUrl(contentDetails?.content_type),
  };
};

export default useContentRefs;
