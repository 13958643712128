import { FEATURES, superUserCreatesManagedPathwayByDefault } from 'settings';

export const Permissions = {
  AdministerAllHospitals: 'administer-all-hospitals',
  AdministerHospital: 'administer-hospital',
  ViewPatients: 'view-patients',
  ViewDashboardUsers: 'view-dashboard-users',
  ViewDataRequests: 'view-data-requests',
  ViewPathwayLibrary: 'view-pathway-library',
  ManageConnectionCodes: 'manage-connection-codes',
  ManagePatients: 'manage-patients',
  ManageSystemAdmin: 'manage-system-admin',
  ManageSupportAdmin: 'manage-support-admin',
  ManageSuperAdmin: 'manage-super-admin',
  ManageHospitalAdmin: 'manage-hospital-admin',
  ManageHospitalUser: 'manage-hospital-user',
  ViewMessages: 'view-messages',
  CreateRule: 'create-rule',
  CreatePathway: 'create-pathway',
  CreateManagedPathway: 'create-managed-pathway',
  InviteSystemAdmin: 'invite-system-admin',
  InviteSupportAdmin: 'invite-support-admin',
  InviteSuperAdmin: 'invite-super-admin',
  InviteHospitalAdmin: 'invite-hospital-admin',
  InviteHospitalUser: 'invite-hospital-user',
  ViewAdministation: 'view-administration',
  AdministerIndexEvents: 'administer-index-events',
  PerformPathwayActionsInListView: 'perform-pathway-actions-in-list-view',
  ViewHospitalMetrics: 'view-hospital-metrics',
  AccessUbiquity: 'access-ubiquity',
  AccessCarbon: 'access-carbon',
};

const withOptionalPermissions = (permissions, optionalMap = {}) => {
  const result = [...permissions];
  for (const [key, value] of Object.entries(optionalMap)) {
    if (Boolean(value)) {
      result.push(key);
    }
  }
  return result;
};

export const permissionsMap = {
  'system-admin': [
    Permissions.InviteSuperAdmin,
    Permissions.InviteSupportAdmin,
    Permissions.InviteSystemAdmin,
    Permissions.ManageSystemAdmin,
    Permissions.ManageSupportAdmin,
    Permissions.ManageSuperAdmin,
    Permissions.ViewDashboardUsers,
    Permissions.ViewAdministation,
    Permissions.AdministerIndexEvents,
    Permissions.ViewPathwayLibrary,
  ],
  'support-admin': [
    Permissions.AdministerAllHospitals,
    Permissions.CreatePathway,
    Permissions.CreateManagedPathway,
    Permissions.CreateRule,
    Permissions.InviteSupportAdmin,
    Permissions.InviteSuperAdmin,
    Permissions.ManageSupportAdmin,
    Permissions.ManageSuperAdmin,
    Permissions.ManageHospitalAdmin,
    Permissions.ManageHospitalUser,
    Permissions.ViewDashboardUsers,
    Permissions.ViewAdministation,
    Permissions.AdministerIndexEvents,
    Permissions.PerformPathwayActionsInListView,
    Permissions.ViewPathwayLibrary,
  ],
  'super-admin': withOptionalPermissions(
    [
      Permissions.AdministerAllHospitals,
      Permissions.CreatePathway,
      Permissions.CreateRule,
      Permissions.InviteSuperAdmin,
      Permissions.InviteHospitalAdmin,
      Permissions.ManageSuperAdmin,
      Permissions.ManageHospitalAdmin,
      Permissions.ManageHospitalUser,
      Permissions.ViewDashboardUsers,
      Permissions.ViewDataRequests,
      Permissions.ViewAdministation,
      Permissions.AdministerIndexEvents,
      Permissions.PerformPathwayActionsInListView,
      Permissions.AccessCarbon,
      Permissions.AccessUbiquity,
    ],
    {
      // When superUserCreatesManagedPathwayByDefault is set to true, this enables the FPA behaviour of hiding the checkbox,
      // And having all super user created pathways set to managed.
      [Permissions.CreateManagedPathway]: !superUserCreatesManagedPathwayByDefault,
      [Permissions.ViewPathwayLibrary]: FEATURES.pathwayLibrary,
    },
  ),
  'hospital-admin': withOptionalPermissions(
    [
      Permissions.AdministerHospital,
      Permissions.ViewMessages,
      Permissions.ViewPatients,
      Permissions.ManagePatients,
      Permissions.InviteHospitalAdmin,
      Permissions.InviteHospitalUser,
      Permissions.ManageConnectionCodes,
      Permissions.ManageHospitalAdmin,
      Permissions.ManageHospitalUser,
      Permissions.ViewDashboardUsers,
      Permissions.ViewDataRequests,
      Permissions.ViewAdministation,
      Permissions.CreatePathway,
      Permissions.PerformPathwayActionsInListView,
      Permissions.CreateRule,
      Permissions.AdministerIndexEvents,
      Permissions.ViewHospitalMetrics,
    ],
    {
      [Permissions.ViewPathwayLibrary]: FEATURES.pathwayLibrary,
    },
  ),
  'hospital-user': [
    Permissions.ViewPatients,
    Permissions.ManagePatients,
    Permissions.ViewMessages,
    Permissions.ViewHospitalMetrics,
  ],
};
