import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Empty } from 'antd';
import FormPreviewModal from '@components/FormPreviewModal';
import { Card } from '../../Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquare, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { fetchForms, downloadForm } from '../../../redux/forms/actions';
import { selectForm } from '../../../redux/forms/reducers';
import './style.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

function determineFileName(form) {
  if (!form) return;

  if (form.latestVersion?.metadata?.fileName) {
    return form.latestVersion.metadata.fileName;
  }

  if (form.metadata?.fileName) {
    return form.metadata?.fileName;
  }

  return 'form.json';
}

const FormContentCard = ({ form, loading, canDownload, canPreview, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const fileName = determineFileName(form);
  const formData = useMemo(() => form?.latestVersion || form, [form]);

  const handleDownload = useCallback(() => dispatch(downloadForm(fileName, formData)), [
    dispatch,
    fileName,
    formData,
  ]);

  const handleOpenModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  return (
    <Card.Half
      className="form-content"
      noPadding
      title={t('cards:FormContent.title')}
      loading={loading}
      extra={
        <>
          {canPreview ? (
            <FontAwesomeIcon
              icon={faExternalLinkSquare}
              className="modal-btn clickable-icon"
              onClick={handleOpenModal}
            />
          ) : null}
          {canPreview ? (
            <FontAwesomeIcon
              icon={faDownload}
              className="clickable-icon"
              onClick={() => handleDownload()}
            />
          ) : null}
        </>
      }
      {...props}
    >
      {form === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form {...formItemLayout} className="detail-form">
          <Form.Item label={t('cards:FormContent.fileLabel')}>
            <span className="ant-form-text">{fileName}</span>
          </Form.Item>
        </Form>
      )}
      <FormPreviewModal
        readOnly
        name={form?.name}
        visible={visible}
        handleCloseModal={closeModal}
        loading={loading}
        schema={formData?.schema ?? {}}
        uiSchema={(formData?.uiSchema || formData?.ui_schema) ?? {}}
      />
    </Card.Half>
  );
};

const FormContentCardbyId = ({ formId, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchForms());
  }, [dispatch, formId]);

  const [loading, form] = useSelector(selectForm(formId));

  return <FormContentCard form={form} loading={loading} {...props} />;
};

export { FormContentCard };

export default FormContentCardbyId;
