import createOrganisationDirectoryClient from '@api/organisationDirectoryClient';
import { Organisation } from '@liquid-state/directory-client';
import TokenPool from '@redux/TokenPool';

class InvalidOrganisationSlug extends Error {}

class OrganisationDetailsCache {
  private cache = new Map<string, Promise<Organisation>>();

  constructor(private tokens: TokenPool) {}

  get = async (slug: string): Promise<Organisation> => {
    const cachedOrg = this.cache.get(slug);
    if (cachedOrg) {
      return cachedOrg;
    }

    const promise = (async () => {
      const client = await createOrganisationDirectoryClient(this.tokens);
      const result = await client.getOrganisationBySlug(slug, true);

      if (result.count !== 1) {
        throw new InvalidOrganisationSlug(slug);
      }

      const orgDetails = result.results[0];

      return orgDetails;
    })();

    this.cache.set(slug, promise);

    return promise;
  };
}

export default OrganisationDetailsCache;
