import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip, Icon, Typography, Spin } from 'antd';
import { TRANSITION_NEXT } from '@constants';
import useContentRefs, { useContentRef } from '@hooks/useContentRefs';
import ContentRef from '@utils/contentRef';
import { REMINDER, ALERT } from '@utils/contentTypes';
import { selectContentLoading } from '@redux/core/reducers';
import { getBasicContentDetails } from '@utils/contentDetails';
import {
  ADHOC_MESSAGE,
  FORM_SUBMISSION,
  RULE_EXECUTION,
  STAGE_TRANSITION,
  UNSUPPORTED_ACTION,
  OTHER,
  FLAG_RAISED,
  FLAG_RESOLVED,
} from './utils';
import { selectFlags } from '@redux/flags/reducers';
import FlagPopover from '@components/FlagPopover';
import FlagIcon from '@components/FlagIcon';

export default ({ record, t, pathwayDataById, appUserId }) => {
  switch (record.type) {
    case STAGE_TRANSITION:
      return <StageTransition record={record} t={t} pathwayDataById={pathwayDataById} />;
    case RULE_EXECUTION:
      return <RuleExecution record={record} t={t} />;
    case FORM_SUBMISSION:
      return <FormSubmission record={record} t={t} appUserId={appUserId} />;
    case ADHOC_MESSAGE:
      return <AdhocMessage record={record} t={t} />;
    case FLAG_RAISED:
    case FLAG_RESOLVED:
      return <FlagChanged record={record} />;
    case OTHER:
      return <Other record={record} t={t} />;
    default:
      return UNSUPPORTED_ACTION;
  }
};

const StageTransition = ({ record, t, pathwayDataById }) => {
  return (
    <Link to={`/procedure/pathways/${pathwayDataById[record.data.pathway_id]?.originalId}`}>
      {record.data.previous_stage_name || t('cards:JourneyTimeline.pathwayAdded')}{' '}
      <FontAwesomeIcon icon={faArrowRight} /> {record.data.new_stage_name}
    </Link>
  );
};

const RuleExecution = ({ record, t }) => {
  if (record.data.rule_what_type === TRANSITION_NEXT) {
    if (record.data.execution_details.execution_type === false) {
      // Rule was not executed, likely because there is no future stage to transition to.
      return t('cards:JourneyTimeline.notApplicable');
    }
    return (
      <Link to={`/procedure/pathways/${record.data.original_pathway_id}`}>
        {record.data.execution_details.current_stage_name} <FontAwesomeIcon icon={faArrowRight} />{' '}
        {record.data.execution_details.data?.new_stage_name}
      </Link>
    );
  }

  // Nest this in a separate component so we can delay the call of hooks.
  return <RuleExecutionWithContent record={record} t={t} />;
};

const RuleExecutionWithContent = ({ record, t }) => {
  const { loading, content, isDeleted, url } = useContentRef(
    record.data.content || record.data.rule_what_details?.content,
  );

  if (loading) {
    return <Spin />;
  }

  if (isDeleted) {
    const name = record.data.rule_what_details.name || record.data.rule_what_details.title;
    return (
      <Tooltip title={t('cards:JourneyTimeline.contentDeleted')}>
        <Typography.Text type="secondary">
          {name} <Icon type="info-circle" />
        </Typography.Text>
      </Tooltip>
    );
  }

  return <Link to={url}>{content.name}</Link>;
};

const FormSubmission = ({ record, t, appUserId }) => {
  const contentRef = ContentRef.normaliseString(record.data.content);
  const { [contentRef]: content } = useContentRefs([contentRef]);
  const { [contentRef]: loading } = useSelector(selectContentLoading([contentRef]));

  if (loading) {
    return <Spin />;
  }

  if (!content && !loading) {
    return (
      <Typography.Text type="secondary">
        {t('cards:JourneyTimeline.contentDeleted')}
      </Typography.Text>
    );
  }

  if (content) {
    const contentDetails = getBasicContentDetails(content);
    return (
      <Link
        to={`/patients/individuals/${appUserId}/${contentDetails.content_type.toLowerCase()}s/${
          contentDetails.id
        }/${record.data.submission_uuid}/`}
      >
        {contentDetails.content_name}
      </Link>
    );
  }

  return contentRef;
};

const AdhocMessage = ({ record, t }) => {
  switch (record.data.metadata?.type) {
    case ALERT:
      return t('cards:JourneyTimeline.triggers.adhocAlert');
    case REMINDER:
      return t('cards:JourneyTimeline.triggers.adhocReminder');
    default:
      return t('cards:JourneyTimeline.triggers.adhocMessage');
  }
};

const Other = ({ record, t }) => {
  if (record.data.engagement_check) {
    return record.data.result;
  }
  return t('cards:JourneyTimeline.notApplicable');
};

const FlagChanged = ({ record }) => {
  const [, organisationFlags] = useSelector(selectFlags);
  const flag = organisationFlags.find(of => of.uuid === record.data.flag);
  return (
    <FlagPopover flag={flag}>
      <span className="timeline-flag-icon">
        <FlagIcon colour={flag?.colour} />
      </span>
    </FlagPopover>
  );
};
