import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Input } from 'antd';
import { Mod37_36 } from '@konfirm/iso7064';
import { useFeature } from '@hooks';

function validateUCID(value) {
  return Mod37_36.validate(value);
}

export const validateExternalId = value => {
  return !!value.trim();
};

export const useUCIDValidation = () => {
  const { ucidIsRequired } = useFeature('ucidIsRequired');
  const { ucidIsMod3736 } = useFeature('ucidIsMod3736');
  return values => {
    if (ucidIsRequired && values.map(v => !v || v.trim().length === 0).indexOf(true) > -1) {
      return false;
    }
    if (ucidIsMod3736) {
      const failed = values
        .filter(v => v)
        .map(v => Mod37_36.validate(v.trim()))
        .find(result => !result);
      return !failed;
    }
    return true;
  };
};

export const UCIDValidationErrorModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal
      title="UCID"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          OK
        </Button>,
      ]}
    >
      <span>{t('patients:NewPatient.basicDetails.admin.ucidField.required')}</span>
    </Modal>
  );
};

function UCIDInput({ record, callback }) {
  const [shouldDisplayModal, setShouldDisplayModal] = useState(false);
  const { ucidIsMod3736 } = useFeature('ucidIsMod3736');
  const validationFunction = ucidIsMod3736 ? validateUCID : validateExternalId;
  return (
    <>
      <Input
        allowClear={true}
        defaultValue={record.externalId}
        onBlur={e => {
          const value = e.target.value.trim();
          if (value) {
            const validationResult = validationFunction(value);
            setShouldDisplayModal(!validationResult);
            if (validationResult) {
              callback(value);
            }
          } else {
            setShouldDisplayModal(false);
            callback('');
          }
        }}
      />
      <UCIDValidationErrorModal
        visible={shouldDisplayModal}
        onClose={() => setShouldDisplayModal(false)}
      />
    </>
  );
}

UCIDInput.propTypes = {
  record: PropTypes.object,
  callback: PropTypes.func,
};

UCIDInput.defaultProps = {
  record: {},
  callback: () => {},
};

export default UCIDInput;
