import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { faCopy, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { selectEditableRules } from '@pathways/redux/rules/reducers';
import { deleteRules, duplicateRules, fetchRules } from '@pathways/redux/rules/actions';
import IconButton from '@components/IconButton';
import SearchableTable from '@components/SearchableTable';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import createColumns from './createColumns';
import confirmModal from '../PathwayList/confirmModal';
import confirmDuplicateModal from './confirmDuplicateModal';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

const ProcedureRuleListCard = ({ showTitle }) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const columns = useMemo(() => createColumns(t), [t]);
  const permissions = useHasPermissions();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onRowSelectionChange = useCallback(
    (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    [setSelectedRows, setSelectedRowKeys],
  );

  const onDuplicateRules = useCallback(() => {
    dispatch(duplicateRules(selectedRows));
    return;
  }, [dispatch, selectedRows]);
  const onDeleteRules = useCallback(() => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
    dispatch(deleteRules(selectedRowKeys));
    return;
  }, [dispatch, selectedRowKeys]);

  useEffect(() => {
    dispatch(fetchRules());
  }, [dispatch]);

  const [loading, rules] = useSelector(selectEditableRules);

  return (
    <SearchableTable
      columns={columns}
      dataSource={rules}
      extra={
        <>
          {permissions[Permissions.PerformPathwayActionsInListView] ? (
            <>
              <IconButton
                disabled={!selectedRows.length || loading}
                icon={faCopy}
                size="large"
                tooltip={t('common:buttons.copy')}
                onClick={() => confirmDuplicateModal(t, onDuplicateRules)}
              />
              <IconButton
                disabled={!selectedRows.length || loading}
                icon={faTrashAlt}
                size="large"
                tooltip={t('common:buttons.delete')}
                onClick={() =>
                  confirmModal(t('cards:ProcedureRuleList.modals.confirmDelete'), onDeleteRules)
                }
              />
            </>
          ) : null}
        </>
      }
      filterFunc={filterFunc}
      hasRoutedPagination
      loading={loading}
      title={showTitle ? t('cards:ProcedureRuleList.title') : null}
      onRowSelectionChange={
        permissions[Permissions.PerformPathwayActionsInListView] ? onRowSelectionChange : null
      }
    />
  );
};

export default ProcedureRuleListCard;
