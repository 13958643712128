import React, { useState, useEffect, createContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Tooltip, Select, Modal } from 'antd';
import { faEye, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@components/IconButton';

import { Column, ColumnGroup } from '@cards/Card';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import { usePageActions } from '@hooks/usePageActions';

import FormDetailsStep from './FormDetailsStep';
import UploadFormStep from './UploadFormStep';
import ReviewStepColumns from './ReviewStepColumns';
import FormBuilder from '../FormBuilder';
import './style.less';

export const FormWizardContext = createContext();

function FormWizard({
  title,
  type,
  initialForm,
  initialFormData,
  onCompleted,
  loading,
  submitting,
  exitMessage,
  formTranslationKey,
  isEdit,
}) {
  const { t } = useTranslation();

  const [currentStepKey, setCurrentStepKey] = useState();
  const [isFormPreviewModalVisible, setIsFormPreviewModalVisible] = useState(false);
  const [isEditComputationsModalVisible, setIsEditComputationsModalVisible] = useState(false);
  const [hasComputations, setHasComputations] = useState(false);
  const [formType, setFormType] = useState(
    initialFormData?.uiSchema?.['ui:displayMode'] || 'standard',
  );
  const { setActions } = usePageActions({ title });

  useEffect(() => {
    if (currentStepKey === 'builder') {
      setActions(
        <div className="form-wizard-actions">
          {hasComputations ? (
            <Tooltip title={t('forms:FormWizard.steps.builder.existingComputations')}>
              <Button onClick={() => setIsEditComputationsModalVisible(true)}>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {t('forms:FormWizard.steps.builder.editComputations')}
              </Button>
            </Tooltip>
          ) : (
            <Button onClick={() => setIsEditComputationsModalVisible(true)}>
              {t('forms:FormWizard.steps.builder.editComputations')}
            </Button>
          )}
          <div className="paginated-form-switch">
            <Select value={formType} onChange={setFormType}>
              <Select.Option value="standard">
                {t('forms:FormWizard.steps.builder.standard')}
              </Select.Option>
              <Select.Option value="paginated">
                {t('forms:FormWizard.steps.builder.paginated')}
              </Select.Option>
              <Select.Option value="chat">{t('forms:FormWizard.steps.builder.chat')}</Select.Option>
            </Select>
          </div>
          <IconButton
            icon={faEye}
            onClick={() => setIsFormPreviewModalVisible(true)}
            size="large"
            tooltip={t('forms:FormWizard.steps.builder.preview')}
          />
        </div>,
      );
    } else {
      setActions(null);
    }
  }, [currentStepKey, setActions, t, hasComputations, formType, setFormType]);

  document.title = title;

  const initialWizardData = useMemo(
    () => ({
      details: {
        name: initialForm.name,
        description: initialForm.description,
        language: initialForm.language,
        status: initialForm.published === 'notPublish' ? 'notPublish' : 'publish',
        audience: initialForm.audienceType,
      },
      content: {
        fileList:
          initialForm.name && initialFormData
            ? [
                {
                  uid: 'rc-upload-1585299106361-2',
                  lastModified: 1584490873633,
                  name: initialForm.metadata.fileName || 'form.json',
                  type: 'application/json',
                  percent: 0,
                },
              ]
            : [],
        json: initialFormData,
      },
      builder: {
        schema: undefined,
      },
    }),
    [initialForm, initialFormData],
  );

  const handleBeforePreviousStep = {
    builder: (proceedCallback, wizardContext) => {
      Modal.confirm({
        title: t('forms:FormWizard.steps.builder.exitConfirmTitle'),
        content: t('forms:FormWizard.steps.builder.exitConfirmContent'),
        okText: t('forms:FormWizard.steps.builder.ok'),
        cancelText: t('forms:FormWizard.steps.builder.cancel'),
        onOk: () => {
          wizardContext.setFormData({
            ...wizardContext.formData,
            builder: {
              schema: undefined,
              startedFromScratch: false,
            },
          });
          proceedCallback();
        },
      });
    },
  };

  return (
    <FormWizardContext.Provider value={type}>
      <Wizard
        onCompleted={onCompleted}
        loading={loading}
        submitting={submitting}
        initialData={initialWizardData}
        exitModalTitle={exitMessage}
        setCurrentStepKey={setCurrentStepKey}
        beforePreviousStep={handleBeforePreviousStep}
      >
        <WizardItem stepKey="details" title={t('forms:FormWizard.steps.details.title')}>
          <ColumnGroup>
            <Column>
              <FormDetailsStep formTranslationKey={formTranslationKey} isEdit={isEdit} />
            </Column>
            <Column></Column>
          </ColumnGroup>
        </WizardItem>
        <WizardItem stepKey="content" title={t('forms:FormWizard.steps.content.title')}>
          <ColumnGroup>
            <Column>
              <UploadFormStep isEdit={isEdit} />
            </Column>
            <Column></Column>
          </ColumnGroup>
        </WizardItem>
        <WizardItem stepKey="builder" title={t('forms:FormWizard.steps.builder.title')}>
          <FormBuilder
            isFormPreviewModalVisible={isFormPreviewModalVisible}
            setIsFormPreviewModalVisible={setIsFormPreviewModalVisible}
            isEditComputationsModalVisible={isEditComputationsModalVisible}
            setIsEditComputationsModalVisible={setIsEditComputationsModalVisible}
            setHasComputations={setHasComputations}
            formType={formType}
            setFormType={setFormType}
          />
        </WizardItem>
        <WizardItem stepKey="review" title={t('forms:FormWizard.steps.review.title')}>
          <ColumnGroup>
            <ReviewStepColumns />
          </ColumnGroup>
        </WizardItem>
      </Wizard>
    </FormWizardContext.Provider>
  );
}

FormWizard.propTypes = {
  type: PropTypes.string.isRequired,
  onCompleted: PropTypes.func.isRequired,
  initialForm: PropTypes.object,
  initialFormData: PropTypes.object,
  exitMessage: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
};

FormWizard.defaultProps = {
  initialForm: {},
  initialFormData: undefined,
  isEdit: false,
};

export default FormWizard;
