import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  faCheckCircle,
  faCopy,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import IconButton from '@components/IconButton';
import { selectPathways, selectPathwaysDuplicating } from '@pathways/redux/pathways/reducers';
import {
  deletePathways,
  duplicatePathways,
  fetchPathways,
  publishPathways,
  unpublishPathways,
} from '@pathways/redux/pathways/actions';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import SearchableTable from '@components/SearchableTable';
import { waitForStoreUpdate } from '@utils';
import { useModalCallback } from '@hooks';
import createColumns from './createColumns';
import confirmDuplicateModal from './confirmDuplicateModal';
import confirmModal from './confirmModal';
import './style.less';
import useDirectoryOrganisations from '@hooks/useDirectoryOrganisations';
import { selectOrganisation } from '@redux/organisations/reducers';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

const ProcedurePathwayListCard = ({ allowRowSelection, showTitle, onRowClick }) => {
  const { t } = useTranslation();
  const permissions = useHasPermissions();

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const dispatch = useDispatch();
  const store = useStore();

  const getOrgDetailsFromSlug = useDirectoryOrganisations();
  const [, currentOrgData] = useSelector(selectOrganisation());
  const columns = useMemo(() => createColumns(t, getOrgDetailsFromSlug, currentOrgData), [
    t,
    getOrgDetailsFromSlug,
    currentOrgData,
  ]);

  const onRowSelectionChange = useCallback(
    (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedRowIds(selectedRowKeys);
    },
    [setSelectedRows],
  );

  useEffect(() => {
    dispatch(fetchPathways());
  }, [dispatch]);

  const onDuplicatePathways = useCallback(() => {
    dispatch(duplicatePathways(selectedRowIds));
    return waitForStoreUpdate(store, selectPathways);
  }, [dispatch, selectedRowIds, store]);
  const onDeletePathways = useCallback(() => {
    dispatch(deletePathways(selectedRowIds));
    return waitForStoreUpdate(store, selectPathways);
  }, [dispatch, selectedRowIds, store]);
  const onPublishPathways = useModalCallback(() => {
    dispatch(publishPathways(selectedRowIds));
    setSelectedRowIds([]);
    setSelectedRows([]);
  }, [dispatch, selectedRowIds]);
  const onUnpublishPathways = useModalCallback(() => {
    dispatch(unpublishPathways(selectedRowIds));
    setSelectedRowIds([]);
    setSelectedRows([]);
  }, [dispatch, selectedRowIds]);
  const [pathwaysLoading, allPathways] = useSelector(selectPathways);
  const duplicating = useSelector(selectPathwaysDuplicating);
  const loading = pathwaysLoading || duplicating;
  const [pathways, setPathways] = useState([]);

  useEffect(() => {
    if (allPathways?.length > 0)
      setPathways(allPathways.filter(pathway => !pathway.isSnapshot).sort((a, b) => b.id - a.id));
  }, [allPathways]);

  return (
    <SearchableTable
      columns={columns}
      dataSource={pathways}
      extra={
        <>
          {permissions[Permissions.PerformPathwayActionsInListView] && allowRowSelection ? (
            <>
              <IconButton
                disabled={!selectedRows.length}
                icon={faCopy}
                size="large"
                tooltip={t('common:buttons.copy')}
                onClick={() => confirmDuplicateModal(t, onDuplicatePathways)}
              />
              {selectedRows.length &&
              selectedRows.every((row, index, arr) => row.isActive === arr[0].isActive) ? (
                <IconButton
                  disabled={!selectedRows.length}
                  icon={selectedRows[0].isActive ? faTimesCircle : faCheckCircle}
                  size="large"
                  tooltip={
                    selectedRows[0].isActive
                      ? t('common:buttons.unpublish')
                      : t('common:buttons.publish')
                  }
                  onClick={() => {
                    if (selectedRows[0].isActive) {
                      confirmModal(
                        t('cards:ProcedurePathwayList.modals.confirmUnpublish'),
                        onUnpublishPathways,
                      );
                      return;
                    }
                    confirmModal(
                      t('cards:ProcedurePathwayList.modals.confirmPublish'),
                      onPublishPathways,
                    );
                  }}
                />
              ) : null}
              <IconButton
                disabled={!selectedRows.length}
                icon={faTrashAlt}
                size="large"
                tooltip={t('common:buttons.delete')}
                onClick={() =>
                  confirmModal(
                    t('cards:ProcedurePathwayList.modals.confirmDelete'),
                    onDeletePathways,
                  )
                }
              />
            </>
          ) : null}
        </>
      }
      filterFunc={filterFunc}
      hasRoutedPagination
      loading={loading}
      title={showTitle ? t('cards:ProcedurePathwayList.title') : null}
      onRowSelectionChange={
        permissions[Permissions.PerformPathwayActionsInListView] && allowRowSelection
          ? onRowSelectionChange
          : null
      }
    />
  );
};

ProcedurePathwayListCard.propTypes = {
  allowRowSelection: bool,
  showTitle: bool,
  onRowClick: func,
};

ProcedurePathwayListCard.defaultProps = {
  allowRowSelection: true,
  showTitle: false,
  onRowClick: null,
};

export default ProcedurePathwayListCard;
