import { call, put, select /* , take */, all, take } from 'redux-saga/effects';
import { message } from 'antd';
import doCreatePathwaysClient from '@redux/doCreatePathwaysClient';
// import { selectHospital } from '@redux/hospitals/reducers';
// import { fetchHospitals } from '@redux/hospitals/actions';
// import { LOAD_HOSPITALS_SUCCESS } from '@redux/hospitals/types';
// import { selectPermissionsForUser } from '@authorisation/selectors';
// import { Permissions } from '@authorisation/constants';
import {
  loadPathways,
  fetchingPathways,
  IFetchEngagementChecks,
  fetchEngagementChecksSuccess,
  fetchEngagementChecksFailed,
  IFetchPathwaySnapshots,
  fetchPathwaySnapshotsSuccess,
} from '../actions';
import i18n from '../../../../i18n';
import { mapRawPathway, mapRawPathwaySnapshot } from '../utils';
// import { doDetermineOwnerForPathway } from './utils';
import {
  IPathway,
  /* IPathwayIndexEvent, */ IRawPathway /* , IStage */,
  LOAD_PATHWAYS,
  IRawPathwaySnapshot,
} from '../types';
import { selectIndexEvents } from '../../indexEvents/reducers';
import { extractSlugFromTypeSlug } from '../../indexEvents/utils';
import { doFetchIndexEvents } from '../../indexEvents/saga';
import { IIndexEvent } from '../../indexEvents/types';
import { selectPathway } from '../reducers';
// import { setMasterRules } from '../../rules/actions';
import { EngagementCheck } from '@liquid-gears/schemas/dist/types/engagement-check';
import { mapEngagementCheckWhatToAction } from './utils';
import { selectSharedPathway } from '@pathways/redux/sharedPathways/reducers';
import {
  fetchSharedEngagementChecks,
  fetchSharedEngagementChecksFailed,
  fetchSharedEngagementChecksSuccess,
} from '@pathways/redux/sharedPathways/actions';

const BATCH_SIZE = 10;
const PAGE_SIZE = 20;

export function* doFetchPathwaySnapshots({ payload: { pathwayId } }: IFetchPathwaySnapshots): any {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    let page = 1;
    let snapshots: IRawPathwaySnapshot[] = [];

    while (true) {
      const {
        next,
        results,
      }: {
        count: number;
        next: string | null;
        previous: string | null;
        results: IRawPathwaySnapshot[];
      } = yield call(pathwaysClient.listPathwaySnapshots, pathwayId, page);

      snapshots = [...snapshots, ...results];

      if (!next) break;
      page += 1;
    }

    let [, indexEvents] = yield select(selectIndexEvents);
    if (!indexEvents.length) {
      yield call(doFetchIndexEvents);
      [, indexEvents] = yield select(selectIndexEvents);
    }

    const indexEventsBySlug = indexEvents.reduce(
      (bySlug: { [key: string]: IIndexEvent }, indexEvent: IIndexEvent) => ({
        ...bySlug,
        [indexEvent.slug]: indexEvent,
      }),
      {},
    );

    const snapshotsWithIndexEvents = snapshots.map(snapshot => {
      snapshot.index_events.sort((a, b) => {
        const aSlug = extractSlugFromTypeSlug(a.event_type_slug);
        const bSlug = extractSlugFromTypeSlug(b.event_type_slug);

        if (indexEventsBySlug[aSlug].order > indexEventsBySlug[bSlug].order) {
          return 1;
        }
        if (indexEventsBySlug[aSlug].order < indexEventsBySlug[bSlug].order) {
          return -1;
        }
        return 0;
      });

      return mapRawPathwaySnapshot(snapshot);
    });

    yield put(fetchPathwaySnapshotsSuccess(snapshotsWithIndexEvents));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('cards:ProcedurePathwayList.networkError'));
  }
}

export function* doFetchPathways(): any {
  yield put(fetchingPathways());

  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    // const permissions = yield select(selectPermissionsForUser);
    let pathways: IPathway[] = [];

    // Don't use ownerId anymore to filter Pathways.
    // In PEP, everyone has their own app and the claims in the
    // Authorisation header are enough for the Pathways backend to filter data.
    // const ownerId = yield call(doDetermineOwnerForPathway);
    const ownerId = undefined;
    let page = 1;

    // temporary batching logic to improve list load speed
    // fetch first page
    const {
      count,
      next: moreThanOnePage,
      results,
    }: { count: number; next: string | null; results: IRawPathway[] } = yield call(
      pathwaysClient.listPathways,
      page,
      true,
      undefined,
      ownerId,
    );
    pathways = [...results.map(pathway => mapRawPathway(pathway))];

    // if more than 1 page, break pages up in to batches of BATCH_SIZE
    if (moreThanOnePage) {
      const numberOfPages = Math.ceil(count / PAGE_SIZE);
      const pageBatches = [];
      for (let i = page; i <= numberOfPages; i += BATCH_SIZE) {
        pageBatches.push([]);

        for (let j = 1; j < BATCH_SIZE + 1; j++) {
          if (i + j <= numberOfPages) {
            const pageBatchesIndex = i > BATCH_SIZE ? Math.floor(i / BATCH_SIZE) : 0;
            pageBatches[pageBatchesIndex] = [...pageBatches[pageBatchesIndex], i + j];
          }
        }
      }

      // iterate over batches of pages to fetch all pages
      for (const batch of pageBatches) {
        const fetchedPages = yield call(
          Promise.all.bind(Promise),
          batch.map(pageNumber =>
            pathwaysClient.listPathways(pageNumber, true, undefined, ownerId),
          ),
        );

        pathways = [
          ...pathways,
          ...fetchedPages.flatMap(({ results }: { results: IRawPathway[] }) =>
            results.map(pathway => mapRawPathway(pathway)),
          ),
        ];
      }
    }

    // TODO: remove this when backend returns pathway index events in correct order
    let [, indexEvents] = yield select(selectIndexEvents);
    if (!indexEvents.length) {
      yield call(doFetchIndexEvents);
      [, indexEvents] = yield select(selectIndexEvents);
    }
    const indexEventsBySlug = indexEvents.reduce(
      (bySlug: { [key: string]: IIndexEvent }, indexEvent: IIndexEvent) => ({
        ...bySlug,
        [indexEvent.slug]: indexEvent,
      }),
      {},
    );

    pathways = pathways.map(pathway => {
      if (!pathway.isDeleted) {
        pathway.indexEvents.sort((a, b) => {
          const aSlug = extractSlugFromTypeSlug(a.eventTypeSlug);
          const bSlug = extractSlugFromTypeSlug(b.eventTypeSlug);

          if (indexEventsBySlug[aSlug].order > indexEventsBySlug[bSlug].order) {
            return 1;
          }
          if (indexEventsBySlug[aSlug].order < indexEventsBySlug[bSlug].order) {
            return -1;
          }
          return 0;
        });
      }
      return pathway;
    });
    // END TODO

    yield put(loadPathways(pathways));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('cards:ProcedurePathwayList.networkError'));
    yield put(loadPathways([]));
  }
}

export function* doFetchEngagementChecks({
  payload: { pathwayId, isSharedPathway },
}: IFetchEngagementChecks): any {
  try {
    let pathway;
    if (isSharedPathway) {
      yield put(fetchSharedEngagementChecks(pathwayId));
      pathway = yield select(selectSharedPathway(pathwayId.toString()));
      if (!pathway?.id) {
        yield take('sharedPathways/fetchSharedPathwaySnapshotsSuccess');
        pathway = yield select(selectSharedPathway(pathwayId.toString()));
      }
    } else {
      [, pathway] = yield select(selectPathway(Number(pathwayId)));
      if (!pathway?.id) {
        yield take(LOAD_PATHWAYS);
        [, pathway] = yield select(selectPathway(Number(pathwayId)));
      }
    }

    let [, indexEvents] = yield select(selectIndexEvents);
    if (!indexEvents.length) {
      yield call(doFetchIndexEvents);
      [, indexEvents] = yield select(selectIndexEvents);
    }

    if (!pathway?.id) {
      console.error('Pathway not found');
      yield put(fetchEngagementChecksFailed(pathwayId));
      return;
    }

    const stages = pathway.stages;
    const rules = stages?.reduce((acc: any, stage: any) => {
      if (stage.rules) {
        return [
          ...acc,
          ...stage.rules.filter(
            (rule: any) => !acc.some((existingRule: any) => existingRule.id === rule.id),
          ),
        ];
      }
      return acc;
    }, []);

    const getTarget = (type: string, id: string) => {
      if (type.includes('stage')) return stages?.find((stage: any) => stage.slug.toString() === id);
      if (type.includes('index'))
        return indexEvents?.find((event: any) => event.slug.toString() === id);
      if (type.includes('rule') || type.includes('submission'))
        return rules?.find(
          (rule: any) =>
            rule.id?.toString() === id ||
            rule.whatDetail?.id?.toString() === id ||
            rule.whatDetail?.uuid?.toString() === id,
        );
    };

    const determineTimingType = (timingStep: string, type: string) => {
      const typeMappings: { [key: string]: string } = {
        stage: 'stage-entry',
        index_event: 'index-event',
        rule_execution: 'rule-execution',
        form_submission: 'form-submission',
      };

      const baseString = typeMappings[type];

      switch (timingStep) {
        case 'from':
        case 'until':
          return `${timingStep}-${baseString}`;
        case 'start':
        case 'stop':
          return `${timingStep}-engagement-check-from-${baseString}`;
        default:
          throw new Error('Invalid timingStep or type');
      }
    };

    const pathwaysClient = yield call(doCreatePathwaysClient);
    let page = 1;
    let next = true;
    let results: any[] = [];
    while (next) {
      const data = yield call(pathwaysClient.listEngagementChecks, pathwayId, page);
      if (data.count) {
        results = results.concat(data.results);
      }
      next = data.next;
      page += 1;
    }

    const formattedEngagementChecks = yield all(
      results.map((check: EngagementCheck) => {
        return {
          id: check.id || Math.floor(Math.random() * 1000000000),
          contentToCheckEngagement: {
            selectedContent: check.engagement_events
              .map(event => {
                return rules?.find(
                  (rule: any) =>
                    rule.whatDetail?.id?.toString() === event.content_id ||
                    rule.whatDetail?.uuid === event.content_id ||
                    rule.whatDetail?.product_id === event.content_id,
                );
              })
              .filter(Boolean),
          },
          typeOfCheck: {
            checkType: check.engagement_type,
            numberOfContents: check.engagement_count,
          },
          timing: {
            from: {
              type:
                check.check_time_from_type &&
                determineTimingType('from', check.check_time_from_type),
              value: getTarget(check.check_time_from_type, check.check_time_from_target),
              daysOffset: check.check_time_from_offset,
            },
            until: {
              type:
                check.check_time_until_type &&
                determineTimingType('until', check.check_time_until_type),
              value: getTarget(check.check_time_until_type, check.check_time_until_target),
              daysOffset: check.check_time_until_offset,
            },
            start: {
              type:
                check.event_time_from_type &&
                determineTimingType('start', check.event_time_from_type),
              value:
                check.event_time_from_type &&
                check.event_time_from_target &&
                getTarget(check.event_time_from_type, check.event_time_from_target),
              daysOffset: check.event_time_from_offset,
            },
            stop: {
              type:
                check.event_time_until_type &&
                determineTimingType('stop', check.event_time_until_type),
              value:
                check.event_time_until_type &&
                check.event_time_until_target &&
                getTarget(check.event_time_until_type, check.event_time_until_target),
              daysOffset: check.event_time_until_offset,
            },
          },
          occurrences: {
            type: check.frequency_type,
            nTimes: check.frequency_count,
            untilAnotherEngagementCheckPassed:
              check.frequency_type === 'until_engagement_check'
                ? check.frequency_target
                : undefined,
          },
          action: mapEngagementCheckWhatToAction(check),
          details: {
            name: check.name,
            description: check.description,
          },
        };
      }),
    );

    if (isSharedPathway) {
      yield put(fetchSharedEngagementChecksSuccess(pathwayId, formattedEngagementChecks));
    } else {
      yield put(fetchEngagementChecksSuccess(pathwayId, formattedEngagementChecks));
    }
  } catch (err) {
    console.error(err);
    yield put(fetchEngagementChecksFailed(pathwayId));
    if (isSharedPathway) {
      yield put(fetchSharedEngagementChecksFailed(pathwayId));
    }
  }
}
