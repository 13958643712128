import React, { useCallback, useState, useMemo } from 'react';
import FormUpload from '@cards/Forms/FormUpload';
import useWizard from '@components/Wizard/useWizard';

export default ({ isEdit }) => {
  const { currentStepData, formData, setFormData, onNextStep } = useWizard();

  const defaultFileList = useMemo(() => currentStepData?.fileList || [], []); // eslint-disable-line react-hooks/exhaustive-deps

  const [fileList, setFileList] = useState(defaultFileList);

  const onSubmit = useCallback(
    (newFileList, json) => {
      setFileList(newFileList);
      setFormData({
        ...formData,
        content: {
          fileList: newFileList,
          json,
        },
        builder: {
          schema: undefined,
        },
      });
    },
    [setFormData, formData],
  );

  return (
    <FormUpload
      onSubmit={onSubmit}
      fileList={fileList}
      setFileList={setFileList}
      onNextStep={onNextStep}
      isEdit={isEdit}
    />
  );
};
