export const CREATE_RULE = 'CREATE_RULE';
export const CREATE_RULE_FAILURE = 'CREATE_RULE_FAILURE';
export const DELETE_RULE = 'DELETE_RULE';
export const DELETE_RULE_SUCCESS = 'DELETE_RULE_SUCCESS';
export const DELETE_RULES = 'DELETE_RULES';
export const DELETE_RULES_SUCCESS = 'DELETE_RULES_SUCCESS';
export const DUPLICATE_RULE = 'DUPLICATE_RULE';
export const DUPLICATE_RULE_SUCCESS = 'DUPLICATE_RULE_SUCCESS';
export const DUPLICATE_RULES = 'DUPLICATE_RULES';
export const DUPLICATE_RULES_SUCCESS = 'DUPLICATE_RULES_SUCCESS';
export const EDIT_RULE = 'EDIT_RULE';
export const EDIT_RULE_SUCCESS = 'EDIT_RULE_SUCCESS';
export const FETCH_RULES = 'FETCH_RULES';
export const FETCHING_RULES = 'FETCHING_RULES';
export const FETCH_RULES_ERROR = 'FETCH_RULES_ERROR';
export const LOAD_RULES = 'LOAD_RULES';
export const RULE_CREATED = 'RULE_CREATED';
export const FETCH_RULES_WHAT_DETAIL = 'FETCH_RULES_WHAT_DETAIL';
export const FETCH_RULES_WHAT_DETAIL_SUCCESS = 'FETCH_RULES_WHAT_DETAIL_SUCCESS';
export const FETCH_PATHWAY_RULES_WHAT_DETAIL = 'FETCH_PATHWAY_RULES_WHAT_DETAIL';
export const FETCH_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS =
  'FETCH_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS';
export const FETCH_SHARED_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS =
  'FETCH_SHARED_PATHWAY_ENGAGEMENT_CHECK_ACTIONS_WHAT_DETAIL_SUCCESS';

export interface IRule {
  description: string;
  id: number;
  metadata: { [key: string]: any };
  name: string;
  url: string;
  what: string;
  whatDetail: { [key: string]: any };
  when: string;
  whenDetail: { [key: string]: any };
  who: string;
  whoDetail: { [key: string]: any };
  ownerId: string;
  audienceType: string;
  isReadOnly: boolean;
}

export interface IRawRule {
  description: string;
  id: number;
  metadata: { [key: string]: any };
  name: string;
  url: string;
  what: string;
  what_detail: { [key: string]: any };
  when: string;
  when_detail: { [key: string]: any };
  who: string;
  who_detail: { [key: string]: any };
  owner_id: string;
  audience_type: string;
  is_readonly: boolean;
}

export interface IEngagementCheckActionAsRule extends IRule {
  engagementCheckId?: number;
}

export interface IFetchRuleWhatDetailOptions {
  rule: IEngagementCheckActionAsRule;
  sharedPathwayId?: number;
  pathwayId?: number;
}
