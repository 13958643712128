import { call, getContext, put, select } from 'redux-saga/effects';
import { message } from 'antd';
import {
  createPathwayFailure,
  createPathwaySuccess,
  editPathwaySuccess,
  editPathwayFailed,
  ICreatePathway,
  IEditPathway,
  IAddEngagementCheck,
  addEngagementCheckSuccess,
  addEngagementCheckFailed,
  IEditEngagementCheck,
  editEngagementCheckSuccess,
  editEngagementCheckFailed,
  createPathwaySnapshotSuccess,
  createPathwaySnapshotFailed,
  ICreatePathwaySnapshot,
  sharePathwaySnapshot,
  sharePathwaySnapshotSuccess,
  sharePathwaySnapshotFailed,
  ISharePathwaySnapshot,
  unsharePathwaySnapshotSuccess,
  unsharePathwaySnapshotFailed,
} from '../actions';
import { selectPathway } from '../reducers';
import i18n from '../../../../i18n';
import doCreatePathwaysClient from '@redux/doCreatePathwaysClient';
import { superUserCreatesManagedPathwayByDefault } from 'settings';
import {
  generateSlugName,
  mapRawPathway,
  mapRawPathwayIndexEvent,
  mapRawPathwayStage,
  mapRawPathwaySnapshot,
} from '../utils';
import {
  doDetermineOwnerForPathway,
  determineEngagementTimingType,
  contentToEngagementEvents,
  mapEngagementActionToWhat,
} from './utils';
import { IPathwayIndexEvent, IStage } from '../types';
import ContentRef from '../../../../utils/contentRef';
import { NewEngagementCheck } from '@liquid-gears/schemas/dist/types/new-engagement-check';
import { EngagementCheck } from '@liquid-gears/schemas/dist/types/engagement-check';
import { selectOrganisationSlug } from '@organisation/redux/selectors';
import { selectUserRole } from '@authorisation/selectors';
import { SUPER_ADMIN } from '@constants';

const getRandomString = (length = 6) =>
  Math.round(Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))
    .toString(36)
    .slice(1)
    .toUpperCase();

function createUniqueSlug(slug: string, name?: string) {
  const baseSlugName = slug || (name && generateSlugName(name));
  const slugName = `${baseSlugName}-${getRandomString(6)}`;
  return slugName;
}

export function* doCreatePathway({ payload: { pathway } }: ICreatePathway): any {
  const history = yield getContext('history');

  try {
    const ownerId = yield call(doDetermineOwnerForPathway);
    const pathwaysClient = yield call(doCreatePathwaysClient);

    const { description, language, name } = pathway;
    let isManagedPathway = pathway.isManagedPathway;
    if (isManagedPathway === undefined) {
      isManagedPathway = false; // We must send a valid boolean not undefined.
      if (superUserCreatesManagedPathwayByDefault) {
        const role = yield select(selectUserRole);
        if (role === SUPER_ADMIN) {
          isManagedPathway = true;
        }
      }
    }
    const rawNewPathway = yield call(pathwaysClient.createPathway, {
      name,
      description,
      isActive: true,
      metadata: { language },
      ownerId,
      isManagedPathway,
    });
    const newPathway = mapRawPathway(rawNewPathway);

    for (const stage of pathway.stages) {
      const { description, isAdhoc, name, number, rules, slug } = stage;

      const slugName = createUniqueSlug(slug, name);

      const newStage = yield call(pathwaysClient.createPathwayStage, newPathway.id, {
        description: description || '',
        isAdhoc,
        name,
        number,
        rules: rules.map(({ id }) => id),
        slug: slugName,
      });

      const patchedStage = yield call(
        pathwaysClient.patchPathwayStage,
        newPathway.id,
        newStage.id,
        {
          description,
          isAdhoc,
          name,
          number,
          rules: rules.map(({ id }) => id),
          slug: slugName,
        },
      );

      const mappedPathwayStage = mapRawPathwayStage(patchedStage);
      if (mappedPathwayStage) newPathway.stages.push(mappedPathwayStage);
    }

    for (const indexEvent of pathway.indexEvents) {
      const { eventTypeSlug, rules } = indexEvent;
      const newIndexEvent = yield call(
        pathwaysClient.createPathwayIndexEvent,
        newPathway.id,
        eventTypeSlug,
        rules.map(({ id }) => id),
      );

      newPathway.indexEvents.push(mapRawPathwayIndexEvent(newIndexEvent));
    }

    yield put(createPathwaySuccess(newPathway));
    yield call(history.push, '/procedure/pathways');
    yield call(message.success, i18n.t('pathways:ProcedurePathways.createSuccess'));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('pathways:ProcedurePathways.wizard.networkError'));
    yield put(createPathwayFailure());
  }
}

export function* doEditPathway({ payload: { pathwayId, editedPathway } }: IEditPathway): any {
  const history = yield getContext('history');
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const [, existingPathway] = yield select(selectPathway(pathwayId));

    const {
      description,
      isActive,
      isDeleted,
      isManagedPathway,
      indexEvents,
      language,
      name,
      stages,
    } = editedPathway;

    // update stage numbers based on order of edited pathway
    stages.forEach((stage, index) => {
      // ignore non-aligned/adhoc stage
      if (stage.number === 99) return;
      stage.number = index + 1;
    });

    const updatedPathway = mapRawPathway(
      yield call(pathwaysClient.patchPathway, pathwayId, {
        description,
        isActive,
        isDeleted,
        isManagedPathway,
        metadata: { ...existingPathway.metadata, language },
        name,
      }),
    );

    const newStages = [];
    for (const stage of stages) {
      let updatedStage;
      if (stage.id) {
        const existingStage = existingPathway.stages.find(
          (existingStage: IStage) => existingStage.id === stage.id,
        );
        updatedStage = yield call(pathwaysClient.patchPathwayStage, pathwayId, stage.id, {
          ...existingStage,
          ...stage,
          rules: stage.rules.map(({ id }) => id),
        });
      } else {
        const { description, isAdhoc, name, number, rules, slug } = stage;
        const slugName = createUniqueSlug(slug, name);
        updatedStage = yield call(pathwaysClient.createPathwayStage, pathwayId, {
          description: description || '',
          isAdhoc,
          name,
          number,
          rules: rules.map(({ id }) => id),
          slug: slugName,
        });

        const patchedStage = yield call(
          pathwaysClient.patchPathwayStage,
          pathwayId,
          updatedStage.id,
          {
            description: description || '',
            isAdhoc,
            name,
            number,
            rules: rules.map(({ id }) => id),
            slug: slugName,
          },
        );

        updatedStage = { ...updatedStage, ...patchedStage };
      }

      const mappedPathwayStage = mapRawPathwayStage(updatedStage);
      if (mappedPathwayStage) newStages.push(mappedPathwayStage);
    }

    const newStageIds = newStages.map(({ id }) => id);
    const stagesToDelete = existingPathway.stages.filter(
      ({ id }: IStage) => !newStageIds.includes(Number(id)),
    );

    for (const { id: stageId, ...stageData } of stagesToDelete) {
      yield call(pathwaysClient.patchPathwayStage, pathwayId, stageId, {
        ...stageData,
        number: 0,
        isDeleted: true,
      });
    }

    const newIndexEvents = [];

    for (const indexEvent of indexEvents) {
      let updatedIndexEvent;
      if (indexEvent.id) {
        updatedIndexEvent = yield call(
          pathwaysClient.patchPathwayIndexEvent,
          pathwayId,
          indexEvent.id,
          indexEvent.eventTypeSlug,
          indexEvent.rules.map(({ id }) => id),
        );
      } else {
        const { eventTypeSlug, rules } = indexEvent;
        updatedIndexEvent = yield call(
          pathwaysClient.createPathwayIndexEvent,
          pathwayId,
          eventTypeSlug,
          rules.map(({ id }) => id),
        );
      }

      newIndexEvents.push(mapRawPathwayIndexEvent(updatedIndexEvent));
    }
    const newIndexEventIds = newIndexEvents.map(({ id }) => id);

    const indexEventsToDelete = existingPathway.indexEvents
      .filter(({ id }: IPathwayIndexEvent) => !newIndexEventIds.includes(id))
      .map(({ id }: IPathwayIndexEvent) => id);

    for (const indexEventId of indexEventsToDelete) {
      yield call(pathwaysClient.deletePathwayIndexEvent, pathwayId, indexEventId);
    }

    yield put(
      editPathwaySuccess({
        ...updatedPathway,
        id: pathwayId,
        indexEvents: newIndexEvents,
        stages: newStages,
      }),
    );
    yield call(history.push, `/procedure/pathways/${pathwayId}`);
    yield call(message.success, i18n.t('pathways:ProcedurePathways.editSuccess'));
  } catch (err) {
    console.error(err);
    yield put(editPathwayFailed());
    yield call(message.error, i18n.t('pathways:ProcedurePathways.editError'));
  }
}

export function* doAddEngagementCheck({
  payload: { pathwayId, engagementCheck },
}: IAddEngagementCheck): any {
  try {
    const organisationSlug = yield select(selectOrganisationSlug());
    const engagement_events = contentToEngagementEvents(
      engagementCheck.contentToCheckEngagement.selectedContent,
    );

    const [mappedWhat, mappedWhatDetail] = mapEngagementActionToWhat(engagementCheck.action);

    let contentRef: undefined | ContentRef = undefined;
    if (mappedWhat !== 'TRANSITION_NEXT') {
      contentRef = ContentRef.fromRuleAndOrgId(
        {
          what: mappedWhat,
          whatDetail: mappedWhatDetail!,
        },
        organisationSlug,
      );
    }

    const formattedEngagementCheck: NewEngagementCheck = {
      name: engagementCheck.details.name,
      description: engagementCheck.details.description || undefined,
      engagement_type: engagementCheck.typeOfCheck.checkType,
      engagement_events,
      engagement_count: engagementCheck.typeOfCheck.numberOfContents || undefined,
      check_time_from_type:
        determineEngagementTimingType(engagementCheck.timing.from.type) || 'stage',
      check_time_from_target:
        engagementCheck.timing.from.value.slug ||
        engagementCheck.timing.from.value.id ||
        engagementCheck.timing.from.value.uuid,
      check_time_from_offset: engagementCheck.timing.from.daysOffset || 0,
      check_time_until_type:
        determineEngagementTimingType(engagementCheck.timing.until.type) || 'stage',
      check_time_until_target:
        engagementCheck.timing.until.value.slug ||
        engagementCheck.timing.until.value.id ||
        engagementCheck.timing.until.value.uuid,
      check_time_until_offset: engagementCheck.timing.until.daysOffset || 0,
      event_time_from_type:
        (engagementCheck.timing.start.type &&
          determineEngagementTimingType(engagementCheck.timing.start.type)) ||
        undefined,
      event_time_from_target: engagementCheck.timing.start.value
        ? engagementCheck.timing.start.value.slug ||
          engagementCheck.timing.start.value.id ||
          engagementCheck.timing.start.value.uuid
        : undefined,
      event_time_from_offset: engagementCheck.timing.start.daysOffset || undefined,
      event_time_until_type:
        (engagementCheck.timing.stop.type &&
          determineEngagementTimingType(engagementCheck.timing.stop.type)) ||
        undefined,
      event_time_until_target: engagementCheck.timing.stop.value
        ? engagementCheck.timing.stop.value.slug ||
          engagementCheck.timing.stop.value.id ||
          engagementCheck.timing.stop.value.uuid
        : undefined,
      event_time_until_offset: engagementCheck.timing.stop.daysOffset || undefined,
      frequency_type: engagementCheck.occurrences.type,
      frequency_count: engagementCheck.occurrences.nTimes || undefined,
      frequency_target: engagementCheck.occurrences.untilAnotherEngagementCheckPassed || undefined,
      what: {
        [mappedWhat]: contentRef
          ? { content: contentRef.toString(), ...mappedWhatDetail }
          : mappedWhatDetail,
      },
    };

    // use Pathways client to save the new EngagementCheck
    const pathwaysClient = yield call(doCreatePathwaysClient);
    yield call(pathwaysClient.createEngagementCheck, pathwayId, formattedEngagementCheck);

    yield call(message.success, i18n.t('pathways:ProcedurePathways.addEngagementCheckSuccess'));
    const history = yield getContext('history');
    yield call(history.push, `/procedure/pathways/${pathwayId}`);

    yield put(addEngagementCheckSuccess(pathwayId, engagementCheck));
  } catch (err) {
    yield put(addEngagementCheckFailed(pathwayId));
    console.error(err);
    yield call(message.error, i18n.t('pathways:ProcedurePathways.addEngagementCheckError'));
  }
}

export function* doEditEngagementCheck({
  payload: { pathwayId, engagementCheck },
}: IEditEngagementCheck): any {
  try {
    const organisationSlug = yield select(selectOrganisationSlug());
    const engagement_events = contentToEngagementEvents(
      engagementCheck.contentToCheckEngagement.selectedContent,
    );

    const [mappedWhat, mappedWhatDetail] = mapEngagementActionToWhat(engagementCheck.action);

    let contentRef: undefined | ContentRef = undefined;
    if (mappedWhat !== 'TRANSITION_NEXT') {
      contentRef = ContentRef.fromRuleAndOrgId(
        {
          what: mappedWhat,
          whatDetail: mappedWhatDetail!,
        },
        organisationSlug,
      );
    }

    const formattedEngagementCheck: EngagementCheck = {
      id: engagementCheck.id,
      name: engagementCheck.details.name,
      description: engagementCheck.details.description || undefined,
      engagement_type: engagementCheck.typeOfCheck.checkType,
      engagement_events,
      engagement_count: engagementCheck.typeOfCheck.numberOfContents,
      check_time_from_type:
        determineEngagementTimingType(engagementCheck.timing.from.type) || 'stage',
      check_time_from_target:
        engagementCheck.timing.from.value.slug ||
        engagementCheck.timing.from.value.id ||
        engagementCheck.timing.from.value.uuid,
      check_time_from_offset: engagementCheck.timing.from.daysOffset,
      check_time_until_type:
        determineEngagementTimingType(engagementCheck.timing.until.type) || 'stage',
      check_time_until_target:
        engagementCheck.timing.until.value.slug ||
        engagementCheck.timing.until.value.id ||
        engagementCheck.timing.until.value.uuid,
      check_time_until_offset: engagementCheck.timing.until.daysOffset,
      event_time_from_type: determineEngagementTimingType(engagementCheck.timing.start.type),
      event_time_from_target: engagementCheck.timing.start.value
        ? engagementCheck.timing.start.value.slug ||
          engagementCheck.timing.start.value.id ||
          engagementCheck.timing.start.value.uuid
        : undefined,
      event_time_from_offset: engagementCheck.timing.start.daysOffset,
      event_time_until_type: determineEngagementTimingType(engagementCheck.timing.stop.type),
      event_time_until_target: engagementCheck.timing.stop.value
        ? engagementCheck.timing.stop.value.slug ||
          engagementCheck.timing.stop.value.id ||
          engagementCheck.timing.stop.value.uuid
        : undefined,
      event_time_until_offset: engagementCheck.timing.stop.daysOffset,
      frequency_type: engagementCheck.occurrences.type,
      frequency_count: engagementCheck.occurrences.nTimes,
      frequency_target: engagementCheck.occurrences.untilAnotherEngagementCheckPassed || undefined,
      what: {
        [mappedWhat]: contentRef
          ? { content: contentRef.toString(), ...mappedWhatDetail }
          : mappedWhatDetail,
      },
    };

    const pathwaysClient = yield call(doCreatePathwaysClient);
    yield call(pathwaysClient.patchEngagementCheck, pathwayId, formattedEngagementCheck);

    yield call(message.success, i18n.t('pathways:ProcedurePathways.editEngagementCheckSuccess'));
    const history = yield getContext('history');
    yield call(history.push, `/procedure/pathways/${pathwayId}`);

    yield put(editEngagementCheckSuccess(pathwayId, engagementCheck));
  } catch (err) {
    yield put(editEngagementCheckFailed(pathwayId));
    console.error(err);
    yield call(message.error, i18n.t('pathways:ProcedurePathways.editEngagementCheckError'));
  }
}

export function* doCreatePathwaySnapshot({
  payload: { pathwayId, snapshotData, makeShared, sharedWith },
}: ICreatePathwaySnapshot): any {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const snapshot = yield call(pathwaysClient.createPathwaySnapshot, pathwayId, snapshotData);

    yield put(createPathwaySnapshotSuccess(mapRawPathwaySnapshot(snapshot)));
    yield call(
      message.success,
      i18n.t('pathways:ProcedurePathways.createSnapshot.createSnapshotSuccess'),
    );

    if (makeShared) yield put(sharePathwaySnapshot(pathwayId, snapshot.id, sharedWith));
  } catch (err) {
    yield put(createPathwaySnapshotFailed());
    console.error(err);
    yield call(
      message.error,
      i18n.t('pathways:ProcedurePathways.createSnapshot.createSnapshotError'),
    );
  }
}

export function* doSharePathwaySnapshot({
  payload: { pathwayId, snapshotId, sharedWith },
}: ISharePathwaySnapshot): any {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    yield call(pathwaysClient.sharePathwaySnapshot, pathwayId, snapshotId, sharedWith);

    yield put(sharePathwaySnapshotSuccess(pathwayId, snapshotId, sharedWith ?? []));
    yield call(message.success, i18n.t('pathways:ProcedurePathways.sharingSettingsUpdateSuccess'));
  } catch (err) {
    yield put(sharePathwaySnapshotFailed());
    console.error(err);
    yield call(message.error, i18n.t('pathways:ProcedurePathways.sharingSettingsUpdateError'));
  }
}

export function* doUnsharePathwaySnapshot({
  payload: { pathwayId, snapshotId },
}: ISharePathwaySnapshot): any {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    yield call(pathwaysClient.unsharePathwaySnapshot, pathwayId, snapshotId);

    yield put(unsharePathwaySnapshotSuccess(snapshotId));
    yield call(message.success, i18n.t('pathways:ProcedurePathways.sharingSettingsUpdateSuccess'));
  } catch (err) {
    yield put(unsharePathwaySnapshotFailed());
    console.error(err);
    yield call(message.error, i18n.t('pathways:ProcedurePathways.sharingSettingsUpdateError'));
  }
}
