type BaseInternalElement = {
  title: string;
  description?: string;
  required?: boolean;
  isUnderSection: boolean;
  order: number;
  isCollapsed?: boolean;
  isNewElement?: boolean;
};

type NullElement = BaseInternalElement & {
  elementType: 'nullElement';
  type: 'null';
};

type TextElement = BaseInternalElement & {
  elementType: 'text';
  type: 'string';
  'ui:widget': 'input' | 'textarea';
  placeholder?: string;
  minLength?: number;
  maxLength?: number;
  required: boolean;
};

type NumberElement = BaseInternalElement & {
  elementType: 'number';
  type: 'number';
  'ui:widget': 'input';
  placeholder?: string;
  minimum?: number;
  maximum?: number;
  required: boolean;
};

type MultipleChoiceElement = BaseInternalElement & {
  elementType: 'multipleChoice';
  type: 'boolean' | 'string' | 'number';
  'ui:widget': 'radio' | 'checkboxes' | 'select';
  enum?: (string | number)[];
  enumNames?: string[];
  default?: boolean | string | number | (string | number)[];
  required: boolean;
};

type DateElement = BaseInternalElement & {
  elementType: 'date';
  type: 'string';
  format: 'date';
  required: boolean;
};

type SliderElement = BaseInternalElement & {
  elementType: 'slider';
  type: 'number';
  'ui:widget': 'range';
  minimum: number;
  maximum: number;
  required: boolean;
};

type SectionElement = BaseInternalElement & {
  elementType: 'section';
  type?: 'section';
};

export type InternalElement =
  | NullElement
  | TextElement
  | NumberElement
  | MultipleChoiceElement
  | DateElement
  | SliderElement
  | SectionElement;

export type InternalSchema = {
  [key: string]: InternalElement;
};

type RJSFUISchemaItem = {
  'ui:enableMarkdownInDescription': true;
  'ui:description'?: string;
  'ui:widget'?: string;
  'ui:placeholder'?: string;
};

type RJSFProperty = {
  title: string;
  type: string;
  minLength?: number;
  maxLength?: number;
  format?: string;
  minimum?: number;
  maximum?: number;
  enum?: (string | number)[];
  enumNames?: string[];
  default?: boolean | string | number | (string | number)[];
  items?: {
    type: string;
    enum?: (string | number)[];
    enumNames?: string[];
  };
  uniqueItems?: boolean;
  properties?: { [key: string]: RJSFProperty };
  required?: string[];
};

export type RJSFSchema = {
  name: string;
  metadata: {
    type: 'FORM';
    fileName: string;
    language: string;
  };
  schema: {
    type: 'object';
    title: string;
    properties: { [key: string]: RJSFProperty };
    required?: string[];
  };
  uiSchema: {
    'ui:order': string[];
    'ui:description'?: string;
    'ui:displayMode'?: string;
    [key: string]: any;
  };
};

interface SectionGroup {
  key: string;
  element: SectionElement;
  properties: { [key: string]: RJSFProperty };
  required: string[];
  uiOrder: string[];
  uiSchema: { [key: string]: RJSFUISchemaItem };
}

function toRJSF(
  internalSchema: InternalSchema,
  name: string,
  formDescription: string,
  fileName: string,
  language: string,
  title: string,
  formType: string,
): RJSFSchema {
  const sortedEntries = Object.entries(internalSchema).sort(([, a], [, b]) => a.order - b.order);
  const topProperties: { [key: string]: RJSFProperty } = {};
  const topRequired: string[] = [];
  const globalOrder: string[] = [];
  const topUiSchema: { [key: string]: RJSFUISchemaItem } = {};
  const sections: { [key: string]: SectionGroup } = {};
  let currentSection: SectionGroup | null = null;

  for (const [uuid, element] of sortedEntries) {
    if (element.elementType === 'section') {
      if (currentSection) {
        sections[currentSection.key] = currentSection;
      }
      currentSection = {
        key: uuid,
        element: element as SectionElement,
        properties: {},
        required: [],
        uiOrder: [],
        uiSchema: {},
      };
      globalOrder.push(uuid);
      continue;
    }

    let prop: RJSFProperty = { title: element.title, type: element.type };

    if (element.elementType === 'nullElement') {
    } else if (element.elementType === 'text') {
      const te = element as TextElement;
      if (te.minLength !== undefined) prop.minLength = te.minLength;
      if (te.maxLength !== undefined) prop.maxLength = te.maxLength;
    } else if (element.elementType === 'number') {
      const ne = element as NumberElement;
      if (ne.minimum !== undefined) prop.minimum = ne.minimum;
      if (ne.maximum !== undefined) prop.maximum = ne.maximum;
    } else if (element.elementType === 'slider') {
      const se = element as SliderElement;
      prop.minimum = se.minimum;
      prop.maximum = se.maximum;
    } else if (element.elementType === 'date') {
      prop.format = 'date';
    } else if (element.elementType === 'multipleChoice') {
      const mce = element as MultipleChoiceElement;
      if (mce['ui:widget'] === 'checkboxes') {
        prop.type = 'array';
        prop.uniqueItems = true;
        prop.items = { type: mce.type };

        if (mce.enum) {
          prop.items.enum = mce.enum;
          if (mce.enumNames) {
            prop.items.enumNames = mce.enumNames;
          }
        }
        if (mce.default !== undefined) prop.default = mce.default;
      } else {
        if (mce.enum) {
          prop.enum = mce.enum;
        }
        if (mce.enumNames) {
          prop.enumNames = mce.enumNames;
        }
        if (mce.default !== undefined) prop.default = mce.default;
      }
    }

    const fieldUi: RJSFUISchemaItem = { 'ui:enableMarkdownInDescription': true };
    if (element.description) fieldUi['ui:description'] = element.description;

    if (element.elementType === 'text') {
      const te = element as TextElement;
      if (te['ui:widget'] === 'textarea') fieldUi['ui:widget'] = 'textarea';
      if (te.placeholder) fieldUi['ui:placeholder'] = te.placeholder;
    } else if (element.elementType === 'number') {
      const ne = element as NumberElement;
      if (ne.placeholder) fieldUi['ui:placeholder'] = ne.placeholder;
    } else if (element.elementType === 'slider') {
      fieldUi['ui:widget'] = 'range';
    } else if (element.elementType === 'multipleChoice') {
      fieldUi['ui:widget'] = (element as MultipleChoiceElement)['ui:widget'];
    }

    if (currentSection) {
      currentSection.properties[uuid] = prop;
      currentSection.uiSchema[uuid] = fieldUi;
      currentSection.uiOrder.push(uuid);
      if (element.required) currentSection.required.push(uuid);
    } else {
      topProperties[uuid] = prop;
      topUiSchema[uuid] = fieldUi;
      globalOrder.push(uuid);
      if (element.required) topRequired.push(uuid);
    }
  }
  if (currentSection) {
    sections[currentSection.key] = currentSection;
  }
  const finalProperties: { [key: string]: RJSFProperty } = { ...topProperties };
  for (const secKey in sections) {
    const sec = sections[secKey];
    finalProperties[secKey] = {
      type: 'object',
      title: sec.element.title,
      properties: sec.properties,
      ...(sec.required.length > 0 ? { required: sec.required } : {}),
    };
  }
  const finalUiSchema: RJSFSchema['uiSchema'] = {
    'ui:order': globalOrder,
    'ui:description': formDescription,
    'ui:displayMode': formType,
    ...topUiSchema,
  };
  for (const secKey in sections) {
    const sec = sections[secKey];
    finalUiSchema[secKey] = {
      'ui:order': sec.uiOrder,
      'ui:description': sec.element.description || '',
      'ui:enableMarkdownInDescription': true,
      ...sec.uiSchema,
    };
  }
  return {
    name,
    metadata: { type: 'FORM', fileName, language },
    schema: {
      type: 'object',
      title,
      properties: finalProperties,
      ...(topRequired.length > 0 ? { required: topRequired } : {}),
    },
    uiSchema: {
      ...finalUiSchema,
    },
  };
}

function fromRJSF(
  rjsfSchema: RJSFSchema,
): {
  internalSchema: InternalSchema;
  name: string;
  description: string;
  fileName: string;
  language: string;
  title: string;
  formType: string;
} {
  const internalSchema: InternalSchema = {};
  const globalOrder =
    (rjsfSchema.uiSchema['ui:order'] as string[]) || Object.keys(rjsfSchema.schema.properties);
  let orderCounter = 0;

  for (const key of globalOrder) {
    const prop = rjsfSchema.schema.properties[key];
    const uiItem = rjsfSchema.uiSchema[key];

    if (prop.type === 'object' && prop.properties !== undefined) {
      const sectionElement: SectionElement = {
        elementType: 'section',
        title: prop.title,
        description: uiItem['ui:description'] || '',
        isUnderSection: false,
        order: orderCounter++,
        isCollapsed: true,
        isNewElement: false,
      };
      internalSchema[key] = sectionElement;

      const sectionRequired = prop.required || [];
      const sectionUiOrder = (uiItem['ui:order'] as string[]) || Object.keys(prop.properties);

      for (const subKey of sectionUiOrder) {
        const subProp = prop.properties[subKey];
        const subUi = uiItem[subKey] as RJSFUISchemaItem;
        const elementOrder = orderCounter++;

        const baseElement: BaseInternalElement = {
          title: subProp.title,
          required: sectionRequired.includes(subKey),
          isUnderSection: true,
          order: elementOrder,
          isCollapsed: true,
          isNewElement: false,
        };

        if (subUi['ui:description']) baseElement.description = subUi['ui:description'];

        if (subProp.type === 'null') {
          internalSchema[subKey] = {
            ...baseElement,
            elementType: 'nullElement',
            type: 'null',
          } as NullElement;
        } else if (subProp.format === 'date') {
          internalSchema[subKey] = {
            ...baseElement,
            elementType: 'date',
            type: 'string',
            format: 'date',
            required: baseElement.required || false,
          } as DateElement;
        } else if (subUi['ui:widget'] === 'range') {
          if (typeof subProp.minimum === 'number' && typeof subProp.maximum === 'number') {
            internalSchema[subKey] = {
              ...baseElement,
              elementType: 'slider',
              type: 'number',
              'ui:widget': 'range',
              minimum: subProp.minimum,
              maximum: subProp.maximum,
              required: baseElement.required || false,
            } as SliderElement;
          } else {
            throw new Error(`Slider element ${subKey} missing minimum/maximum`);
          }
        } else if (
          subUi['ui:widget'] &&
          ['radio', 'checkboxes', 'select'].includes(subUi['ui:widget'])
        ) {
          if (subProp.type === 'array' && subUi['ui:widget'] === 'checkboxes' && subProp.items) {
            const itemType = (subProp.items as any).type;

            internalSchema[subKey] = {
              ...baseElement,
              elementType: 'multipleChoice',
              type: itemType as 'string' | 'number',
              'ui:widget': subUi['ui:widget'],
              required: baseElement.required || false,
              enum: subProp.items.enum,
              enumNames: subProp.items.enumNames,
              default: subProp.default,
            } as MultipleChoiceElement;
          } else {
            internalSchema[subKey] = {
              ...baseElement,
              elementType: 'multipleChoice',
              type: subProp.type as 'boolean' | 'string' | 'number',
              'ui:widget': subUi['ui:widget'],
              required: baseElement.required || false,
              enum: subProp.enum,
              enumNames: subProp.enumNames,
              default: subProp.default,
            } as MultipleChoiceElement;
          }
        } else if (subProp.type === 'string') {
          const te: TextElement = {
            ...baseElement,
            elementType: 'text',
            type: 'string',
            'ui:widget': subUi['ui:widget'] === 'textarea' ? 'textarea' : 'input',
            required: baseElement.required || false,
          };

          if (subProp.minLength !== undefined) te.minLength = subProp.minLength;
          if (subProp.maxLength !== undefined) te.maxLength = subProp.maxLength;
          if (subUi['ui:placeholder']) te.placeholder = subUi['ui:placeholder'];

          internalSchema[subKey] = te;
        } else if (subProp.type === 'number') {
          const ne: NumberElement = {
            ...baseElement,
            elementType: 'number',
            type: 'number',
            'ui:widget': 'input',
            required: baseElement.required || false,
          };

          if (subProp.minimum !== undefined) ne.minimum = subProp.minimum;
          if (subProp.maximum !== undefined) ne.maximum = subProp.maximum;
          if (subUi['ui:placeholder']) ne.placeholder = subUi['ui:placeholder'];

          internalSchema[subKey] = ne;
        }
      }
    } else {
      const elementOrder = orderCounter++;
      const baseElement: BaseInternalElement = {
        title: prop.title,
        required: (rjsfSchema.schema.required || []).includes(key),
        isUnderSection: false,
        order: elementOrder,
        isCollapsed: true,
        isNewElement: false,
      };

      const uiField = uiItem as RJSFUISchemaItem;
      if (uiField && uiField['ui:description']) baseElement.description = uiField['ui:description'];

      if (prop.type === 'null') {
        internalSchema[key] = {
          ...baseElement,
          elementType: 'nullElement',
          type: 'null',
        } as NullElement;
      } else if (prop.format === 'date') {
        internalSchema[key] = {
          ...baseElement,
          elementType: 'date',
          type: 'string',
          format: 'date',
          required: baseElement.required || false,
        } as DateElement;
      } else if (uiField && uiField['ui:widget'] === 'range') {
        if (typeof prop.minimum === 'number' && typeof prop.maximum === 'number') {
          internalSchema[key] = {
            ...baseElement,
            elementType: 'slider',
            type: 'number',
            'ui:widget': 'range',
            minimum: prop.minimum,
            maximum: prop.maximum,
            required: baseElement.required || false,
          } as SliderElement;
        } else {
          throw new Error(`Slider element ${key} missing minimum/maximum`);
        }
      } else if (
        uiField &&
        uiField['ui:widget'] &&
        ['radio', 'checkboxes', 'select'].includes(uiField['ui:widget'])
      ) {
        if (prop.type === 'array' && uiField['ui:widget'] === 'checkboxes' && prop.items) {
          const itemType = (prop.items as any).type;

          internalSchema[key] = {
            ...baseElement,
            elementType: 'multipleChoice',
            type: itemType as 'string' | 'number',
            'ui:widget': uiField['ui:widget'],
            required: baseElement.required || false,
            enum: prop.items.enum,
            enumNames: prop.items.enumNames,
            default: prop.default,
          } as MultipleChoiceElement;
        } else {
          internalSchema[key] = {
            ...baseElement,
            elementType: 'multipleChoice',
            type: prop.type as 'boolean' | 'string' | 'number',
            'ui:widget': uiField['ui:widget'],
            required: baseElement.required || false,
            enum: prop.enum,
            enumNames: prop.enumNames,
            default: prop.default,
          } as MultipleChoiceElement;
        }
      } else if (prop.type === 'string') {
        const te: TextElement = {
          ...baseElement,
          elementType: 'text',
          type: 'string',
          'ui:widget': uiField && uiField['ui:widget'] === 'textarea' ? 'textarea' : 'input',
          required: baseElement.required || false,
        };

        if (prop.minLength !== undefined) te.minLength = prop.minLength;
        if (prop.maxLength !== undefined) te.maxLength = prop.maxLength;
        if (uiField && uiField['ui:placeholder']) te.placeholder = uiField['ui:placeholder'];

        internalSchema[key] = te;
      } else if (prop.type === 'number') {
        const ne: NumberElement = {
          ...baseElement,
          elementType: 'number',
          type: 'number',
          'ui:widget': 'input',
          required: baseElement.required || false,
        };

        if (prop.minimum !== undefined) ne.minimum = prop.minimum;
        if (prop.maximum !== undefined) ne.maximum = prop.maximum;
        if (uiField && uiField['ui:placeholder']) ne.placeholder = uiField['ui:placeholder'];

        internalSchema[key] = ne;
      }
    }
  }

  return {
    internalSchema,
    name: rjsfSchema.name,
    description: rjsfSchema.uiSchema['ui:description'] || '',
    fileName: rjsfSchema.metadata?.fileName,
    language: rjsfSchema.metadata?.language,
    title: rjsfSchema.schema.title,
    formType: rjsfSchema.uiSchema['ui:displayMode'] || 'standard',
  };
}

export { toRJSF, fromRJSF };
