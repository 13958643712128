import React, { createContext, useCallback, useEffect, useState } from 'react';
import { arrayOf, bool, func, node, objectOf, oneOfType, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Form, Spin, Modal } from 'antd';
import { usePageActions } from '@hooks';
import WizardMenu from './Menu';
import { antFormType } from '../../propTypes';
import { track } from '../../analytics';
import './style.less';

export const WizardContext = createContext(null);

const doExitWizard = (t, history, title) => {
  Modal.confirm({
    title: title || t('common:Wizard.exitConfirmModal.defaultTitle'),
    content: t('common:Wizard.exitConfirmModal.content'),
    okText: t('common:Wizard.exitConfirmModal.okText'),
    cancelText: t('common:Wizard.exitConfirmModal.cancelText'),
    onOk: history.goBack,
  });
};

function Wizard({
  children,
  form,
  initialData,
  loading,
  submitting,
  onCompleted,
  exitModalTitle,
  enableFinalStepValidation,
  setCurrentStepKey,
  beforePreviousStep,
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [stepKeys, setStepNames] = useState([]);
  const [steps, setSteps] = useState([]);
  const [childrenArray, setChildrenArray] = useState([]);
  const [formData, setFormData] = useState({ ...initialData });

  const { t } = useTranslation();
  const history = useHistory();

  usePageActions({
    showClose: true,
    onClose: () => doExitWizard(t, history, exitModalTitle),
  });

  useEffect(() => {
    if (stepKeys.length > 0) {
      setCurrentStepKey(stepKeys[currentStep]);
    }
  }, [currentStep, stepKeys, setCurrentStepKey]);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  useEffect(() => {
    const childrenArray = React.Children.toArray(children);
    const { stepKeys, wizardSteps } = childrenArray.reduce(
      (prev, { props: { children, ...props } }, index) => ({
        stepKeys: [...prev.stepKeys, props.stepKey || index],
        wizardSteps: [...prev.wizardSteps, props],
      }),
      { stepKeys: [], wizardSteps: [] },
    );
    setChildrenArray(childrenArray);
    // if (stepKeys.length > 0) {
    //   track(`View step ${stepKeys[0]}`);
    // }
    setStepNames(stepKeys);
    setSteps(wizardSteps);
  }, [children]);

  const onNextStep = useCallback(() => {
    if (currentStep === steps.length - 1) {
      if (enableFinalStepValidation) {
        form.validateFieldsAndScroll((err, data) => {
          if (err) {
            track('Form validation error');
            return;
          }
          if (Object.entries(data).length !== 0) {
            setFormData({
              ...formData,
              [stepKeys[currentStep]]: { ...formData[stepKeys[currentStep]], ...data },
            });
          }
          track('Completed');
          return onCompleted(formData);
        });
      } else {
        const data = { ...form.getFieldsValue() };
        if (Object.entries(data).length !== 0) {
          setFormData({
            ...formData,
            [stepKeys[currentStep]]: { ...formData[stepKeys[currentStep]], ...data },
          });
        }
        track('Completed');
        return onCompleted(formData);
      }
      return;
    }
    form.validateFieldsAndScroll((err, data) => {
      if (err) {
        track('Form validation error');
        return;
      }
      if (Object.entries(data).length !== 0) {
        setFormData({
          ...formData,
          [stepKeys[currentStep]]: { ...formData[stepKeys[currentStep]], ...data },
        });
      }

      const newStepKey = stepKeys[currentStep + 1];
      track(`View step ${newStepKey}`);
      setCurrentStep(currentStep + 1);
    });
  }, [
    currentStep,
    setCurrentStep,
    formData,
    stepKeys,
    setFormData,
    form,
    onCompleted,
    steps,
    enableFinalStepValidation,
  ]);

  const handlePreviousStep = useCallback(() => {
    if (beforePreviousStep && typeof beforePreviousStep[stepKeys[currentStep]] === 'function') {
      beforePreviousStep[stepKeys[currentStep]](
        () => {
          const newStepKey = stepKeys[currentStep - 1];
          track(`View step ${newStepKey}`);
          setCurrentStep(currentStep - 1);
        },
        { formData, setFormData, currentStepName: stepKeys[currentStep] },
      );
    } else {
      const data = { ...form.getFieldsValue() };
      if (Object.entries(data).length !== 0) {
        setFormData({
          ...formData,
          [stepKeys[currentStep]]: { ...formData[stepKeys[currentStep]], ...data },
        });
      }
      const newStepKey = stepKeys[currentStep - 1];
      track(`View step ${newStepKey}`);
      setCurrentStep(currentStep - 1);
    }
  }, [currentStep, form, formData, stepKeys, setFormData, beforePreviousStep]);

  return (
    <WizardContext.Provider
      value={{
        currentStepName: stepKeys[currentStep],
        form,
        formData,
        setFormData,
        enableFinalStepValidation,
        onNextStep,
      }}
    >
      <div className="wizard-container">
        <WizardMenu
          currentStep={currentStep}
          loading={loading}
          submitting={submitting}
          steps={steps}
          onCompleted={onCompleted}
          onNextStep={onNextStep}
          onPreviousStep={handlePreviousStep}
        />
        <Spin spinning={loading}>
          <div className="wizard-content">{childrenArray[currentStep]}</div>
        </Spin>
      </div>
    </WizardContext.Provider>
  );
}

Wizard.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  form: antFormType.isRequired,
  initialData: shape({}),
  loading: bool,
  submitting: bool,
  onCompleted: func.isRequired,
  exitModalTitle: string,
  analyticsScreen: string,
  enableFinalStepValidation: bool,
  setCurrentStepKey: func,
  beforePreviousStep: objectOf(func),
};

Wizard.defaultProps = {
  initialData: {},
  loading: false,
  submitting: false,
  exitModalTitle: undefined,
  analyticsScreen: undefined,
  enableFinalStepValidation: false,
  setCurrentStepKey: () => {},
  beforePreviousStep: {},
};

export default Form.create()(Wizard);
