import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Empty, Button } from 'antd';
import FormPreviewModal from '@components/FormPreviewModal';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import { Card } from '@cards/Card';

import './style.less';

const FormDetailCard = ({ form, title, formData, ...props }) => {
  const { t } = useTranslation();
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const handleOpenPreviewModal = () => setIsPreviewModalVisible(true);
  const closeModal = () => setIsPreviewModalVisible(false);

  return (
    <Card.Half className="review-form-details" title={t(title)} {...props}>
      {form === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <DetailForm>
          <DetailRow label={t('cards:FormDetail.labels.title')} value={form.name} />
          <DetailRow label={t('cards:FormDetail.labels.description')} value={form.description} />
          <DetailRow
            label={t('cards:FormDetail.labels.audience')}
            value={form.audience ? t(`cards:FormDetail.labels.${form.audience}`) : ''}
          />
          <DetailRow
            label={t('cards:FormDetail.labels.preview')}
            value={
              <Button onClick={handleOpenPreviewModal}>
                {t('cards:FormDetail.labels.preview')}
              </Button>
            }
          />
        </DetailForm>
      )}
      <FormPreviewModal
        readOnly
        schema={formData.schema}
        uiSchema={formData.uiSchema}
        visible={isPreviewModalVisible}
        handleCloseModal={closeModal}
        name={form?.name}
        loading={false}
        submitting={false}
      />
    </Card.Half>
  );
};

FormDetailCard.propTypes = {
  title: PropTypes.string,
  form: PropTypes.object,
  formData: PropTypes.object,
};

FormDetailCard.defaultProps = {
  title: 'cards:FormDetail.title',
  form: undefined,
  formData: undefined,
};

export default FormDetailCard;
