import { combineReducers } from 'redux';
import { IState } from '@redux/reducer';
import {
  CREATE_RULE,
  CREATE_RULE_FAILURE,
  DELETE_RULE,
  DELETE_RULES,
  DELETE_RULES_SUCCESS,
  DUPLICATE_RULE,
  DUPLICATE_RULES,
  DUPLICATE_RULES_SUCCESS,
  EDIT_RULE,
  EDIT_RULE_SUCCESS,
  FETCH_RULES,
  FETCH_RULES_ERROR,
  FETCHING_RULES,
  LOAD_RULES,
  RULE_CREATED,
  IRule,
  FETCH_RULES_WHAT_DETAIL_SUCCESS,
} from './types';
import {
  IDeleteRulesSuccess,
  IDuplicateRulesSuccess,
  IEditRuleSuccess,
  IFetchingRules,
  IFetchRules,
  IFetchRulesError,
  ILoadRules,
  IRuleCreated,
  ICreateRule,
  IDeleteRule,
  IDeleteRules,
  IDuplicateRule,
  IDuplicateRules,
  IEditRule,
  ICreateRuleFailure,
  EditRuleFailed,
  IFetchRulesWhatDetailSuccess,
} from './actions';

function creating(state = false, action: ICreateRule | ICreateRuleFailure | IRuleCreated) {
  switch (action.type) {
    case CREATE_RULE:
      return true;
    case CREATE_RULE_FAILURE:
    case RULE_CREATED:
      return false;
    default:
      return state;
  }
}

function data(
  state: IRule[] = [],
  action:
    | IDeleteRulesSuccess
    | IDuplicateRulesSuccess
    | IEditRuleSuccess
    | ILoadRules
    | IRuleCreated
    | IFetchRulesWhatDetailSuccess,
): IRule[] {
  switch (action.type) {
    case DELETE_RULES_SUCCESS:
      return state.filter(({ id }) => !action.payload.ruleIds.includes(id));
    case DUPLICATE_RULES_SUCCESS:
      return [...action.payload.rules, ...state];
    case EDIT_RULE_SUCCESS:
      return state.map(rule =>
        rule.id === action.payload.editedRule.id ? action.payload.editedRule : rule,
      );
    case LOAD_RULES:
      return action.payload.rules;
    case RULE_CREATED:
      return [action.payload.rule, ...state];
    case FETCH_RULES_WHAT_DETAIL_SUCCESS:
      return state.map(rule => {
        if (action.payload.rules[rule.id]) {
          return {
            ...rule,
            whatDetail: {
              ...rule.whatDetail,
              ...action.payload.rules[rule.id],
            },
          };
        }
        return rule;
      });
    default:
      return state;
  }
}

function editing(state = false, action: IEditRule | IEditRuleSuccess | EditRuleFailed) {
  switch (action.type) {
    case EDIT_RULE:
      return true;
    case EDIT_RULE_SUCCESS:
    case 'rules/editFailed':
      return false;
    default:
      return state;
  }
}

function error(
  state: boolean | string = false,
  action: IFetchRules | IFetchRulesError,
): boolean | string {
  switch (action.type) {
    case FETCH_RULES_ERROR:
      return action.payload.error;
    case FETCH_RULES:
      return false;
    default:
      return state;
  }
}

function loading(
  state: boolean = false,
  action:
    | IDeleteRule
    | IDeleteRules
    | IDuplicateRule
    | IDuplicateRules
    | IFetchingRules
    | IDeleteRulesSuccess
    | IDuplicateRulesSuccess
    | IFetchRulesError
    | ILoadRules
    | IRuleCreated,
): boolean {
  switch (action.type) {
    case DELETE_RULE:
    case DELETE_RULES:
    case DUPLICATE_RULE:
    case DUPLICATE_RULES:
    case FETCHING_RULES:
      return true;
    case DELETE_RULES_SUCCESS:
    case DUPLICATE_RULES_SUCCESS:
    case FETCH_RULES_ERROR:
    case LOAD_RULES:
    case RULE_CREATED:
      return false;
    default:
      return state;
  }
}

export default combineReducers({ creating, data, editing, error, loading });

export const selectRuleCreating = (state: IState) => state.rules.creating;
export const selectRuleEditing = (state: IState) => state.rules.editing;
export const selectRules = (state: IState) => [state.rules.loading, state.rules.data];
export const selectEditableRules = (state: IState) => [
  state.rules.loading,
  state.rules.data.filter(r => !r.isReadOnly),
];
// filters = [WHEN_TYPE]
export const selectRulesFilteredByWhen = (filters: string[], editableOnly = true) => (
  state: IState,
) => [
  state.rules.loading,
  state.rules.data.filter(
    ({ when, isReadOnly }) => filters.includes(when) && !(editableOnly && isReadOnly),
  ),
];

export const selectRule = (id: number) => (state: IState): [boolean, IRule | undefined] => [
  state.rules.loading,
  state.rules.data.find(rule => `${rule.id}` === `${id}`),
];
